import Wrapper from "../../containers/apps/mail/wrapper";
import {Fragment, useCallback, useEffect, useState} from "react";
import {Badge, Col, Row, Select, Text} from "@doar/components";
import React from "react";
import Lottie from "react-lottie-player";
import landingPageAnimation from "@doar/shared/lottie/landingPageAnimation.json";
import scrollDownAnimation from "@doar/shared/lottie/scroll_down.json";
import {presets} from 'react-text-transition';
import {StyledTextTransition} from './style'
import SearchInput from "../app-header/search"
import {Map} from 'react-feather'


const LandingPage = (props) => {

    const TEXTS = ['Buy', 'Rent'];
    const TEXTS1 = ['House', 'Apartment'];
    const [index, setIndex] = React.useState(0);
    const [index2, setIndex2] = React.useState(0);
    const [searchParams, setSearchParams] = useState({
        rooms: null
    });

    useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);
    useEffect(() => {
        const intervalId2 = setInterval(
            () => setIndex2((index2) => index2 + 1),
            4000, // every 3 seconds
        );
        return () => clearTimeout(intervalId2);
    }, []);


    return (
        <>
            <Col style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                minHeight: 500,
                alignItems: 'center'
            }}>
                <Col mt={50} mb={30} style={{
                    zIndex: 2,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    position: "absolute"
                }}>
                    <Row p={40}>
                        <h1 style={{textAlign: 'center'}}>Find the best <StyledTextTransition
                            springConfig={presets.wobbly}>{TEXTS1[index2 % TEXTS1.length]}</StyledTextTransition> within
                            your budget in Kenya</h1>
                    </Row>
                    <Row className={'landing-search-wrapper-parent'} justifyContent={'center'} m={0}>
                        <Row
                            className={'landing-search-wrapper'}
                        >
                            <h4 style={{
                                paddingRight: 10,
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                display: "flex",
                                marginBottom: 0

                            }}>I want to <StyledTextTransition
                                style={{
                                    marginRight: 5,
                                    marginLeft: 5
                                }}
                                springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</StyledTextTransition>
                                a
                            </h4>
                            <Row marginLeft={10}
                                 className={'bedroom-select'}
                                 marginRight={10}
                                 p={0} style={{
                                paddingRight: 10,
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                display: "flex"
                            }}>
                                <Select
                                    id={"homePageRoomSize"}
                                    name={"homePageRoomSize"}
                                    style={{border: "none"}}
                                    onChange={(value) => {
                                        setSearchParams((current) => {
                                            return {
                                                ...current,
                                                rooms: value.target.value
                                            }
                                        })
                                    }}
                                    aria-label="Select Apartment">
                                    <option
                                        key={`rooms_any`}
                                        value={null}>{`Select Rooms`}</option>
                                    {[-3, -2, -1, 0, 1, 2, 3, 4, 5, 6, 7].map((bedrooms, index) => <option
                                        key={`rooms_${index}`}
                                        value={bedrooms}>{bedrooms < 1 ? `${bedrooms == -3 ? 'Single Room' : (bedrooms == -2 ? 'Double Room' : (bedrooms == -1 ? 'Studio' : 'Bedsitter'))}` : `${bedrooms} Bedroom `}</option>)}
                                </Select>
                            </Row>
                            <h4 style={{
                                paddingRight: 10,
                                height: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                textAlign: "center",
                                display: "flex",
                                marginBottom: 0
                            }}> house in </h4>
                            <Row marginLeft={10} className={'landing-search'}>
                                <SearchInput hint={"Search area/region"} isLandingPage
                                             onSearchLocationSelected={(value, radius, zoom, selectedSearchLocation) => {
                                                 setSearchParams((current) => {
                                                     return {
                                                         ...current,
                                                         location: value,
                                                         selectedSearchLocation: selectedSearchLocation,
                                                         radius:radius,
                                                         zoom:zoom
                                                     }
                                                 })
                                             }}
                                             onSearchClicked={() => {
                                                 if (searchParams)
                                                     window.location.replace(`/search?location=${searchParams.location.lat},${searchParams.location.lng}${searchParams.rooms != null?('&rooms='+searchParams.rooms):''}&place=${searchParams.selectedSearchLocation}`)
                                             }}
                                             isListingHeader={true}/>
                            </Row>
                        </Row>
                        <Row m={0} p={"7px 5px"} className={'map-search-button-wrapper'} style={{backgroundColor: "#f84163", }}>
                            <Col pl={2} pr={2} style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                cursor:"pointer"
                            }}
                            onClick={()=>{
                                console.log(searchParams)
                                if (searchParams)
                                    window.location.replace(`/search?${searchParams.location != null?(`location=${searchParams.location.lat},${searchParams.location.lng}`):''}${searchParams.rooms != null?('&rooms='+searchParams.rooms):''}${searchParams.selectedSearchLocation != null?('&place='+searchParams.selectedSearchLocation):''}&map=true`)

                            }}
                            >
                                <Map color={'white'} size={20}/>
                                <Text style={{textWrap: "nowrap"}} color={'white'}>Search with Map</Text>
                            </Col>
                        </Row>
                    </Row>

                </Col>
                <Lottie
                    className={'landingPageAnimation'}
                    loop={true}
                    play={true}
                    animationData={landingPageAnimation}
                    style={{
                        opacity: 0.2,
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        bottom: "50px",
                        alignSelf: 'bottom',
                        marginLeft: 0,
                        zIndex: 0,
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation()
                    }}
                />
                <Lottie
                    className={'scrollDownAnimation'}
                    loop={true}
                    play={true}
                    animationData={scrollDownAnimation}
                    style={{
                        position: 'absolute',
                        bottom: 70,
                        alignSelf: 'bottom',
                        marginLeft: 0,
                        zIndex: 0,
                    }}
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation()
                    }}
                />
            </Col>

        </>
    );
};

export default LandingPage;
