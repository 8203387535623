import {
    // Calendar,
    MessageSquare,
    Users,
    // FileText,
    // Mail,
    // LogIn,
    // UserPlus,
    // UserCheck,
    // ShieldOff,
    // User,
    // File,
    PieChart,
    Package,
    // Layers,
    // Box,
    // Archive,
    // AlertCircle,
    // ShoppingBag,
    // Globe,
    // LifeBuoy,
    // ShoppingCart,
    Home, Tool, Phone,
} from "react-feather";

const asideMenus = [
    {
        id: 1,
        label: "Dashboard",
        url: "/",
        Icon: PieChart,
        submenu: [
            {
                id: 11,
                label: "Home",
                url: "/",
                Icon: PieChart,
            },
            // {
            //     id: 12,
            //     label: "Website Analytics",
            //     url: "/classic-plus/dashboard-two",
            //     Icon: Globe,
            // },
            // {
            //     id: 13,
            //     label: "Cryptocurrency",
            //     url: "/classic-plus/dashboard-three",
            //     Icon: PieChart,
            // },
            // {
            //     id: 14,
            //     label: "Helpdesk Management",
            //     url: "/classic-plus/dashboard-four",
            //     Icon: LifeBuoy,
            // },
            // {
            //     id: 15,
            //     label: "Ecommerce Dashboard",
            //     url: "/classic-plus/dashboard-five",
            //     Icon: ShoppingCart,
            // },
        ],
    },
    {
        id: 2,
        label: "Management",
        url: "/",
        Icon: PieChart,
        submenu: [
            {
                id: 21,
                label: "Properties",
                url: "/properties",
                Icon: Home,
            },
            {
                id: 22,
                label: "Users",
                url: "/users",
                Icon: Users,
            },
            {
                id: 23,
                label: "Maintenance",
                url: "/maintenance",
                Icon: Tool,
            },
        ],
    },
    {
        id: 3,
        label: "Communication",
        url: "/",
        Icon: Package,
        submenu: [
            // {
            //     id: 21,
            //     label: "Calendar",
            //     url: "/classic-plus/apps/calendar",
            //     Icon: Calendar,
            // },
            {
                id: 31,
                label: "Messages",
                url: "/messages",
                Icon: MessageSquare,
            },
            {
                id: 32,
                label: "Contacts",
                url: "/contacts",
                Icon: Phone,
            }

            // {
            //     id: 34,
            //     label: "File Manager",
            //     url: "/classic-plus/apps/file-manager",
            //     Icon: FileText,
            // },
            // {
            //     id: 35,
            //     label: "Mail",
            //     url: "/classic-plus/apps/mail",
            //     Icon: Mail,
            // },
        ],
    },
    // {
    //     id: 4,
    //     label: "Pages",
    //     url: "/",
    //     Icon: Layers,
    //     megamenu: [
    //         {
    //             id: 41,
    //             title: "Authentication",
    //             Icon: Layers,
    //             submenu: [
    //                 {
    //                     id: 311,
    //                     label: "Sign In",
    //                     url: "/classic-plus/signin",
    //                     Icon: LogIn,
    //                 },
    //                 {
    //                     id: 312,
    //                     label: "Sign Up",
    //                     url: "/classic-plus/signup",
    //                     Icon: UserPlus,
    //                 },
    //                 {
    //                     id: 313,
    //                     label: "Verify Account",
    //                     url: "/classic-plus/verify-account",
    //                     Icon: UserCheck,
    //                 },
    //                 {
    //                     id: 314,
    //                     label: "Forgot Password",
    //                     url: "/classic-plus/forgot-password",
    //                     Icon: ShieldOff,
    //                 },
    //             ],
    //         },
    //         {
    //             id: 42,
    //             title: "Error Pages",
    //             Icon: AlertCircle,
    //             submenu: [
    //                 {
    //                     id: 321,
    //                     label: "404 Page Not Found",
    //                     url: "/classic-plus/error-404",
    //                     Icon: File,
    //                 },
    //                 {
    //                     id: 322,
    //                     label: "500 Internal Server",
    //                     url: "/classic-plus/error-500",
    //                     Icon: File,
    //                 },
    //                 {
    //                     id: 323,
    //                     label: "503 Service Unavailable",
    //                     url: "/classic-plus/error-503",
    //                     Icon: File,
    //                 },
    //                 {
    //                     id: 324,
    //                     label: "505 Forbidden",
    //                     url: "/classic-plus/error-505",
    //                     Icon: File,
    //                 },
    //             ],
    //         },
    //         {
    //             id: 43,
    //             title: "User Pages",
    //             Icon: User,
    //             submenu: [
    //                 {
    //                     id: 331,
    //                     label: "View Profile",
    //                     url: "/classic-plus/profile-view",
    //                     Icon: User,
    //                 },
    //                 {
    //                     id: 332,
    //                     label: "Connections",
    //                     url: "/classic-plus/connections",
    //                     Icon: Users,
    //                 },
    //                 {
    //                     id: 333,
    //                     label: "Groups",
    //                     url: "/classic-plus/groups",
    //                     Icon: Users,
    //                 },
    //                 {
    //                     id: 334,
    //                     label: "Events",
    //                     url: "/classic-plus/events",
    //                     Icon: Calendar,
    //                 },
    //             ],
    //         },
    //         {
    //             id: 44,
    //             title: "Other Pages",
    //             Icon: File,
    //             submenu: [
    //                 {
    //                     id: 341,
    //                     label: "Timeline",
    //                     url: "/classic-plus/timeline",
    //                     Icon: FileText,
    //                 },
    //                 {
    //                     id: 342,
    //                     label: "Pricing",
    //                     url: "/classic-plus/pricing",
    //                     Icon: FileText,
    //                 },
    //                 {
    //                     id: 343,
    //                     label: "Help Center",
    //                     url: "/classic-plus/help-center",
    //                     Icon: FileText,
    //                 },
    //                 {
    //                     id: 344,
    //                     label: "Invoice",
    //                     url: "/classic-plus/invoice",
    //                     Icon: FileText,
    //                 },
    //             ],
    //         },
    //     ],
    // },
    // {
    //     id: 5,
    //     label: "User Interface",
    //     url: "/",
    //     Icon: Package,
    //     submenu: [
    //         {
    //             id: 41,
    //             label: "Components",
    //             url: "https://doar-component.jamstacktemplates.dev/?path=/docs/introduction--page",
    //             Icon: Box,
    //         },
    //         {
    //             id: 42,
    //             label: "Collections",
    //             url: "#!",
    //             Icon: Archive,
    //         },
    //     ],
    // },
];

export default asideMenus;
