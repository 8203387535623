import styled, { css, themeGet } from "@doar/shared/styled";
import { device } from "@doar/shared";

export const StyledListingApp = styled(({ ...rest }) => <div {...rest} />)`
  width: 100%;
  height: 100%;
  display: flex;

  .vertical-card-view {
    height: 100%;


    &.grid-view {
      width: 100% !important;
    }

    &:not(.grid-view) {
      display: none;

      ${device.small} {
        display: block;
      }
      
      .featured-property-wrapper, .rated-property-wrapper {
        img {
          ${device.small} {
            width: 158px !important;
          }

          ${device.medium} {
            width: 350px !important;
          }

          ${device.large} {
            width: 200px !important;
          }
        }
      }
    }


    ${device.small} {
      width: 199px;
    }

    ${device.large} {
      width: 394px;
    }

    ${device.xlarge} {
      width: 591px;
    }


    .vertical-card-view-wrapper {
      height: 100%;
      width: 100%;
      flex: 1 1 1px;
      overflow-y: auto;
      
      .title{
        font-weight: 600;
        padding: 0.5rem !important;
        padding-left: 1rem !important;
        display: flex;
        justify-content: space-between;
      }
      
    }


  }

  .horizontal-card-view {
    display: none;
    flex-direction: column;
    align-items: start;
    bottom: 20px;
    height: 270px;
    left: 0;
    position: absolute;
    right: 0;
    z-index: 7;
    
    & > div{
      width: 100%;
    }
    
    &:not(.grid-view) {
      display: flex;
      ${device.small} {
        display: none;
      }
    }

  }

  .map-view {
    flex-grow: 1;
    z-index: 0;
  }

  .housePin {
    align-items: center;
    background-color: ${themeGet("colors.primary")};
    color: #fff;
    font-size: 15px;
    justify-content: center;
    text-align: center;
    border-radius: 5px;
    opacity: 1;
    width: 150px;
    display: flex;
    flex-direction: column;
    box-shadow: 1px 1px 4px 4px #0000003d;
    position: relative;

    .imageWrapper{
      display: none;
      position: relative;
      img{
        width: 100%;
      }
      .hover-details{
        position: absolute;
        bottom: 6px;
        background-color: #00000070;
        width: -webkit-fill-available;
        left: 6px;
        right: 5px;
      }
    }
    
     &:hover{
       .imageWrapper{
         display: block;
         position: absolute;
         padding: 5px;
         bottom: 0;
         z-index: 100;
         background-color: #f84163;
         border-radius: 5px;
       }
     }
  }

  .housePinArrow {
    display: flex;
    width: 150px;
    justify-content: center;

    & > div {
      background-color: ${themeGet("colors.primary")};
      height: 20px;
      margin-top: -15px;
      opacity: 1;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
      -webkit-transform-origin: center;
      transform-origin: center;
      width: 20px;
      z-index: -1;
    }
  }
  
  .locationCircle {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${themeGet("colors.primary")};
    color: #fff;
    //font-size: 15px;
    font-family: ${themeGet("fonts.poppins")};
    justify-content: center;
    text-align: center;
    border-radius: 116px;
    height: 100px;
    opacity: .7;
    width: 100px;
  }
  
  
`;
export const StyledPropertyItemWrapper = styled(({ ...rest }) => <div {...rest} />)`

  display: flex;
  flex-flow: wrap;
  justify-content: center;
  margin-bottom: 1.5rem;

`;

export const StyledFeaturedPropertyItem = styled(({ ...rest }) => <div {...rest} />)`
    height: 100%;

    .card {
        cursor: pointer;
        //padding: 0.5rem !important;
        margin: 0.5rem !important;
        height: 100%;
        border: none;
        border-radius: 15px;
        box-shadow: 0 0 20px rgba(8, 21, 66, .05);
        letter-spacing: .5px;
        //margin-bottom: 30px;

        .featured-property-wrapper {
            padding-bottom: 0.5rem !important;
            position: relative;
            height: 100%;

            img {
                height: 100%;
                width: calc(100vw * 0.5);
                max-width: 200px;
                min-width: 150px;
                object-fit: cover;
                border-radius: 12px;
            }

            .featured-property-top-actions {
                position: absolute;
                top: 0;
                left: 10px;
                height: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
            }

            .featured-property-details {
                position: absolute;
                bottom: 0;
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 1rem !important;
                padding-bottom: 1.5rem !important;
                color: white;

                .price-text {
                    font-weight: 700;
                    text-transform: capitalize;
                    color: white;
                    margin: 0px;
                }


            }
        }
    }

`;

export const StyledHighestRatedPropertyItem = styled(({ onClick, ...rest }) => <div onClick={onClick} {...rest} />)`
    height: 100%;

    .card {
        cursor: pointer;
        //padding: 0.5rem !important;
        margin: 0.5rem !important;
        height: 100%;
        border: none;
        border-radius: 15px;
        box-shadow: 0 0 20px rgba(8, 21, 66, .05);
        letter-spacing: .5px;
        //margin-bottom: 30px;

        .rated-property-wrapper {
            padding-bottom: 0.5rem !important;
            position: relative;
            height: 100%;

            img {
                height: 100%;
                width: calc(100vw * 0.5);
                max-width: 200px;
                min-width: 150px;
                object-fit: cover;
                border-radius: 12px;
            }

            .rated-property-top-actions {
                position: absolute;
                top: 0;
                left: 10px;
                height: 40px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;

            }

            .rated-property-details {
                position: absolute;
                bottom: 0;
                display: flex;
                flex-direction: column;
                width: 100%;
                padding: 0.5rem !important;
                color: white;

                & > div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;

                    .price-text {
                        font-weight: 700;
                        text-transform: capitalize;
                        color: white;
                        margin: 0px;
                    }
                }

                .card-details {
                    justify-content: space-around;
                    padding: 0.25rem !important;

                    & > div {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }

                }

                .address-text {
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

            }
        }
    }

`;

export const StyledPropertyItem = styled(({ ...rest }) => <div {...rest} />)`
    height: fit-content;

    .property-item {
        cursor: pointer;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        padding: .25rem !important;
        margin: .25rem !important;
        width: calc(100vw / 2 - 15px);
        max-width: 185px;
        min-width: 140px;
        border-radius: 10px;
        background-color: ${themeGet("colors.dark")};

        &.horizontal {
            .horizontal {
                justify-content: center;
            }

        }


        ${(props) =>
                props.theme.name !== "dark" &&
                css`
                    background-color: ${themeGet("colors.white")};
                `}
        img {
            border-radius: 10px;
            height: 10rem;
            object-fit: cover;
            width: 100%;
        }

        .pricing-details {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;
            margin-left: unset !important;
            margin-right: unset !important;
        }

        .address-text {
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-box;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 0 7px 0 7px;
        }

        .price-text {
            font-weight: 700;
            text-transform: capitalize;
            margin: 0px;
            color: ${themeGet("colors.primary")};
        }

        .property-item-actions {
            top: 0;
            height: 40px;
            position: absolute;
            display: flex;
            justify-content: space-between;
            width: 100%;
            align-items: center;
            padding-left: 0.5rem;
        }

        .card-details {
            display: flex;
            justify-content: space-around;
            align-items: center;
            width: 100%;

            & > div {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 0px;

                & > div {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .col {
                align-items: center;
            }

            h6 {
                margin-bottom: 0px;
                margin-right: 5px;
            }
        }

    }

`;
