import {TenantRole} from "../graphql/generated/tenant-schema";
import {lazy} from "react";

const ClassicPlusSignUp = lazy(() => import("../pages/classic-plus/signup"));
const ClassicPlusDashboardFour = lazy(
    () => import("../pages/classic-plus/dashboard-four")
);
const Houses = lazy(() => import("../components/profile"));
const ClassicPlusChat = lazy(() => import("../pages/classic-plus/apps/chat"));
const PropertyModules = lazy(() => import("../components/properties"));
const WishListTab = lazy(() => import("../components/app/profile/wishlist-tab"));
const UserModules = lazy(() => import("../components/users"));
const ClassicPlusContacts = lazy(
    () => import("../pages/classic-plus/apps/contacts")
);
export const routes = [
    // {path: "/", element: <ClassicPlusDashboardFour/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.Agent]},
    {path: "/houses", element: <PropertyModules/>, allowedRoles: [TenantRole.User]},
    {path: "/messages", element: <ClassicPlusChat/>, allowedRoles: [TenantRole.User]},
    {path: "/wishlist", element: <WishListTab/>, allowedRoles: [TenantRole.User]},
    // {path: "/properties", element: <PropertyModules/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.Agent]},
    // {path: "/users", element: <UserModules/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.Agent]},
    // {path: "/maintenance", element: <PropertyModules/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.Agent]},
    // {path: "/contacts", element: <ClassicPlusContacts/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.Agent]},
    // {path: "/accounting", element: <ClassicPlusDashboardFour/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.Agent]},
    // {path: "/reports", element: <ClassicPlusDashboardFour/>, allowedRoles: [Role.Owner, Role.CoOwner, Role.Agent]},
    // {path: "/test", element: <HomePage/>, allowedRoles:[]},
]
