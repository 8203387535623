import {SpaceProps, BorderProps} from "@doar/shared/styled";
import {Container} from "@doar/components";
import {StyledContent} from "./style";

interface IProps extends SpaceProps, BorderProps {
    children: React.ReactNode;
    className?: string;
    fullHeight?: boolean;
    fullWidth?: boolean;
    hasFooter?: boolean;
    align?: "top" | "center" | "bottom";
}

const Content = ({
                     children,
                     className,
                     fullHeight,
                     fullWidth,
                     align,
                     hasFooter,
                     ...restProps
                 }: IProps) => {
    return (
        <StyledContent
            $fullHeight={fullHeight}
            $fullWidth={fullWidth}
            $align={align}
            className={className}
            $hasFooter={hasFooter}
            {...restProps}
        >
            <Container className="container" pl="0" pr="0"
                       fullHeight={fullHeight}
                       fullWidth={fullWidth}>
                {children}
            </Container>
        </StyledContent>
    );
};

export default Content;
