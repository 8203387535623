import {Link} from "react-router-dom";
import {Bell} from "react-feather";
import {DropdownToggle, Dropdown} from "@doar/components";
import Notification from "../dropdown-item";
import {
    StyledDropMenu,
    StyledDropHeader,
    StyledDropItem,
    StyledDropFooter,
    StyledBadge,
} from "../header-dropdown-elements";
import {NotificationType} from "src";

const NotificationDropdown = ({generalNotifications}: { generalNotifications: NotificationType[] }) => {
    return (
        <Dropdown direction="down">
            <DropdownToggle variant="texted">
                <Bell className="header-icon"/>
                <StyledBadge>{generalNotifications.length}</StyledBadge>
            </DropdownToggle>
            <StyledDropMenu>
                <StyledDropHeader>NOTIFICATIONS</StyledDropHeader>
                {generalNotifications.length > 0 ? generalNotifications.map((notification, index) =>
                    <StyledDropItem key={`message_${index}`} path="/profile-view">
                        <Notification generalNotification={notification}/>
                    </StyledDropItem>
                ) : <small style={{
                    width: "100%",
                    display: "inherit",
                    textAlign: "center"
                }}>No Notifications at the moment</small>}
                <StyledDropFooter>
                    <Link to="/profile-view">View all Notifications</Link>
                </StyledDropFooter>
            </StyledDropMenu>
        </Dropdown>
    );
};

export default NotificationDropdown;
