import { Media, MediaLeft, Avatar, AvatarInitial } from "@doar/components";
import { StyledMediaBody, StyledStrong, StyledText, StyledSpan } from "./style";
import {dateTimeFormat} from "../../settings/data";
import {NotificationType} from "../../../index";

const DropdownItem = ({chatNotification, generalNotification}:{chatNotification?:any, generalNotification?:NotificationType, }) => {
    return (
        <Media>
            <MediaLeft>
                <Avatar size="sm" shape="circle" status="online">
                    <AvatarInitial>{chatNotification?.ownerId}</AvatarInitial>
                </Avatar>
            </MediaLeft>
            <StyledMediaBody>
                <StyledStrong>{generalNotification?.title??"Owner"}</StyledStrong>
                <StyledText>{chatNotification?.content??generalNotification?.message}</StyledText>
                <StyledSpan>{dateTimeFormat({
                    hour: '2-digit',
                    minute: '2-digit',
                }).format(new Date(chatNotification?.sentAt??generalNotification?.timestamp))}</StyledSpan>
            </StyledMediaBody>
        </Media>
    );
};

export default DropdownItem;
