
export const fetchAddressDetailsByLocation = (latLng) => {
    return fetch('https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=' + latLng.lat + '&lon=' + latLng.lng, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const fetchLocationBoundary = (circleDetail) => {
    return fetch(`${process.env.PUBLIC_URL}` +
        (circleDetail?.district != null ? ("/api/boundaries/districts/" + circleDetail?.county + "/" + circleDetail?.constituency + "/" + circleDetail?.district + ".json")
            : (circleDetail?.constituency != null ? "api/boundaries/constituencies/" + circleDetail?.county + "/" + circleDetail?.constituency + ".json" : (circleDetail?.county != null ? "api/boundaries/counties/" + circleDetail?.county + ".json" : "api/boundaries/countries/" + circleDetail?.country + ".json"))), {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};

export const fetchLocationAutocomplete = (query) => {
    return fetch("https://photon.komoot.io/api/?q=" + encodeURI(query) + "&bbox=33.913,-4.689,41.900,5.042", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};
