import React, {Fragment, Suspense, useEffect, useRef, useState} from 'react';
import Taptop from "./layout/tap-top";
import Footer from './layout/footer'
import {ToastContainer} from 'react-toastify'
import {
    Apartment, House, HouseStatus, HouseType,
    useGetFeaturedApartmentsLazyQuery,
    useGetHighestRatedHousesLazyQuery
} from "../graphql/generated/tenant-schema";
import {LeaseTypes} from "./settings/data";
import {Splide, SplideSlide} from "@splidejs/react-splide";
import LandingPage from './landing';
import {StyleLandingBackground} from './landing/style';
import Content from "../layouts/content";
import {Col} from "@doar/components";
import FeaturedPropertyItem from "./app/featured-property-item";
import HighestRatedPropertyItem from "./app/highest-rated-property-item";
import {Grid} from '@splidejs/splide-extension-grid';
import '@splidejs/react-splide/css'
import TenantPropertyListingDetails from "./app/tenant-property-listing-details";
import {X} from "react-feather";

export type PartialApartment = {
    __typename?: "Apartment" | undefined;
    id: string;
    name: string;
    district: string;
    constituency: string;
    county: string;
    country?: string | null | undefined;
    media?: { __typename?: "Media" | undefined; addresses: string[]; thumbnails: string[]; } | null | undefined;
}

export type PartialHouse = {
    __typename?: 'House',
    id: string,
    livingroom: number,
    bedroom: number,
    bathroom: number,
    price: number,
    floor: number,
    houseNumber?: string | null,
    description?: string | null,
    houseType: HouseType,
    status: HouseStatus,
    rate: string,
    apartmentId?: string | null,
    lat?: number | null,
    lng?: number | null,
    country?: string | null,
    county?: string | null,
    constituency?: string | null,
    district?: string | null,
    media?: { __typename?: 'Media', thumbnails: Array<string>, addresses: Array<string> } | null,
    apartment?: {
        __typename?: 'Apartment',
        name: string,
        lat: number,
        lng: number,
        district: string,
        country?: string | null,
        county: string,
        constituency: string
    } | null,
    myInteractions?: { __typename?: 'MyInteractions', liked: boolean, clicked: boolean } | null,
    reviewsAggregate: Array<{
        __typename?: 'HouseReviewsAggregateResponse',
        averageRating?: { __typename?: 'HouseReviewsAvgAggregate', score?: number | null } | null,
        totalReviews?: { __typename?: 'HouseReviewsCountAggregate', id?: number | null } | null
    }>
}

const LandingHomePage = () => {
    const listingAppRef = useRef();
    const filterHeaderRef = useRef();
    //Default Filters
    const [priceFilter, setPriceFilter] = React.useState({min: 0, max: 0});
    const [houseTypeRangeFilter, setHouseTypeRangeFilter] = React.useState({min: 0, max: 0});
    const [leaseTypeFilter, setLeaseTypeFilter] = React.useState(LeaseTypes.ALL);
    const [featuredApartments, setFeaturedApartments] = useState<PartialApartment[]>([]);
    const [highestRatedHouses, setHighestRatedHouses] = useState<PartialHouse[]>([]);
    const [selectedProperty, setSelectedProperty] = useState<any[]>([]);
    const [selectedApartment, setSelectedApartment] = useState<PartialApartment[]>([]);
    const [selectedChatHouse, setSelectedChatHouse] = useState<PartialHouse | null | undefined>(null);


    const [queryFeaturedApartments] = useGetFeaturedApartmentsLazyQuery({
        variables: {
            paging: {
                offset: 0,
                limit: 10
            },
        },
        onCompleted: (results) => {
            setFeaturedApartments(results.featuredApartments.nodes)
        }
    })

    const [queryHighestRatedHouses] = useGetHighestRatedHousesLazyQuery({
        variables: {
            offset: 0,
            limit: 10
        },
        onCompleted: (results) => {
            setHighestRatedHouses(results.getHighestRatedHouses)
        }
    })


    function openChat(house: House) {
        setSelectedChatHouse(house);
        // setChat(chat => ({
        //     ...chat,
        //     isOpen: true,
        // }));
        // getChat({
        //     variables: {
        //         houseId: house.id
        //     }
        // })
    }

    useEffect(() => {
        queryFeaturedApartments()
        queryHighestRatedHouses()
    }, [])


    return (
        <Fragment>
            <Content fullWidth fullHeight>
                <Taptop/>
                <StyleLandingBackground className="page-body-wrapper d-flex flex-column">
                    <Fragment>
                        <LandingPage ref={listingAppRef}/>
                        <Col className={'landing-page-properties'} mb={3} ml={0} mr={0}>
                            <Col sm={12} p={3}>
                                <Col p={3}>
                                    <h3
                                        style={{
                                            textAlign: 'center'
                                        }}
                                        className={'font-weight-bold d-flex justify-content-between text-center'}> Featured
                                        Apartments close to you<p className={'text-muted small font-weight-normal'}></p>
                                    </h3>
                                </Col>
                                <div className={`w-100 pt-0`}>
                                    {featuredApartments?.length > 0 ?
                                        <Splide
                                            className="main_slide"
                                            extensions={{Grid}}
                                            options={{
                                                type: 'loop',
                                                // perPage: 1,
                                                perMove: 1,
                                                gap: '0.5rem',
                                                autoplay: true,
                                                pagination: false,
                                                height: '25rem',
                                                autoWidth: true,
                                                grid: {
                                                    dimensions: [[1, 1], [2, 2], [2, 1], [1, 2], [2, 2], [3, 2]],
                                                    gap: {
                                                        row: '6px',
                                                        col: '6px',
                                                    },
                                                }
                                            }}>
                                            {
                                                featuredApartments?.map((apartment, index) => (

                                                    <SplideSlide
                                                        key={`homepage_main_slide_${index}`}
                                                        onClick={(e) =>
                                                            setSelectedApartment([apartment])
                                                        }
                                                    >
                                                        <Suspense key={`suspense_${index}`} fallback={
                                                            <div
                                                                key={`suspense_fallback_${index}`}></div>
                                                        }>
                                                            <FeaturedPropertyItem
                                                                key={`featured_property_item_${index}`}
                                                                openChat={openChat}
                                                                data={[apartment]}/>
                                                        </Suspense>
                                                    </SplideSlide>
                                                ))
                                            }
                                        </Splide> : null}
                                </div>
                            </Col>
                            <Col sm={12} pb={3}>
                                <Col mt={5} p={3}><h3
                                    style={{
                                        textAlign: 'center'
                                    }}
                                    className={'font-weight-bold p-2 pl-2 d-flex justify-content-between'}>Highest
                                    Rated close to you<span
                                        className={'text-muted small font-weight-normal'}></span></h3></Col>
                                <div className={`w-100 pt-0`}>
                                    {highestRatedHouses?.length > 0 ?
                                        <Splide
                                            className="main_slide"
                                            extensions={{Grid}}
                                            options={{
                                                type: 'loop',
                                                // perPage: 1,
                                                perMove: 1,
                                                autoplay: true,
                                                gap: '0.5rem',
                                                pagination: false,
                                                height: '34rem',
                                                autoWidth: true,
                                                grid: {
                                                    rows: 2,
                                                    gap: {
                                                        row: '6px',
                                                        col: '6px',
                                                    },
                                                }
                                            }}>
                                            {
                                                highestRatedHouses?.map((house: PartialHouse, index: number) => (
                                                    <SplideSlide
                                                        key={`homepage_main_slide_${index}`}
                                                        onClick={(e) => setSelectedProperty([house])}
                                                    >
                                                        <Suspense key={`suspense_${index}`} fallback={
                                                            <div
                                                                key={`suspense_fallback_${index}`}></div>
                                                        }>
                                                            <HighestRatedPropertyItem
                                                                key={`highest_rated_property_item_${index}`}
                                                                openChat={openChat}
                                                                data={[house]}/>
                                                        </Suspense>
                                                    </SplideSlide>
                                                ))
                                            }
                                        </Splide> : null}
                                </div>
                            </Col>
                        </Col>
                    </Fragment>

                    {selectedApartment != null && typeof selectedApartment !== 'undefined' && Object.keys(selectedApartment).length > 0 ?
                        <div className="apartment-details">
                            <X className={'close-button'}
                               onClick={() => setSelectedApartment([])}/>
                            <TenantPropertyListingDetails selectedPropertyId={selectedApartment[0]?.id}
                                                          setSelectedProperty={setSelectedProperty}
                                                          openChat={openChat}
                                                          isHouse={false}
                            />
                        </div> : null}
                    {selectedProperty != null && typeof selectedProperty !== 'undefined' && Object.keys(selectedProperty).length > 0 ?
                        <div className="property-details">
                            <X className={'close-button'}
                               onClick={() => setSelectedProperty([])}/>
                            <TenantPropertyListingDetails selectedPropertyId={selectedProperty[0]?.id}
                                                          setSelectedProperty={setSelectedProperty}
                                                          openChat={openChat}
                                                          propertiesCount={selectedProperty?.length ?? 0}
                                                          isHouse={true}
                            />
                        </div> : null}
                    <Col mb={'60px'}><Footer noMargin/></Col>
                </StyleLandingBackground>
                <ToastContainer/>
            </Content>

        </Fragment>
    );
}
export default LandingHomePage;
