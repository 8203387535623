import React, {Fragment} from 'react';
import {Col, Container, Row} from "@doar/components";

const Footer = (props) => {
    return (
        <Fragment>
                <footer className={`footer position-fixed w-100 ${props?.noMargin ? 'm-0' : ''}`} style={{
                    zIndex: 1,
                    height: props?.height ?? 50,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center"
                }}>
                    <Container fluid={true}>
                        <Row>
                            <Col md="12" className="footer-copyright text-center">
                                <p className="mb-0">{"Copyright 2023 ©  KejaRent PLC."}</p>
                            </Col>
                        </Row>
                    </Container>
                </footer>
        </Fragment>
    );
}

export default Footer;
