import { Suspense, lazy, useState } from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Outlet,
} from "react-router-dom";
import Preloader from "./components/preloader";
import Layout from "./layouts";
import Layout02 from "./layouts/layout-02";
import { PrivateRoute } from "./routes/PrivateRoute";
import { routes } from "./routes/routes";
import { NotificationType } from "./index";
import { getCookie, setCookie } from "react-use-cookie";
import { useAppSelector } from "./redux/hooks";
import {
    TenantRole,
    useNewMessageSubscription,
    useNewRentalMessageSubscription
} from "./graphql/generated/tenant-schema";
import moment from "moment";
import { toast } from "react-toastify";
import { ToastWithTitle } from "./components/settings";
import { chatService, handleErrorResponse, notificationService, subscriptionClient } from "./components/settings/data";
import LandingHomePage from "./components/landingPage";
const ClassicPlusSignIn = lazy(() => import("./pages/classic-plus/signin"));
const VerifyAccount = lazy(() => import("./pages/classic-plus/delete-account"));
const KnowledgebaseComponent = lazy(() => import("./components/faq"));
const PrivacyPolicy = lazy(() => import("./components/privacy-policy"));
const ClassicPlusErrorNotFound = lazy(() => import("./pages/classic-plus/error-404"));
const HomePage = lazy(() => import("./components/homePage"));

const App = () => {


    const [notifications, setNotifications] = useState<NotificationType[]>(JSON.parse(getCookie('notifications', '[]')));
    const { theme } = useAppSelector((state) => state.theme);




    useNewMessageSubscription({
        onSubscriptionData(data) {
            const receivedData = data?.subscriptionData?.data?.newMessageReceived;
            if (receivedData) {
                notificationService.chatNotification.next(receivedData);
                chatService.newMessageSubject.next({
                    houseId: receivedData.houseId,
                    message: receivedData.message
                })
            }
        },
        client: subscriptionClient,
        shouldResubscribe: true,
        fetchPolicy: 'network-only'
    })


    useNewRentalMessageSubscription({
        onSubscriptionData: (data) => {
            if (data.subscriptionData.data) {
                toast.info(<ToastWithTitle title={data.subscriptionData.data.message.title ?? "New Rental Message"}
                    message={data.subscriptionData.data.message.message ?? "Please check on new chats for messages from owners"} />);
                setNotifications(current => {
                    const notis = [{
                        ...data.subscriptionData.data?.message,
                        timestamp: new Date()
                    }, ...current].filter(notification => moment().diff(moment(notification.timestamp), "day") < 1);
                    setCookie('notifications', JSON.stringify(notis))
                    notificationService.generalNotifications.next(notis)
                    return notis
                })
            }
        },
        client: subscriptionClient,
        shouldResubscribe: true,
        fetchPolicy: 'network-only',
        onError: (error) => {
            handleErrorResponse(error)
        }
    })


    return (
        <>
            <Router>
                <Suspense fallback={<Preloader />}>
                    <Routes>
                        {/* Apps Routes */}

                        {/* Classic Plus Routes */}

                        <Route element={
                            <Layout hideFooter={true}>
                                <Outlet />
                            </Layout>
                        }>
                            {/* Dashboard Routes */}
                            {/*<Route*/}
                            {/*    path="/"*/}
                            {/*    element={<ClassicPlusDashboardFour />}*/}
                            {/*/>*/}

                            {
                                routes.map((route, index) =>
                                    <Route
                                        key={`route_${index}`}
                                        path={route.path}
                                        element={
                                            <PrivateRoute element={route.element}
                                                allowedRoles={route.allowedRoles} />
                                        }
                                    />)
                            }
                            <Route path="/faq" element={<KnowledgebaseComponent />} />
                            <Route path="/privacy-policy" element={<PrivacyPolicy />} />


                            {/*<Route*/}
                            {/*    path="/classic-plus/dashboard-two"*/}
                            {/*    element={<ClassicPlusDashboardTwo/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/dashboard-three"*/}
                            {/*    element={<ClassicPlusDashboardThree/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/dashboard-four"*/}
                            {/*    element={<ClassicPlusDashboardOne/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/dashboard-five"*/}
                            {/*    element={<ClassicPlusDashboardFive/>}*/}
                            {/*/>*/}

                            {/*/!* Management Routes *!/*/}
                            {/*<Route*/}
                            {/*    path="/properties"*/}
                            {/*    element={<PropertyModules/>}*/}
                            {/*/>*/}

                            {/*/!* Authentication Routes *!/*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/signin"*/}
                            {/*    element={<ClassicPlusSignIn/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/signup"*/}
                            {/*    element={<ClassicPlusSignUp/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/verify-account"*/}
                            {/*    element={<ClassicPlusVerifyAccount/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/forgot-password"*/}
                            {/*    element={<ClassicPlusForgotPassword/>}*/}
                            {/*/>*/}

                            {/*/!* Error Routes *!/*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/error-500"*/}
                            {/*    element={<ClassicPlusError500/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/error-503"*/}
                            {/*    element={<ClassicPlusError503/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/error-505"*/}
                            {/*    element={<ClassicPlusError505/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/error-404"*/}
                            {/*    element={<ClassicPlusErrorNotFound/>}*/}
                            {/*/>*/}

                            {/*/!* User Routes *!/*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/profile-view"*/}
                            {/*    element={<ClassicPlusProfileView/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/connections"*/}
                            {/*    element={<ClassicPlusConnections/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/groups"*/}
                            {/*    element={<ClassicPlusGroups/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/events"*/}
                            {/*    element={<ClassicPlusEvents/>}*/}
                            {/*/>*/}

                            {/*/!* Other Routes *!/*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/timeline"*/}
                            {/*    element={<ClassicPlusTimeline/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/pricing"*/}
                            {/*    element={<ClassicPlusPricing/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/help-center"*/}
                            {/*    element={<ClassicPlusHelpCenter/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/invoice"*/}
                            {/*    element={<ClassicPlusInvoice/>}*/}
                            {/*/>*/}

                            {/*<Route*/}
                            {/*    path="/apps/contacts"*/}
                            {/*    element={<Contacts/>}*/}
                            {/*/>*/}
                            {/*<Route path="/apps/chat" element={<Chat/>}/>*/}
                            {/*<Route*/}
                            {/*    path="/apps/calendar"*/}
                            {/*    element={<Calendar/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/apps/file-manager"*/}
                            {/*    element={<FileManager/>}*/}
                            {/*/>*/}
                            {/*<Route path="/apps/mail" element={<Mail/>}/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/apps/calendar"*/}
                            {/*    element={<ClassicPlusCalendar/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/apps/file-manager"*/}
                            {/*    element={<ClassicPlusFileManager/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/apps/mail"*/}
                            {/*    element={<ClassicPlusMail/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/messages"*/}
                            {/*    element={<ClassicPlusChat/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/apps/contacts"*/}
                            {/*    element={<ClassicPlusContacts/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/apps/calendar"*/}
                            {/*    element={<ClassicPlusCalendar/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/apps/file-manager"*/}
                            {/*    element={<ClassicPlusFileManager/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/apps/mail"*/}
                            {/*    element={<ClassicPlusMail/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/messages"*/}
                            {/*    element={<ClassicPlusChat/>}*/}
                            {/*/>*/}
                            {/*<Route*/}
                            {/*    path="/classic-plus/apps/contacts"*/}
                            {/*    element={<ClassicPlusContacts/>}*/}
                            {/*/>*/}
                        </Route>
                        <Route element={
                            <Layout hasSidebar={false} hideFooter={true} >
                                <Outlet />
                            </Layout>
                        }>
                            <Route path="/" element={<LandingHomePage />} />
                        </Route>
                        <Route path="/search" element={<HomePage />} />
                        <Route path="/login" element={<ClassicPlusSignIn />} />
                        <Route path="/account-deletion" element={<VerifyAccount />} />
                        <Route path="*" element={<ClassicPlusErrorNotFound />} />
                    </Routes>
                </Suspense>
            </Router>
        </>
    );
};

export default App;
