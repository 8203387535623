import {Link} from "react-router-dom";
import {MessageSquare} from "react-feather";
import {DropdownToggle, Dropdown} from "@doar/components";
import Notification from "../dropdown-item";
import {
    StyledDropMenu,
    StyledDropHeader,
    StyledDropItem,
    StyledDropFooter,
    StyledBadge,
} from "../header-dropdown-elements";

const MessageDropdown = ({chatNotifications}: { chatNotifications: any }) => {
    return (
        <Dropdown direction="down">
            <DropdownToggle variant="texted">
                <MessageSquare className="header-icon"/>
                <StyledBadge>{Object.keys(chatNotifications).length}</StyledBadge>
            </DropdownToggle>
            <StyledDropMenu>
                <StyledDropHeader>View all Messages</StyledDropHeader>
                {Object.keys(chatNotifications).length > 0 ? Object.keys(chatNotifications).map((key, index) => {
                    const notification = chatNotifications[key];
                    return <StyledDropItem key={`chat_notification_${index}`} path="/apps/chat">
                        <Notification chatNotification={notification}/>
                    </StyledDropItem>
                }) : <small style={{
                    width: "100%",
                    display: "inherit",
                    textAlign: "center"
                }}>No Messages at the moment</small>}
                <StyledDropFooter>
                    <Link to="/apps/chat">View all Messages</Link>
                </StyledDropFooter>
            </StyledDropMenu>
        </Dropdown>
    );
};

export default MessageDropdown;
