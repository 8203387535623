import styled, {themeGet} from "@doar/shared/styled";
import {css} from "styled-components";
import {device} from "@doar/shared";
import TextTransition from 'react-text-transition';

export const StyleLandingBackground = styled(({...rest}) => <div {...rest} />)`
    background-color: ${themeGet("colors.background")};
    height: 100%;
    overflow-y: auto;

    .search-menu {
        position: absolute !important;
    }

    select#homePageRoomSize {
        -moz-appearance: none;
        -webkit-appearance: none;
        box-shadow: none;
    }

    .landing-page-properties {
        padding-left: 0px;
        padding-right: 0px;

        ${(device.medium)} {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    .landing-search-wrapper-parent {
        flex-direction: column;
        align-items: center;
        width: 100%;

        ${(device.medium)} {
            flex-direction: row;
        }
        
        .map-search-button-wrapper{
            margin-left: 0px;
            width: fit-content;
            height: 50px;

            ${(device.medium)} {
                margin-left: 30px;
                width: unset;
                height: 70px;
            }
        }
        
    }

    .landing-search-wrapper {
        zIndex: 1;
        width: 100%;
        border: 1px solid #0000001f;
        justify-content: center;
        align-items: center;
        padding-left: 10px;
        padding-right: 10px;
        margin-left: 0px;
        margin-right: 0px;
        position: relative;
        height: max-content;
        background-color: ${themeGet("colors.white")};
        margin-bottom: 10px;
        flex-direction: column;


        ${(props) =>
                props.theme.name === "dark" &&
                css`
                    background-color: ${themeGet("colors.background")};
                `}
        ${(device.small)} {
            margin-left: unset;
            margin-right: unset;
        }

        ${(device.medium)} {
            width: fit-content;
            margin-bottom: unset;
            flex-direction: row;
            margin-left: 10px;
            margin-right: 10px;
        }

        & > * {
            border-right: none;

            ${(device.medium)} {
                border-right: 1px solid rgba(0, 0, 0, 0.12);
            }


            &:not(.landing-search) {
                max-height: 50px;
                padding: 10px;

                ${(device.medium)} {
                    padding: unset;
                }
            }

            &.bedroom-select {
                padding: unset !important;
                padding-right: 10px !important;
            }
        }


    }

    .landing-search {
        width: 100%;
        margin-left: 0;
        margin-right: 0;

        ${(device.medium)} {
            width: unset;
            height: unset;
            margin-left: 10px;
            margin-right: -15px;
        }

        .is-landing-page {
            > div {
                flex-direction: column;
                
                .location-search{
                    height: 50px;
                    ${(device.medium)} {
                        height: unset;
                    }
                    
                    input{
                        text-align: center;
                        ${(device.medium)} {
                            text-align: unset;
                        }

                    }
                }

                ${(device.medium)} {
                    flex-direction: row;
                }
            }

        }
        
        .map-mode-selector{
            flex-direction: row;
            padding: 15px 15px;
            width: 100%;

            ${(device.medium)} {
                flex-direction: column;
                width: unset;
            }
        }

    }

    .landingPageAnimation {
        max-width: 1500px;
        display: flex;
        justify-content: center;
        align-items: end;

        svg {
            height: fit-content !important;
            //min-width: 700px;
            //position: fixed;
        }

    }

    .scrollDownAnimation {
        display: flex;
        justify-content: end;
        align-items: end;
        width: 50px;
        height: 50px;

        ${(device.small)} {
            width: 70px;
            height: 70px;
        }


    }


    ${(props) =>
            props.theme.name !== "dark" &&
            css`
                background-color: ${themeGet("colors.white")};
            `}
    .apartment-details {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        top: 60px;
        background-color: white;
        z-index: 10;
        transition-duration: 5s;
        overflow-y: scroll;

        .close-button {
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }

    .property-details {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        top: 60px;
        z-index: 10;
        transition-duration: 5s;
        overflow: auto;
        background-color: ${themeGet("colors.background")};

        ${(props) =>
                props.theme.name !== "dark" &&
                css`
                    background-color: ${themeGet("colors.white")};
                `}
        .close-button {
            position: absolute;
            top: 5px;
            right: 5px;
        }
    }
`;


export const StyledTextTransition = styled(({...rest}) => <TextTransition {...rest} />)`
  display: inline-flex !important;
  font-weight: bold;
`;
