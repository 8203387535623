import {TTheme} from "@doar/shared/types";
import {ApolloClient, ApolloError, ApolloLink, InMemoryCache} from "@apollo/client";
// @ts-ignore
import {createUploadLink} from "apollo-upload-client";
import {useMemo} from "react";

const config = require("./config/config.json")
import {BehaviorSubject} from "rxjs";
import firebase from "firebase";
import {getCookie} from "react-use-cookie";
import {toast} from "react-toastify";
import {
    ChatMessage,
    ChatMessagesConnection,
    House, HouseStatus,
    LogoutDocument,
    Maybe, OffsetPageInfo, RentalStatus,
    Scalars,
    Tenant,
    useGetCurrentSubscriptionQuery
} from "../../graphql/generated/tenant-schema";
import {WebSocketLink} from "@apollo/client/link/ws";
import {Droplet, Grid, Trash2, Truck, Tv, Wifi, X, Zap} from 'react-feather'
import {ToastWithTitle} from ".";
import waterIcon from "@iconify/icons-uil/glass";
import wifiIcon from "@iconify/icons-uil/wifi";
import electricityIcon from "@iconify/icons-uil/bolt";
import balconyIcon from "@iconify/icons-uil/grid";
import wasteIcon from "@iconify/icons-uil/trash";
import transportationIcon from "@iconify/icons-uil/bus";
import fireDetectorIcon from "@iconify/icons-uil/fire";
import cableTVIcon from "@iconify/icons-uil/tv-retro";
import { NotificationType } from "src";


export const phoneNumberPrefix = '+254';
export const phoneRegex = /^([+])?(254)?(0)?([17])\d{8}\b/gm
export const passwordRegex = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
export const passwordCountRegex = /(?=.{8,})/
export const passwordSpecialRegex = /(?=.*[!@#$%^&*])/
export const passwordNumericRegex = /(?=.*[0-9])/
export const passwordUppercaseCaseRegex = /(?=.*[A-Z])/
export const passwordLowercaseCaseRegex = /(?=.*[a-z])/


// Firebase
export const firebaseApp = firebase.initializeApp({
    apiKey: config.firebase.apiKey,
    authDomain: config.firebase.authDomain,
    databaseURL: config.firebase.databaseURL,
    projectId: config.firebase.projectId,
    storageBucket: config.firebase.storageBucket,
    messagingSenderId: config.firebase.messagingSenderId,
    appId: config.firebase.appId

});

export const SkinModes: TTheme[] = ["cool", "dark", "classic", "light"];

const tenantLink: ApolloLink = createUploadLink({
    uri: `${process.env.REACT_APP_BACKEND_ENDPOINT || ""}/graphql`,
    credentials: "include",
});
export const apolloClient = new ApolloClient({
    link: tenantLink,
    cache: new InMemoryCache({
        resultCaching: false,
        addTypename: false,
    }),
});
export const useApolloClient = () => {
    const tenantClient = useMemo(
        () =>
            apolloClient,
        []
    );
    return tenantClient;
};

export const subscriptionClient = (new ApolloClient(
    {
        link: ApolloLink.from(
            [
                new WebSocketLink({
                    uri: `${process.env.REACT_APP_BACKEND_WS_ENDPOINT}`,
                    options: {
                        reconnect: true,
                        connectionParams: {
                            authToken: JSON.parse(getCookie(process.env.REACT_APP_TENANT_COOKIE??"tenant", "{}")).jwtToken
                        }
                    }
                })
            ]
        ),
        cache: new InMemoryCache({
            resultCaching: false,
            addTypename: false,
        }),
    }));

declare global {
    export interface Array<T> {
        count(filterMethod: (value: T) => boolean): number
    }

    export interface Number {
        formatCurrency(n?: number, x?: number, s?: string, c?: string): string
    }

    export interface String {
        toCamelCase(removeSpaces?: boolean): string,

        ellipsizeMiddle(length?: number): string,

        splitByCaps(): string,
    }
}

Number.prototype.formatCurrency = function (n = 0, x = 3, s = ',', c = '.') {
    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};
String.prototype.toCamelCase = function (removeSpaces = false) {
    return this.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
String.prototype.ellipsizeMiddle = function (n = 3) {
    return this.substr(0, n) + (this.length > n ? '...' : '') + (this.length > n * 2 ? this.substr(this.length - n, n) : '');
};
String.prototype.splitByCaps = function () {
    return this.split(/(?=[A-Z])/).reduce((a, b) => `${a} ${b}`, "");
};
Array.prototype.count = function (filterMethod) {
    return this.reduce((count, item) => filterMethod(item) ? count + 1 : count, 0);
}

export const dateTimeFormat = (format?: {
    localeMatcher?: "best fit" | "lookup";
    weekday?: "long" | "short" | "narrow";
    era?: "long" | "short" | "narrow";
    year?: "numeric" | "2-digit";
    month?: "numeric" | "2-digit" | "long" | "short" | "narrow";
    day?: "numeric" | "2-digit";
    hour?: "numeric" | "2-digit";
    minute?: "numeric" | "2-digit";
    second?: "numeric" | "2-digit";
    timeZoneName?: "long" | "short";
    formatMatcher?: "best fit" | "basic";
    hour12?: boolean;
    timeZone?: string;
} | undefined) => new Intl.DateTimeFormat('en-US', format ?? {
    hour: '2-digit',
    minute: '2-digit',
    month: '2-digit',
    day: '2-digit',
    year: '2-digit',
})

const loginWithFirebasePhoneAuth = async (phone: string, theme?: TTheme) => {

    return await firebaseApp.auth().signInWithPhoneNumber(phone, new firebase.auth.RecaptchaVerifier('sign-in-button', {
        'size': 'invisible',
        'callback': () => {
            return;
        }
    })).then(function (confirmationResult) {
        toast.success(<ToastWithTitle title={"Verification code sent."}
                                      message={"Kindly check your phone for the code sent and enter it to proceed. Do not share the code with anyone!"}/>,
            {
                theme: theme == "dark" ? "dark" : "light"
            });
        return confirmationResult;
    }).catch((error) => {
        return null;
    });
}

const currentUserSubject = new BehaviorSubject(getCookie(process.env?.REACT_APP_TENANT_COOKIE ?? 'tenant')?JSON.parse(getCookie(process.env?.REACT_APP_TENANT_COOKIE ?? 'tenant')):null);

const createHouseSubject = new BehaviorSubject<() => boolean>(() => {
        return false;
    }
);
const addTenantSubject = new BehaviorSubject(() => {
        return false;
    }
);
const receiveRentSubject = new BehaviorSubject(() => {
        return false;
    }
);

//For the Chat Page. Triggered from the Chat Page
const chatMessageSubject = new BehaviorSubject<ChatUser | undefined>(undefined);
const newMessageSubject = new BehaviorSubject<NewMessage | undefined>(undefined);

//For the rightbar and chat popup. Triggered from App.tsx
const chatNotification = new BehaviorSubject<any>(JSON.parse(getCookie('chats', "{}")));


const generalNotifications = new BehaviorSubject<NotificationType[]>(JSON.parse(getCookie('notifications', "[]")));



const confirmFirebaseVerificationCode = async (code: string, confirmationResult: any, theme?: TTheme) => {

    return confirmationResult.confirm(code).then(async (verificationResult: any) => {
        return verificationResult;
    }).catch((error: any) => {
        toast.error(<ToastWithTitle title={"Code Verification Failed."}
                                    message={error?.message ?? "OTP Code verification failed. Kindly confirm that you've entered the correct code"}/>, {
            theme: theme == "dark" ? "dark" : "light"
        });
        return error;
    })
}

function Logout() {
    apolloClient.mutate({
        mutation: LogoutDocument,
        context: {clientName: "owner"}
    }).then((response) => {
        currentUserSubject.next(null);
        firebaseApp.auth().signOut()
        // window.location.replace("/login")
    }).catch((error) => {
        firebaseApp.auth().signOut()
    })
}


export function handleErrorResponse(error: ApolloError) {
    if (error.message == "GqlAuthGuard") {
        authenticationService.logout()
        // window.location.replace("/login");
    }
}

export const authenticationService = {
    loginWithPhone: loginWithFirebasePhoneAuth,
    confirmVerificationCode: confirmFirebaseVerificationCode,
    currentUserSubject: currentUserSubject,
    currentUser: currentUserSubject.asObservable(),
    logout: Logout
};

export const ownerActionsService = {
    createHouseSubject: createHouseSubject,
    receiveRentSubject: receiveRentSubject,
    addTenantSubject: addTenantSubject
};

export const chatService = {
    selectedChatSubject: chatMessageSubject,
    newMessageSubject: newMessageSubject,
};



const currentSubscription = new BehaviorSubject(async () => {
        try {
            return useGetCurrentSubscriptionQuery().data?.currentSubscription
        } catch (e) {
            return null;
        }
    }
);

export const subscriptionServices = {
    currentSubscription: currentSubscription
};



export const notificationService = {
    chatNotification: chatNotification,
    generalNotifications: generalNotifications,
};







export type NewMessage = {
    houseId: Maybe<string> | undefined,
    message: ChatMessage | { __typename?: 'ChatMessage', sentAt: string, tenantId?: string | null, ownerId?: string | null, content: string }
}

export type ChatUser = { __typename?: 'Chat', id: string, houseId?: string | null, ownerId?: number | null, tenantId?: string | null, createdAt: any,messages?: Maybe<{ __typename?: 'ChatMessagesConnection', nodes: Array<{ __typename?: 'ChatMessage', sentAt: string, tenantId?: string | null, ownerId?: string | null, content: string }> }>, house: { __typename?: 'House', id: string, houseNumber?: string | null, apartment?: { __typename?: 'Apartment', id: string, name: string, country?: string | null, county: string, constituency: string, district: string } | null, media?: { __typename?: 'Media', addresses: Array<string>, thumbnails: Array<string> } | null, rentals?: { __typename?: 'Rental', status: RentalStatus } | null }, owner?: { __typename?: 'Owner', firstName?: string | null, lastName?: string | null, media?: { __typename?: 'Media', addresses: Array<string>, thumbnails: Array<string> } | null } | null }

export const defaultSearchLocation = {
    lat: -1.23075,
    lng: 36.87586
}
// export const houseFeatures = {
//     water: <Droplet/>,
//     wifi: Wifi,
//     "electricity": Zap,
//     "balcony": Grid,
//     "waste": Trash2,
//     "transportation": Truck,
//     "fireDetector": Zap,
//     "cableTV": Tv
// }

export const defaultsConfig = {
    maxBedrooms: 10,
    maxBathrooms: 10,
    maxImageSize: 5000000
}

export const fetchLocationAutocomplete = (query: string) => {
    return fetch("https://photon.komoot.io/api/?q=" + encodeURI(query) + "&bbox=33.913,-4.689,41.900,5.042", {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
    });
};


export const UserRoles = {
    ADMIN: "ADMIN",
    SUPER: "SUPER",
    OWNER: "OWNER",
    AGENT: "AGENT",
    USER: "USER",
}

export const PropertyCarouselType = {
    BIG: "BIG",
    MEDIUM: "MEDIUM",
    NONE: "NONE",
}

export const HouseTypes = {
    "-3": "SINGLE_ROOM",
    "-2": "DOUBLE_ROOM",
    "-1": "STUDIO",
    "0": "BEDSITTER",
    SELF_CONTAINED: "SELF_CONTAINED"
}
export const LeaseTypes = {
    ALL: "ALL",
    RENT: "RENT",
    BUY: "BUY",
    "SHORT-TERM_RENT": "SHORT-TERM_RENT",
}
export const ExtraChargeTypes = {
    RENT: "RENT",
    CLEANING: "CLEANING",
    SECURITY: "SECURITY",
    WATER: "WATER",
    ELECTRICITY: "ELECTRICITY",
    SERVICE_CHARGE: "SERVICE_CHARGE"
}
export const PaymentTypes = {
    DEPOSIT: "DEPOSIT",
    PAYMENT: "PAYMENT",
    VARIABLE: "VARIABLE"
}
export const PropertySaleType = {
    RENT: "RENT",
    SELL: "SELL",
    BOTH: "BOTH"
}

export const PaymentModes = {
    CASH: "CASH",
    MPESA: "MPESA",
    BANK: "BANK"
}
export const PaymentStatus = {
    OVERPAID: "OVERPAID",
    FULLY_PAID: "FULLY_PAID",
    UNPAID: "UNPAID",
    DEPOSIT_PAID: "DEPOSIT_PAID",
    DEPOSIT_RETURN_PENDING: "DEPOSIT_RETURN_PENDING",
    INVALID: "INVALID",
    PARTIALLY_PAID: "PARTIALLY_PAID"
}
export const arrayDateToDate = (arrayDate: Date[]) => {
    return new Date(arrayDate?.map(time => time.toString().length <= 1 ? "0" + time : time.toString()).reduce((result, item) => {
        return result += "-" + item
    }))
}

export const houseFeatures = {
    "water": waterIcon,
    "wifi": wifiIcon,
    "electricity": electricityIcon,
    "balcony": balconyIcon,
    "waste": wasteIcon,
    "transportation": transportationIcon,
    "fireDetector": fireDetectorIcon,
    "cableTV": cableTVIcon
}
Number.prototype.formatCurrency = function (n = 0, x = 3, s = ',', c = '.') {
    const re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};
String.prototype.toCamelCase = function (removeSpaces = false) {
    return this.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}
String.prototype.ellipsizeMiddle = function (n = 3) {
    return this.substr(0, n) + (this.length > n ? '...' : '') + (this.length > n * 2 ? this.substr(this.length - n, n) : '');
};
Array.prototype.count = function (filterMethod) {
    return this.reduce((count, item) => filterMethod(item) ? count + 1 : count, 0);
}
export const generateColor = function () {
    return '#' + Math.random().toString(16).substr(-6);
}

export const ownerUrl = process.env.REACT_APP_OWNER_ENDPOINT + "/";

export function handleAxiosResponse(response: any) {
    return response.text().then((text: any) => {
        let data = {};
        try {
            data = (text && JSON.parse(text)) ?? {}
        } catch (e) {
            console.log()
        }

        return new Promise((resolve, reject) => {
            if (!response.ok) {
                if ([401, 403].indexOf(response.status) !== -1) {
                    // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                    localStorage.removeItem('token')
                }
                reject(data)
            }
            resolve(data)
        });
    });
}
