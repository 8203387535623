import {StyledLogo} from "./style";
import LogoImg from "@doar/shared/images/logo/logo.svg";
import {useEffect, useState} from "react";
import {presets} from 'react-text-transition';
import {StyledTextTransition} from "../landing/style";

const Logo = () => {
    const [index, setIndex] = useState(0);
    const TEXTS = ['Rent', 'Buy', 'Sell'];

    useEffect(() => {
        const intervalId = setInterval(
            () => setIndex((index) => index + 1),
            3000, // every 3 seconds
        );
        return () => clearTimeout(intervalId);
    }, []);
    return (
        <StyledLogo path="/">
            <img className="img-fluid" src={LogoImg} alt=""/>
            <div>Keja<span>
                <StyledTextTransition
                    style={{
                        fontWeight:400
                    }}
                    springConfig={presets.wobbly}>{TEXTS[index % TEXTS.length]}</StyledTextTransition></span></div>
        </StyledLogo>
    );
};

export default Logo;
