import React, {Fragment, Suspense, useEffect, useRef, useState} from 'react';
import {Splide, SplideSlide} from '@splidejs/react-splide';
import {Grid} from '@splidejs/splide-extension-grid';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import Lottie from "react-lottie-player";
import likeAnimation from "@doar/shared/lottie/like.json";
import vrAnimation from "@doar/shared/lottie/vr_cube.json";
import bedIcon from "@iconify/icons-uil/bed-double";
import {Icon} from "@iconify/react";
import ContentShimmer from 'react-content-shimmer'
import {toast} from "react-toastify";
import bathIcon from "@iconify/icons-uil/bath";
import {
    useGetHouseLazyQuery,
    useLikeHouseMutation,
    useRequestViewHouseRentalMutation,
    useGetOwnerContactsSubscribedLazyQuery,
    useGetApartmentLazyQuery,
    useGetSubscriptionTypesLazyQuery,
    useGetCouponsLazyQuery,
    useSubscribeToPackageMutation,
    SubscriptionStatus,
    useNewMessageSubscription,
    usePaymentReceivedSubscriptionSubscription,
    useGetCurrentSubscriptionLazyQuery,
    PaymentStatus,
    HouseStatus
} from "../../graphql/generated/tenant-schema";
import houseStub from "@doar/shared/images/house_stub.png";
import TenantPropertyOverview from "./tenant-property-overview";
import chatIcon from "@iconify/icons-uil/comments-alt";
import phoneIcon from "@iconify/icons-uil/phone-alt";
import emailIcon from "@iconify/icons-uil/fast-mail-alt";
import eyeIcon from "@iconify/icons-uil/eye";
import PropertyItem from "./property-item";
import {GroupBy} from "@ysfe/array-groupby";
import {X} from "react-feather";
import {Loading} from "../loading/loading";
import subscribedAnimation from "@doar/shared/lottie/subscribed.json";
import {
    authenticationService, dateTimeFormat, handleErrorResponse, houseFeatures, PropertyCarouselType, subscriptionClient
} from "../settings/data";
import {ToastWithTitle} from "../settings";
import {
    Button,
    CardBody,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    Row,
    Anchor,
    ButtonGroup,
    Badge,
    Nav,
    NavLink
} from "@doar/components";
import {Overlay, Popover} from "react-bootstrap";
import {LoginForm} from "./loginform";
import {StyledModalCarousel, StyledPropertyListingDetails} from "../app-header/style";
import SigninForm from "../signin-form";
import {useAppSelector} from "../../redux/hooks";
import {StyledBody, StyledHeader, StyledTitle} from "../templates/apps/mail/compose-mail/style";


const TenantPropertyListingDetails = (props) => {
    const loaderReference = useRef();
    const [selectedModalImageIndex, setSelectedModalImageIndex] = useState(undefined);
    const [isCouponView, setCouponView] = useState(false);
    const [coupons, setCoupons] = useState(null);
    const [isHouseProperty, setIsHouseProperty] = useState(false);
    const [propertyDetails, setPropertyDetails] = useState(null);
    const [currentUser, setCurrentUser] = useState(null);
    const [ownerContacts, setOwnerContacts] = useState({mobileNumber: null, email: null, ownerNames: null});
    const [isOwnerContactsLoading, setIsOwnerContactLoading] = useState(false);
    const [showPaymentPopover, setShowPaymentPopover] = useState(false);
    const [clickedPaymentButtonRef, setClickedPaymentButtonRef] = useState(null);
    const [subscriptionTypes, setSubscriptionTypes] = useState(null);
    const [selectedSubscriptionType, setSelectedSubscriptionType] = useState(null);
    const [subscribeLoading, setSubscribeLoading] = useState(false);
    const [subscription, setSubscription] = useState(null);
    const {theme} = useAppSelector((state) => state.theme);

    const mainRef = useRef();
    const thumbsRef = useRef();
    const propertyOverviewRef = useRef();
    const mobileNumberButtonRef = useRef();
    const emailButtonRef = useRef();
    const [selectedCarouselType, setSelectedCarouselType] = useState(props?.carouselType ?? props?.isHouse ? PropertyCarouselType.BIG : PropertyCarouselType.MEDIUM)
    const property = propertyDetails ?? props?.selectedProperty ?? {};
    const [isLiked, setIsLiked] = useState(property?.myInteractions?.liked);

    const [getHouseDetails] = useGetHouseLazyQuery({
        variables: {
            id: props?.selectedPropertyId
        }, onCompleted: (result) => {
            setPropertyDetails(prevState => result?.house)
        }, onError: (error) => {
            handleErrorResponse(error)

            toast.error(<ToastWithTitle title={"We couldn't get the house details."}
                                        message={error.message ?? "Kindly retry."}/>, {
                theme: theme == "dark" ? "dark" : "light"
            });
        }
    })

    const [getOwnerContacts] = useGetOwnerContactsSubscribedLazyQuery({
        variables: {
            houseId: props?.selectedPropertyId
        }, onCompleted: (result) => {
            setIsOwnerContactLoading(false)
            setSubscription(result.getOwnerContactsSubscribed.currentSubscription)
            if (result.getOwnerContactsSubscribed.currentSubscription != null) {
                setOwnerContacts({
                    email: result.getOwnerContactsSubscribed.email,
                    mobileNumber: result.getOwnerContactsSubscribed.mobileNumber,
                    ownerNames: result.getOwnerContactsSubscribed.ownerNames,
                })
            } else {
                getSubscriptionTypes()
                getCoupons()
                setShowPaymentPopover(true);
            }
        }, onError: (error) => {
            // handleErrorResponse(error)
            // setCurrentUser(null)
            getSubscriptionTypes()
            getCoupons()
            setShowPaymentPopover(true);
            setIsOwnerContactLoading(false)
            toast.error(<ToastWithTitle title={"We couldn't get the contact details."}
                                        message={error.message ?? "Kindly retry."}/>, {
                theme: theme == "dark" ? "dark" : "light"
            });
        }, fetchPolicy: "network-only"
    })


    const loadOwnerContacts = () => {
        setIsOwnerContactLoading(true);
        getOwnerContacts();
    }


    const [requestReserveHouse] = useRequestViewHouseRentalMutation({
        variables: {
            houseId: property?.id ?? props?.selectedPropertyId, message: "Hi, I would like to view your house"
        }, onCompleted: () => {
            toast.success(<ToastWithTitle title={"Your request has been received."}
                                          message={"Either the owner or a verified agent will reach out"}/>);
        }, onError: (error) => {
            handleErrorResponse(error)

            switch (error?.message) {
                case 'RENTAL_REQUEST_EXISTING':
                    toast.warning(<ToastWithTitle title={"Already requested."}
                                                  message={"An existing request to the same house is pending with the owner. We have notified them to recheck."}/>);
                    break;
                default:
                    toast.error(<ToastWithTitle title={"We couldn't send your request."}
                                                message={error?.message ?? "Kindly retry and reach out to us in case it persists"}/>);
                    break;
            }

        }
    })
    const [getSubscriptionTypes] = useGetSubscriptionTypesLazyQuery({
        onCompleted: (results) => {
            setSubscriptionTypes(results.subscriptionTypes)
        }, onError: (error) => {
            handleErrorResponse(error)

        }, fetchPolicy: 'cache-first'
    })
    const [getCurrentSubscriptions] = useGetCurrentSubscriptionLazyQuery({
        onCompleted: (results) => {
            setSubscription(results.currentSubscription)
        }, onError: (error) => {
            handleErrorResponse(error)

        }, fetchPolicy: 'network-only'
    })
    const [getCoupons] = useGetCouponsLazyQuery({
        onCompleted: (results) => {
            setCoupons(results)
        }, onError: (error) => {
            handleErrorResponse(error)
            setCoupons(null)
        }, fetchPolicy: "network-only"
    })
    const [subscribeToPackage] = useSubscribeToPackageMutation({
        onCompleted: (results) => {
            setSubscription(results?.subscribe)
            setSubscribeLoading(false)
        }, onError: (error) => {
            setSubscribeLoading(false)
        }, fetchPolicy: "network-only"
    })
    const [likeHouse] = useLikeHouseMutation({
        variables: {
            houseId: property?.id ?? props?.selectedPropertyId,
        }, onCompleted: () => {
            setIsLiked(!isLiked);
        }, onError: (error) => {
            handleErrorResponse(error)
            setIsLiked(isLiked);
        }
    })

    const [getApartment] = useGetApartmentLazyQuery({
        onCompleted: (results) => {
            const groupedHouses = GroupBy(results.apartment.houses?.nodes, (a, b) => a.apartmentId == b.apartmentId && a.bedroom == b.bedroom && a.bathroom == b.bathroom && a.price == b.price && a.status == HouseStatus.Vacant)
            setPropertyDetails(prevState => {
                return {
                    ...results.apartment, houses: {
                        totalCount: results.apartment.houses.totalCount, nodes: groupedHouses
                    }
                }
            });
        }, onError: (error) => {
            handleErrorResponse(error)
            toast.error(<ToastWithTitle title={"We couldn't get the apartment details."}
                                        message={error.message ?? "Kindly retry."}/>);
        }
    })

    const getApartmentDetails = () => {
        getApartment({
            variables: {
                id: props?.selectedPropertyId, housesPaging: {
                    offset: 0, limit: 10
                }
            }
        })
    }

    useEffect(() => {
        // firebase_app.auth().onAuthStateChanged(setCurrentUser);
        authenticationService.currentUser.subscribe(x => {
            setCurrentUser(x)
        });
        // subscriptionServices.currentSubscription.asObservable().subscribe(currentSubscription => {
        //     setSubscription(currentSubscription);
        //     return null;
        // });
        if (props?.isHouse) {
            if (props?.selectedPropertyId) getHouseDetails()
        } else getApartmentDetails()
    }, [])

    useEffect(() => {
        if (mainRef.current && thumbsRef.current && thumbsRef.current.splide) {
            mainRef.current.sync(thumbsRef.current.splide);
        }
    }, [propertyDetails])
    useEffect(() => {
        setIsLiked(property?.myInteractions?.liked)
    }, [property])

    usePaymentReceivedSubscriptionSubscription({
        onSubscriptionData(data) {
            const paymentReceived = data.subscriptionData.data.paymentReceived;
            if (paymentReceived.status == PaymentStatus.Success) {
                getCurrentSubscriptions()
            } else {
                toast.warning(<ToastWithTitle title={`Payment ${paymentReceived.status}`}
                                              message={"Payment may have failed or cancelled. Kindly retry payment to continue."}/>);

            }
        }, client: subscriptionClient, shouldResubscribe: true, fetchPolicy: 'network-only'
    })
    return (<StyledPropertyListingDetails>
            <Col sm={12} lg={props?.fillWidth ? 12 : 9} xxl={props?.fillWidth ? 12 : 8} paddingLeft={0}
                 paddingRight={0}>
                {<Col sm={12} className="property-listing-details-wrapper" >
                    <Col
                        sm={selectedCarouselType == PropertyCarouselType.BIG || selectedCarouselType == PropertyCarouselType.MEDIUM ? 12 : null}
                        md={selectedCarouselType == PropertyCarouselType.BIG ? 8 : (selectedCarouselType == PropertyCarouselType.MEDIUM ? 6 : null)}
                        lg={selectedCarouselType == PropertyCarouselType.BIG ? 8 : (selectedCarouselType == PropertyCarouselType.MEDIUM ? 6 : null)}
                        style={{position: "inherit"}}>
                        <div className={'mb-2'} style={{position: "inherit"}}>
                            {Object.keys(property).length <= 0 ?
                                <ContentShimmer size={{height: 560, width: "100%"}} rounded="5%"/> :
                                <Splide className="main_slide" options={{
                                    type: 'loop',
                                    perPage: 1,
                                    perMove: 1,
                                    gap: '1rem',
                                    pagination: false,
                                    height: '35rem',
                                }} ref={mainRef}  onClick={(splide, slide, event) => setSelectedModalImageIndex(splide.index)}>
                                    {property?.media?.addresses?.length > 0 ? property?.media?.addresses.map((slide, slideIndex) => (
                                        <SplideSlide key={`main_slide_${slideIndex}`}>
                                            <img src={(slide)} alt={slideIndex}
                                                 className="w-100 h-100"
                                                 style={{objectFit: "cover", width: "100%", height: "100%"}}
                                                 onError={({currentTarget}) => {
                                                     currentTarget.onerror = null; // prevents looping
                                                     currentTarget.src = houseStub;
                                                 }}
                                            />
                                        </SplideSlide>)) : <SplideSlide key={`main_slide_0`}>
                                        <img src={houseStub} alt='0'
                                             className="w-100 h-100"
                                             style={{objectFit: "cover", width: "100%", height: "100%"}}
                                        />
                                    </SplideSlide>}
                                </Splide>}
                            {Object.keys(property).length > 0 ? <div className="main-slide-overlay">
                                <div
                                    className="main-slide-overlay-top">
                                    <div
                                        className="vr_badge">
                                        <Lottie
                                            loop={true}
                                            play={true}
                                            animationData={vrAnimation}
                                            style={{
                                                width: 50, height: 50, alignSelf: 'center', margin: -14, marginLeft: -7
                                            }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                e.stopPropagation()
                                            }}
                                        />
                                        House Tour
                                    </div>

                                    {props?.propertiesCount && props?.propertiesCount > 1 ?
                                        <Badge color={'secondary'} pill
                                               style={{height: "fit-content"}}>
                                            {props?.propertiesCount} Houses</Badge> : <div></div>}
                                    <Lottie
                                        loop={false}
                                        play={true}
                                        animationData={likeAnimation}
                                        direction={isLiked ? 1 : -1}
                                        segments={isLiked ? [0, 20] : [20, 0]}
                                        style={{
                                            width: 70, height: 70, alignSelf: 'center'
                                        }}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation()
                                            likeHouse();
                                        }}
                                    />
                                </div>
                                <div
                                    className="main-slide-overlay-bottom"
                                    style={{bottom: 0}}>
                                    <h5 className="mb-0"
                                        style={{fontWeight: 900}}>{(property?.apartment?.name ?? property?.name ?? 'Single Unit House')?.toCamelCase()}</h5>
                                    {props?.isHouse ? <div
                                        className="d-flex flex-row justify-content-center align-items-center">
                                        {props?.selectedProperty?.livingroom ? <div
                                            className="m-1 d-flex justify-content-center align-items-center">
                                            <Icon
                                                icon={bedIcon}/><h6
                                            className="pl-1">{`${property?.livingroom} Sitting`}</h6>
                                        </div> : null}
                                        <div
                                            className="m-1 d-flex justify-content-center align-items-center">
                                            <Icon
                                                icon={bedIcon}/><h6
                                            className="pl-1">{`${property?.bedroom} Beds`}</h6>
                                        </div>
                                        <div
                                            className="m-1 d-flex justify-content-center align-items-center">
                                            <Icon
                                                icon={bedIcon}/><h6
                                            className="pl-1">{`${property?.bathroom} Baths`}</h6>
                                        </div>
                                    </div> : <div>
                                        <div className="m-1 d-flex justify-content-center align-items-center">
                                            <h6 className="text white pb-3"
                                                style={{borderBottom: "1px solid #0000001c"}}>{(property?.district ?? property?.apartment?.district) + ', ' + (property?.constituency ?? property?.apartment?.constituency) + ', ' + (property?.county ?? property?.apartment?.county)}</h6>
                                        </div>
                                    </div>}
                                </div>
                                <div className="ribbon ribbon-success left"> For Sale/Rent</div>
                            </div> : null}
                        </div>
                        {Object.keys(property).length <= 0 ? <ContentShimmer size={{height: 80, width: "100%"}}
                                                                             style={{
                                                                                 marginTop: 10, borderRadius: 10
                                                                             }}/> : <Splide
                            className="thumb_slide"
                            options={{
                                type: 'slide',
                                rewind: true,
                                gap: '1rem',
                                pagination: false,
                                fixedWidth: 110,
                                fixedHeight: 70,
                                cover: true,
                                focus: 'center',
                                isNavigation: true,
                            }} ref={thumbsRef}>
                            {property?.media?.thumbnails?.length > 0 ? property?.media?.thumbnails?.map((slide, slideIndex) => (
                                <SplideSlide key={`thumb_slide_${slideIndex}`}>
                                    <img src={(slide)} alt={slideIndex}
                                         onError={({currentTarget}) => {
                                             currentTarget.onerror = null; // prevents looping
                                             currentTarget.src = houseStub;
                                         }}
                                    />
                                </SplideSlide>)) : <SplideSlide key={`thumb_slide_0`}>
                                <img src={houseStub}
                                     alt='0'/>
                            </SplideSlide>}
                        </Splide>}
                    </Col>
                    {props?.isHouse ? <Col
                        sm={selectedCarouselType == PropertyCarouselType.BIG || selectedCarouselType == PropertyCarouselType.MEDIUM ? 12 : null}
                        md={selectedCarouselType == PropertyCarouselType.BIG ? 4 : (selectedCarouselType == PropertyCarouselType.MEDIUM ? 6 : null)}
                        lg={selectedCarouselType == PropertyCarouselType.BIG ? 4 : (selectedCarouselType == PropertyCarouselType.MEDIUM ? 6 : null)}
                        className={'house-details-wrapper'}>
                        <div className={'house-details'}>
                            {Object.keys(property).length <= 0 ? <ContentShimmer size={{height: 30, width: "100%"}}
                                                                                 style={{
                                                                                     marginTop: 10,
                                                                                     borderRadius: 10
                                                                                 }}/> :
                                <h3 className="font-weight-bolder">{`${property?.apartment?.name ?? property?.name ?? 'Single Unit House'}`.toCamelCase()}</h3>}
                            {Object.keys(property).length <= 0 ? <ContentShimmer size={{height: 30, width: "100%"}}
                                                                                 style={{
                                                                                     marginTop: 10,
                                                                                     borderRadius: 10
                                                                                 }}/> : <h6 className="text-muted pb-3"
                                                                                            style={{
                                                                                                borderBottom: "1px solid #0000001c",
                                                                                                paddingBottom: "0.75rem"
                                                                                            }}>{(property?.district ?? property?.apartment?.district) + ', ' + (property?.constituency ?? property?.apartment?.constituency) + ', ' + (property?.county ?? property?.apartment?.county)}</h6>}
                            {Object.keys(property).length <= 0 ? <ContentShimmer size={{height: 30, width: "100%"}}
                                                                                 style={{
                                                                                     marginTop: 10,
                                                                                     borderRadius: 10
                                                                                 }}/> : <Fragment>
                                {Object.keys(property).length <= 0 ? <ContentShimmer size={{height: 30, width: 100}}
                                                                                     style={{
                                                                                         marginTop: 10,
                                                                                         borderRadius: 10
                                                                                     }}/> :
                                    <h6 className="font-weight-bold"
                                        style={{display: "inline"}}>{property?.houseType.toString().replaceAll("_", " ").toCamelCase()}</h6>}
                                <Row className={'house-room-details'}>
                                    {property?.livingroom ? <div>
                                        <Icon
                                            icon={bedIcon} style={{width: 25, height: 25}}/><h6
                                        className="pl-1">{`${property?.livingroom} Living`}</h6>
                                    </div> : null}
                                    <div>
                                        <Icon icon={bedIcon} style={{width: 25, height: 25}}/><h6
                                        className="pl-1">{`${property?.bedroom} Beds`}</h6>
                                    </div>
                                    <div>
                                        <Icon
                                            icon={bathIcon} style={{width: 25, height: 25}}/><h6
                                        className="pl-1">{`${property?.bathroom} Baths`}</h6>
                                    </div>
                                </Row>
                            </Fragment>}
                        </div>
                        <div className="price-details">
                            {Object.keys(property).length <= 0 ? <ContentShimmer size={{height: 30, width: 100}}
                                                                                 style={{
                                                                                     marginTop: 10,
                                                                                     borderRadius: 10
                                                                                 }}/> :
                                <h3 className="font-weight-bolder"
                                    style={{display: "inline"}}>{`${property?.currency?.toLowerCase() || 'KES'}. ${property?.price.formatCurrency()}`}</h3>}
                            {Object.keys(property).length <= 0 ? <ContentShimmer size={{height: 30, width: 50}}
                                                                                 style={{
                                                                                     marginTop: 10,
                                                                                     borderRadius: 10
                                                                                 }}/> : <Badge color={'secondary'}
                                                                                               className="small ml-2">{`${property?.rate}`.toCamelCase()}</Badge>}
                        </div>
                        <div className="property-details-features">
                            {typeof property !== 'undefined' && Object.keys(property).length <= 0 ?
                                <ContentShimmer size={{height: 30, width: 40}}
                                                style={{
                                                    marginTop: 10, borderRadius: 10
                                                }}/> : Object.entries(property.features).map(([featureName, featureValue]) => {
                                    return <div key={featureName}
                                                className={`feature-item font-weight-bold`}
                                                disabled={houseFeatures?.hasOwnProperty(featureName) && featureValue}>
                                        <Icon icon={houseFeatures[featureName]} style={{width: 20, height: 20}}/>
                                        <span className="w-100 font-bold">{featureName?.toCamelCase()}</span>
                                    </div>;
                                })}
                        </div>
                        <div className="flex-grow-1"></div>
                        <div className="property-details-actions">
                            {Object.keys(property).length <= 0 ? <ContentShimmer size={{height: 80, width: "100%"}}
                                                                                 style={{
                                                                                     marginTop: 10,
                                                                                     borderRadius: 10
                                                                                 }}/> : <ButtonGroup className="w-100">
                                {/*<Button color="success" type="button"*/}
                                {/*        onClick={() => requestReserveHouse()}>*/}
                                {/*    <Icon icon={eyeIcon} style={{color: "white"}}*/}
                                {/*          className="details-icon"/>*/}
                                {/*    {"Request Viewing"}</Button>*/}
                                {/*<Button outline color="success" type="button"*/}
                                {/*        onClick={() => requestReserveHouse()}>{"Reserve"}</Button>*/}
                                <Button innerRef={emailButtonRef} loading={`${isOwnerContactsLoading}`}
                                        color="primary" type="button"
                                        onClick={() => {
                                            setClickedPaymentButtonRef(emailButtonRef)
                                            loadOwnerContacts()
                                        }}>
                                    <Icon icon={emailIcon} style={{color: "white"}}
                                          className="details-icon"/>{ownerContacts?.email ?
                                    <Anchor path={`mailto:${ownerContacts?.email}`}></Anchor> : "Email"}
                                </Button>
                                <Button innerRef={mobileNumberButtonRef} onClick={() => {
                                    setClickedPaymentButtonRef((current) => mobileNumberButtonRef)
                                    loadOwnerContacts()
                                }}>
                                    <Icon icon={phoneIcon} style={{color: "white"}}
                                          className="details-icon"/>
                                    {ownerContacts?.mobileNumber ?? "Phone"}</Button>

                                <Button onClick={() => {
                                    props?.openChat(property)
                                }}>
                                    <Icon icon={chatIcon} style={{color: "white"}}
                                          className="details-icon"/>{"Chat"}</Button>
                                <Overlay
                                    placement="top"
                                    trigger="click"
                                    transition={true}
                                    show={showPaymentPopover}
                                    target={clickedPaymentButtonRef?.current}
                                >
                                    <Popover
                                        className={'popover-purchase'}
                                        id="example"
                                        title="Subscribe to remove Ads" content={"true"}
                                        style={{minWidth: '200px', maxWidth: '420px'}}>

                                        {currentUser ? <div className={"subscription-wrapper"}>
                                            <div className={'subscription-header'}>
                                                <h6 className={'font-weight-bold'}>KejaRent
                                                    Without limits!</h6><X
                                                onClick={() => setShowPaymentPopover(false)}/></div>
                                            {!subscription ? <h5>{"It's"} <span
                                                className={'font-weight-bold'}>FREE</span> with
                                                Ads,<br/>{"It's even "}<span
                                                    className={'font-weight-bold'}>Cheaper</span> without
                                            </h5> : null}
                                            {subscription ? (subscription.status === SubscriptionStatus.FullyPaid ?
                                                    <div className={'subscribed-wrapper'}>
                                                        <h5 className={'font-weight-bold'}>{"You're Premium!"}</h5>

                                                        <Lottie
                                                            loop={false}
                                                            play={true}
                                                            animationData={subscribedAnimation}
                                                            direction={1}
                                                            style={{
                                                                width: 130, height: 130, alignSelf: 'center'
                                                            }}
                                                        />

                                                    </div> : <div
                                                        className={'payment-pending-wrapper'}
                                                    >
                                                        <h5 className={'font-weight-bold'}>Payment
                                                            Pending</h5>
                                                        <Lottie
                                                            loop={false}
                                                            play={true}
                                                            animationData={subscribedAnimation}
                                                            direction={1}
                                                            style={{
                                                                width: 130,
                                                                height: 130,
                                                                alignSelf: 'center',
                                                                filter: "grayscale(100%)",
                                                                opacity: 0.2
                                                            }}
                                                        />
                                                        <span className={'pt-2'}>You will receive a payment notification on your phone number</span>
                                                        <span>Kindly complete payment to continue</span>
                                                        <span className={'p-3 font-weight-bold'}
                                                              style={{
                                                                  color: "var(--theme-deafult)", cursor: "pointer"
                                                              }}
                                                              onClick={() => {
                                                                  setSubscribeLoading(true)

                                                                  subscribeToPackage({
                                                                      variables: {
                                                                          subscriptionTypeId: selectedSubscriptionType.id,
                                                                          paymentGatewayId: '2'
                                                                      }
                                                                  }).then((subscribeResult) => {
                                                                      toast.success(<ToastWithTitle
                                                                          title={`Payment request sent`}
                                                                          message={"You will receive a payment notification on your phone number."}/>);
                                                                  })
                                                              }}
                                                        >I have not received the payment notification</span>
                                                    </div>

                                            ) : (subscribeLoading ? <Loading
                                                ref={loaderReference}
                                                idleTitle={"Nothing to show"}
                                                idleMessage={"Select your subscription to continue"}
                                                successTitle={"Hooray!"}
                                                successMessage={"Subscription successful "}
                                                failTitle={"We're very sorry"}
                                                failMessage={"Subscription wasn't successful, kindly retry"}
                                                loadingTitle={"Just a moment ..."}
                                                state={subscribeLoading ? 'LOADING' : "IDLE"}
                                                loadingMessage={"We're looking for the door keys"}
                                                // canContinue
                                                // canRetry
                                                onContinueClicked={() => setSubscribeLoading(false)}
                                                onRetryClicked={() => {
                                                    setSubscribeLoading(true)

                                                }}
                                                lottieWidth={130}
                                                lottieHeight={130}
                                                height={150}
                                                onCancelClicked={() => setSubscribeLoading(false)}
                                            /> : (isCouponView || (coupons && coupons.welcomeCoupon) ? (!coupons ?
                                                <div className={'p-2'}>
                                                    <ContentShimmer
                                                        size={{
                                                            height: 100, width: "100%"
                                                        }}
                                                        rounded="5%"/>
                                                </div> : <Splide className="main_slide"
                                                                 options={{
                                                                     type: 'loop',
                                                                     perPage: 1,
                                                                     perMove: 1,
                                                                     gap: '1rem',
                                                                     pagination: false,
                                                                 }} ref={mainRef}>
                                                    {coupons?.length > 0 || coupons?.welcomeCoupon ? ([coupons.welcomeCoupon, ...coupons?.coupons]).map((coupon, couponIndex) => (
                                                        <SplideSlide
                                                            key={`main_slide_${couponIndex}`}>
                                                            <div
                                                                className={'d-flex align-items-center justify-content-center'}>
                                                                <section
                                                                    className="coupon d-flex flex-row align-items-center m-3 text-white">
                                                                    <div
                                                                        className="coupon-left p-3 text-center">
                                                                        <h3 className={'font-weight-bold'}>{coupon.discountPercent ? `${coupon.discountPercent}%` : `Ksh. ${coupon.discountFlat}`}</h3>
                                                                        <h3 className={'font-weight-bold'}>OFF</h3>
                                                                        <h6 className={'font-weight-bold'}>{`${coupon.subscriptionType.subscriptionType.replaceAll("_", " ")} subscription`}</h6>
                                                                    </div>
                                                                    <div
                                                                        className="coupon-right d-flex flex-column justify-content-center align-items-center p-3 text-center">
                                                                        <h6 className="coupon-info font-weight-bold">{coupon.title}</h6>
                                                                        <span
                                                                            className="coupon-info">{coupon.description}</span>
                                                                        <small
                                                                            className="coupon-time p-2 font-weight-bold">Expiry: {`${dateTimeFormat({
                                                                            weekday: "short",
                                                                            month: 'short',
                                                                            day: '2-digit',
                                                                            year: 'numeric',
                                                                        }).format(coupon.expiry)}`}</small>

                                                                        <Button
                                                                            onClick={() => {
                                                                                setSubscribeLoading(true)
                                                                                subscribeToPackage({
                                                                                    variables: {
                                                                                        couponCode: coupon.code,
                                                                                        subscriptionTypeId: coupon.subscriptionType.id,
                                                                                        paymentGatewayId: '2'
                                                                                    }
                                                                                })
                                                                            }}
                                                                            className={'border-0 btn-light-primary b-r-0 bg-white'}>Use
                                                                            Coupon</Button>
                                                                    </div>
                                                                </section>
                                                            </div>
                                                        </SplideSlide>)) : <SplideSlide
                                                        key={`main_slide_0`}>
                                                        <span>unfortunately no coupon is available at this time</span>
                                                    </SplideSlide>}
                                                </Splide>) : <Fragment>
                                                <div className={'subscriptions'} p={3}>
                                                    {subscriptionTypes?.map((subscriptionType, index) => <Col sm={4}
                                                                                                              p={2}
                                                                                                              key={index}
                                                                                                              onClick={() => setSelectedSubscriptionType(subscriptionType)}>
                                                        <div
                                                            style={{
                                                                transform: selectedSubscriptionType?.id == subscriptionType?.id ? 'scaleX(1.15) scaleY(1.15)' : '',
                                                                transition: "0.5s",
                                                                border: `1px solid ${subscriptionType.themeColor}`,
                                                                cursor: "pointer"
                                                            }}>
                                                            <div
                                                                className={'text-white'}
                                                                style={{
                                                                    backgroundColor: subscriptionType.themeColor,
                                                                    padding: "0.25rem",
                                                                    marginBottom: "0.25rem"
                                                                }}>
                                                                <h6 className={'font-weight-bold text-white'}>{subscriptionType.subscriptionType}</h6>
                                                            </div>
                                                            <h4 className={'font-weight-bolder'}>Ksh. {subscriptionType.subscriptionAmount?.formatCurrency()}</h4>
                                                            <h5>{subscriptionType.subscriptionTimeSeconds / (60 * 60 * 24)} days</h5>
                                                            <h6 className={'font-weight-normal text-muted small'}>{subscriptionType.subscriptionDescription}</h6>


                                                        </div>
                                                    </Col>)}
                                                </div>
                                                <Col padding={2}
                                                     className={'continue-button'}>
                                                    <Button
                                                        onClick={() => {
                                                            if (selectedSubscriptionType != null) {
                                                                setSubscribeLoading(true)
                                                                subscribeToPackage({
                                                                    variables: {
                                                                        subscriptionTypeId: selectedSubscriptionType.id,
                                                                        paymentGatewayId: '2'
                                                                    }
                                                                })
                                                            } else {
                                                                toast.warning(<ToastWithTitle
                                                                    title={"Select Package"}
                                                                    message={"Kindly select package to use"}/>);
                                                            }
                                                        }}
                                                        className={'border-0 btn-light-primary b-r-0 bg-white m-2'}>Continue</Button>
                                                </Col>
                                            </Fragment>))}


                                        </div> : <Fragment>
                                            <div className="login-card" style={{
                                                minHeight: 'unset'
                                            }}>
                                                <SigninForm hasPadding={false} hasMargin={false}
                                                            onClose={() => setShowPaymentPopover(false)}/>
                                            </div>
                                        </Fragment>}
                                    </Popover>
                                </Overlay>

                            </ButtonGroup>}
                        </div>
                        <div className="property-note">
                            <h6>Planning to move?</h6>
                            <p className={"mb-0"}>use <strong className="font-black"><Anchor
                                href={"https://www.pickapp.ke"} target={"_blank"}
                                rel="noopener noreferrer">PickApp</Anchor></strong> {'the "Uber" for moving.'}
                            </p>
                            <p><strong className="font-black">Convenient, </strong><strong
                                className="font-black">Upfront costs, </strong><strong
                                className="font-black">Instant </strong></p>
                        </div>
                    </Col> : <Col
                        lg={selectedCarouselType == PropertyCarouselType.BIG ? 4 : (selectedCarouselType == PropertyCarouselType.MEDIUM ? 6 : null)}
                        md={selectedCarouselType == PropertyCarouselType.BIG ? 4 : (selectedCarouselType == PropertyCarouselType.MEDIUM ? 6 : null)}
                        sm={12}
                        p={0}
                        pr={3}
                        pb={3}
                        className={`text-left d-flex flex-column`}>
                        <h5 className={'font-weight-bold p-2 pl-2 d-flex justify-content-between'}>{property?.name}<span
                            className={'text-muted small font-weight-normal'}>{property?.houses?.totalCount ?? 0} Houses</span>
                        </h5>
                        {<div className={`w-100`}>
                            {property.houses?.nodes?.length > 0 ? <Splide className="apartment_houses_slide"
                                                                          extensions={{Grid}}
                                                                          options={{
                                                                              type: 'loop', // perPage: 1,
                                                                              perMove: 1,
                                                                              gap: '0.5rem',
                                                                              pagination: false,
                                                                              autoWidth: true,
                                                                              height: "fit-content",
                                                                              grid: {
                                                                                  cols: 2, rows: 2, gap: {
                                                                                      row: '1rem', col: '1rem',
                                                                                  },
                                                                              }
                                                                          }}>
                                {property.houses.nodes?.map((houseGroup, index) => (<SplideSlide
                                    key={`apartment_main_slide_${index}`}
                                    onClick={(e) => props?.setSelectedProperty(houseGroup)}
                                >
                                    <Suspense key={`suspense_${index}`} fallback={<div
                                        key={`suspense_fallback_${index}`}>...</div>}>
                                        <PropertyItem key={`property_item_${index}`}
                                                      openChat={props?.openChat}
                                                      data={{
                                                          count: houseGroup.length, house: houseGroup[0]
                                                      }}/>
                                    </Suspense>
                                </SplideSlide>))}
                            </Splide> : null}
                        </div>}

                    </Col>}
                </Col>}
                <div className="pt-2 property-single col-12 p-0">
                    <TenantPropertyOverview ref={propertyOverviewRef} propertyDetails={property}/>
                </div>
            </Col>
            {<StyledModalCarousel
                className={'modal-splide-carousel'}
                show={selectedModalImageIndex!== undefined} onClose={() => {
                setSelectedModalImageIndex(undefined)
            }} size="xl" centered={true}>
                <div className={'close-button-nav'} onClick={() => {
                    setSelectedModalImageIndex(undefined)
                }}>
                    <X/>
                </div>
                {Object.keys(property).length <= 0 ?
                    <ContentShimmer size={{height: 560, width: "100%"}} rounded="5%"/> :
                    <Splide className="slide_modal" options={{
                        start: selectedModalImageIndex,
                        type: 'loop', perPage: 1, perMove: 1, gap: '1rem', pagination: false, height: '50rem',
                    }}>
                        {property?.media?.addresses?.length > 0 ? property?.media?.addresses.map((slide, slideIndex) => (
                            <SplideSlide key={`main_slide_modal_${slideIndex}`}>
                                <img src={(slide)} alt={slideIndex}
                                     className="w-100 h-100"
                                     style={{objectFit: "contain", width: "100%", height: "100%"}}
                                     onError={({currentTarget}) => {
                                         currentTarget.onerror = null; // prevents looping
                                         currentTarget.src = houseStub;
                                     }}
                                />
                            </SplideSlide>)) : <SplideSlide key={`main_slide_0`}>
                            <img src={houseStub} alt='0'
                                 className="w-100 h-100"
                                 style={{objectFit: "contain", width: "100%", height: "100%"}}
                            />
                        </SplideSlide>}
                    </Splide>}
            </StyledModalCarousel>}
        </StyledPropertyListingDetails>

    );
}
export default TenantPropertyListingDetails;
