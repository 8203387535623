import { SpaceProps } from "@doar/shared/styled";
import { StyledContainer } from "./style";

interface IProps extends SpaceProps {
    children: React.ReactNode;
    className?: string;
    fullWidth?:boolean;
    fullHeight?:boolean;
}

const Container = ({ className,fullWidth,fullHeight, ...props }: IProps) => {
    return <StyledContainer className={className}
                            $fullHeight={fullHeight}
                            $fullWidth={fullWidth} {...props} />;
};

export default Container;
