import classnames from "clsx";
import { SpaceProps, TypographyProps, LayoutProps } from "@doar/shared/styled";
import { StyledLabel } from "./style";

interface IProps extends SpaceProps, TypographyProps, LayoutProps {
    children?: React.ReactNode;
    htmlFor: string;
    className?: string;
    onClick?:any;
}

const Label = ({ children, htmlFor, className, onClick, ...rest }: IProps) => {
    return (
        <StyledLabel
            htmlFor={htmlFor}
            onClick={onClick}
            className={classnames(className, "label")}
            {...rest}
        >
            {children}
        </StyledLabel>
    );
};

export default Label;
