import React, {Fragment, useState, useEffect, useRef, ChangeEvent} from 'react';
import {
    Minimize,
    X
} from 'react-feather';
import {Save
} from '../constant'
import {getCookie, setCookie} from "react-use-cookie";
import {useUpdateTenantMutation} from "../../graphql/generated/tenant-schema";
import * as yup from 'yup';
import {Loading} from "../loading/loading";
import {findDOMNode} from "react-dom";
import {Form, Overlay, Popover} from "react-bootstrap";
import {
    authenticationService,
    handleErrorResponse, houseFeatures,
    notificationService,
} from "../settings/data";
import ProfilePicture from '@doar/shared/images/User.jpg'
import {
    Anchor,
    Avatar, AvatarInitial,
    Button,
    CardBody,
    Col,
    Container,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    Row
} from "@doar/components";
import Feedback from "react-bootstrap/Feedback";
import SigninForm from "../signin-form";
import {StyledNavbarElement} from "../../layouts/header/style";
import MessageDropdown from "../header/message-dropdown";
import NotificationDropdown from "../header/notification-dropdown";
import ProfileDropdown from "../header/profile-dropdown";
import {StyledUserImage} from "./style";
import {Icon} from "@iconify/react";
import waterIcon from "@iconify/icons-uil/glass";


export type RightBarType = {
    isListingHeader:boolean
}

const Rightbar = ({isListingHeader}:RightBarType) => {


    // const history = useHistory();
    // const {logout} = useAuth0()
    const loaderReference = useRef();
    const [moonlight, setMoonlight] = useState(false)
    const [chatNotifications, setChatNotifications] = useState({})
    const [generalNotifications, setGeneralNotifications] = useState([])
    const [isUpdateAccountOpen, setIsUpdateAccountOpen] = useState(false)
    const [currentUser, setCurrentUser] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [updateTenantData, setUpdateTenantData] = useState({});
    const [updateTenantProfile, setUpdateTenantProfile] = useState();
    const [loading, setLoading] = useState(false);
    const loginButtonRef = useRef();
    const [showLoginPopover, setShowLoginPopover] = useState(false);

    const handleChange = (e: ChangeEvent<any>) => {
        setUpdateTenantData(current => {
            return {
                ...current,
                [e?.target?.name]: e?.target?.value
            }
        })
    }

    const [formErrors, setFormErrors] = useState({});
    const schema = yup.object().shape({
        email: yup.string().email(),
        lastName: yup.string().required().label("Your Last Name"),
        firstName: yup.string().required().label("Your First Name"),
        media: yup
            .mixed()
            // .required("You need to provide a profile Image")
            // .test("fileSize", "The file is too large", (value) => {
            //     console.log(value)
            //     return value && !(value.size > defaultsConfig.maxImageSize);
            // })
            // .test("type", "Only the following formats are accepted: .jpeg, .jpg, .bmp, .webp", (value) => {
            //     return value && (['image/jpeg', 'image/bmp', 'image/png', 'image/jpg', 'image/webp'].includes(value.type));
            // })
            .nullable()
    });


    useEffect(() => {
        authenticationService.currentUser.subscribe(x => {
            setCurrentUser(x)
            setUpdateTenantData({
                ...updateTenantData,
                firstName: updateTenantData?.firstName ?? x?.firstName,
                lastName: updateTenantData?.lastName ?? x?.lastName,
                email: updateTenantData?.email ?? x?.email,
            })
        });
        notificationService.chatNotification.asObservable().subscribe(receivedData => {
            const chats = {
                ...(JSON.parse(getCookie("chats", "{}")))
            };
            if (receivedData?.houseId) {
                chats[receivedData?.houseId] = receivedData.message
                setCookie("chats", JSON.stringify(chats));
            }
            setChatNotifications(chats);
            return null;
        });
        notificationService.generalNotifications.asObservable().subscribe((receivedData) => {
            setGeneralNotifications(receivedData);
            return null;
        });
        if (localStorage.getItem("layout_version") === "dark-only") {
            setMoonlight(true)
        }
    }, []);



    const [updateTenant] = useUpdateTenantMutation({
        variables: {
            ...updateTenantData,
            media: updateTenantProfile
        },
        onCompleted: (results) => {
            setCookie(process.env.REACT_APP_TENANT_COOKIE ?? 'tenant', JSON.stringify({
                ...JSON.parse(getCookie(process.env?.REACT_APP_TENANT_COOKIE ?? "tenant", "{}")),
                ...results.updateSelf
            }));
            loaderReference.current.isSuccess()
            // setLoading(false)
        },
         onError: (error) => {
            handleErrorResponse(error)
            loaderReference.current.isFailed()
            // setLoading(false)
        }
    })

    //full screen function
    function goFull() {
        if ((document.fullScreenElement && document.fullScreenElement !== null) ||
            (!document.mozFullScreen && !document.webkitIsFullScreen)) {
            if (document.documentElement.requestFullScreen) {
                document.documentElement.requestFullScreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullScreen) {
                document.documentElement.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

    return (
        <Fragment>
            <div className={`nav-right pull-right right-header p-0 h-100`}>
                <ul className={`nav-menus h-100  ${isListingHeader ? 'm-0' : ''}`}>
                    <StyledNavbarElement $hideOnMobile ml={["8px", "15px"]}>
                        <MessageDropdown chatNotifications={chatNotifications} />
                    </StyledNavbarElement>
                    <StyledNavbarElement $hideOnMobile ml={["8px", "15px"]}>
                        <NotificationDropdown generalNotifications={generalNotifications} />
                    </StyledNavbarElement>
                    <StyledNavbarElement $hideOnMobile ml={["8px", "15px"]}>
                        <Anchor className="text-dark" path="#javascript" onClick={goFull}><Minimize/></Anchor>
                    </StyledNavbarElement>
                    <StyledNavbarElement ml={["15px", "20px", "30px"]}>
                        <ProfileDropdown user={currentUser} onEdit={() => setIsUpdateAccountOpen(true)} showLogin={() => setShowLoginPopover(true)} loginButtonRef={loginButtonRef} />
                    </StyledNavbarElement>
                </ul>
            </div>
            {/**/}
            <Modal
                show={(window.location.pathname != "/" && currentUser && (!currentUser?.firstName || !currentUser?.lastName) || isUpdateAccountOpen)}
                className={"h-100 d-flex align-items-center"}>
                <ModalBody className="m-0">
                    <Container fluid={true}>

                        <Row className="d-flex justify-content-center align-items-center mb-2">
                            <div className="col-12 w-100 d-flex flex-row justify-content-between">
                                <h5>{"Update your profile"}</h5>
                                <X onClick={() => setIsUpdateAccountOpen(false)}/>
                            </div>
                            <Col sm="12">
                                {!loading ?
                                    <CardBody>
                                        <Row justifyContent={'center'} alignItems={'center'} marginBottom={2}>
                                            {/*<Lottie*/}
                                            {/*    loop={true}*/}
                                            {/*    play={true}*/}
                                            {/*    animationData={profileAnimation}*/}
                                            {/*    direction={1}*/}
                                            {/*    style={{width: 200, height: 200, alignSelf: 'center'}}*/}
                                            {/*/>*/}

                                            <div className={`user-profile ${formErrors?.path === 'media' && formErrors?.errors.length > 0 ? "is-invalid" : ""}`}>
                                                <div className={`hovercard`}>
                                                    <StyledUserImage className={`user-image h-100`}>
                                                        <Avatar size="xxl">
                                                            {(selectedImage || currentUser?.media?.addresses?.length > 0)  && <img src={selectedImage ?? (currentUser?.media?.addresses?.length > 0 ? currentUser?.media?.addresses[0] : ProfilePicture)} alt={name} />}
                                                            {!(selectedImage || currentUser?.media?.addresses?.length > 0) && (
                                                                <AvatarInitial bg={'gray500'}>
                                                                    {`${currentUser?.firstName} ${currentUser?.lastName}`.substring(0, 1)}
                                                                </AvatarInitial>
                                                            )}
                                                        </Avatar>
                                                        <div className="icon-wrapper step2"
                                                             data-intro="Change Profile image here">
                                                            <label htmlFor="file" className="file-label">
                                                                <i className="far fa-edit" style={{
                                                                    fontSize: 'x-large'
                                                                }}>

                                                                </i>
                                                            </label>
                                                            <input className="upload" id="file" type="file"
                                                                   name={'media'}
                                                                   onChange={(e) => {

                                                                       setUpdateTenantProfile(e.target.files[0])
                                                                       const reader = new FileReader();
                                                                       reader.onload = (event) => {
                                                                           setSelectedImage(event.target?.result)
                                                                       }
                                                                       reader.readAsDataURL(e.target.files[0])
                                                                   }}
                                                            />
                                                        </div>
                                                    </StyledUserImage>
                                                </div>
                                            </div>
                                            <Feedback className={'p-0 mt-4 text-center'}>
                                                {formErrors?.errors}
                                            </Feedback>
                                        </Row>

                                        <div className="form-row text-center">
                                            <Col md="12 mb-3 mt-2">
                                                <Form className="form-bookmark text-left">
                                                    <div className="form-row">
                                                        <FormGroup className="mb-3 col-md-6 my-0">
                                                            <Label className="mb-1"
                                                                   htmlFor="first-name">{"First Name"}</Label>
                                                            <Input className="form-control"
                                                                   type="text"
                                                                   required=""
                                                                   aria-label="First name"
                                                                   value={updateTenantData?.firstName}
                                                                   placeholder={currentUser?.firstName ?? "Enter first name"}
                                                                   name={'firstName'}
                                                                   onChange={handleChange}
                                                                   id={"firstName"}
                                                                   autoComplete="off"
                                                                   invalid={formErrors?.path === 'firstName'}
                                                            />
                                                            {formErrors?.path === 'firstName' ? <Feedback>
                                                                {formErrors?.errors}
                                                            </Feedback> : null}
                                                        </FormGroup>
                                                        <FormGroup className="mb-3 col-md-6 my-0">
                                                            <Label className="mb-1"
                                                                   htmlFor="last-name">{"Last Name"}</Label>
                                                            <Input className="form-control" type="text" required=""
                                                                   aria-label="Last name"
                                                                   placeholder={currentUser?.lastName ?? "Enter last name"}
                                                                   value={updateTenantData?.lastName}
                                                                   id={"lastName"}
                                                                   name={'lastName'}
                                                                   invalid={formErrors?.path === 'lastName'}
                                                                   onChange={handleChange}
                                                                   autoComplete="off"/>

                                                            {formErrors?.path === 'lastName' ? <Feedback>
                                                                {formErrors?.errors}
                                                            </Feedback> : null}
                                                        </FormGroup>
                                                    </div>
                                                    <div className="form-row">
                                                        <FormGroup className="mb-3 col-md-12 my-0">
                                                            <Label className="mb-1"
                                                                   htmlFor="email">{"Email address"}</Label>
                                                            <Input className="form-control"
                                                                   type="email"
                                                                   aria-label="Apartment name"
                                                                   placeholder={currentUser?.email ?? "Enter your email"}
                                                                   value={updateTenantData?.email}
                                                                   id={"email"}
                                                                   name={'email'}
                                                                   invalid={formErrors?.path === 'email'}
                                                                   onChange={handleChange}
                                                                   autoComplete="off"/>
                                                            {formErrors?.path === 'email' ? <Feedback>
                                                                {formErrors?.errors}
                                                            </Feedback> : null}
                                                        </FormGroup>
                                                    </div>
                                                    <Button marginTop={2} color="secondary" onClick={(e) => {
                                                        e.preventDefault();
                                                        schema.validate(updateTenantData).then((isValid) => {
                                                            if (isValid) {
                                                                updateTenant()
                                                                setLoading(true)
                                                            }
                                                        }).catch((error) => {
                                                            // console.log(JSON.stringify(error))
                                                            setFormErrors(error);
                                                        })
                                                    }}>{Save}</Button>
                                                </Form>
                                                <div className="valid-feedback">{"Looks good!"}</div>
                                            </Col>
                                        </div>
                                    </CardBody> :
                                    <Loading
                                        ref={loaderReference}
                                        idleTitle={"Nothing to show"}
                                        idleMessage={"Click on One of your houses to select it"}
                                        successTitle={"Hooray!"}
                                        successMessage={"House Found "}
                                        failTitle={"We're very sorry"}
                                        failMessage={"We couldn't find your house, kindly retry"}
                                        loadingTitle={"Just a moment ..."}
                                        state={loading ? 'LOADING' : "IDLE"}
                                        loadingMessage={"We're getting your house details"}
                                        // canContinue
                                        // canRetry
                                        onContinueClicked={() => setLoading(false)}
                                        onRetryClicked={() => {
                                            if (loaderReference.current)
                                                loaderReference.current.isLoading()
                                            setLoading(true)
                                        }}
                                        onCancelClicked={() => setLoading(false)}
                                    />}
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
            </Modal>

            <Overlay
                placement="bottom"
                trigger="click"
                show={showLoginPopover}
                target={findDOMNode(loginButtonRef?.current)}
            >
                <Popover id="example" title="Subscribe to remove Ads" content={true}
                         style={{minWidth: '200px', maxWidth:'420px', marginRight:"10px" }}>
                            <SigninForm hasPadding={false} hasMargin={false} onLoginCallback={()=>{
                                setShowLoginPopover(false)
                            }}  onClose={()=>setShowLoginPopover(false)}  />
                            {/*<LoginForm*/}
                            {/*    onClose={()=>setShowPaymentPopover(false)}*/}
                            {/*    selected={"firebase"}*/}
                            {/*    onLoginCallback={(results) => {*/}
                            {/*    }*/}
                            {/*    } onLoginFailed={(error) => {*/}
                            {/*}*/}
                            {/*}/>*/}
                </Popover>
            </Overlay>
        </Fragment>

    );
}

export default Rightbar;
