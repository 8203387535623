import React, {Fragment, useState} from 'react';
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Lottie from "react-lottie-player";
import likeAnimation from "@doar/shared/lottie/like.json";
import houseStub from "@doar/shared/images/house_stub.png";
import {StyledFeaturedPropertyItem} from "./style";
import {Badge} from "@doar/components";

const FeaturedPropertyItem = (props) => {

    const [isLiked, setIsLiked] = useState([]);

    return (
        <Fragment>
            <StyledFeaturedPropertyItem>
                <Card>
                    <div className={'featured-property-wrapper'}>
                        <img
                            alt={'house_image'}
                            src={props?.data?.length >0 && props?.data[0]?.media?.thumbnails?.length > 0 ? (props?.data[0]?.media?.thumbnails[0]) : houseStub}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src=houseStub;
                            }}
                            />
                        <div className={"featured-property-top-actions"}>
                            {props?.data.length > 1 ? <Badge pill style={{height: "fit-content"}}>{props?.data.length} Houses</Badge>:<div></div>}

                            <Lottie
                                loop={false}
                                play={true}
                                animationData={likeAnimation}
                                direction={isLiked ? 1 : -1}
                                segments={isLiked ? [1, 20] : [0, 20]}
                                style={{
                                    width: 50,
                                    height: 50,
                                    alignSelf: 'center'
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation()
                                    setIsLiked(!isLiked)
                                }}
                            />
                        </div>

                        <div className="featured-property-details">
                            <h6 className="m-0 price-text"> {props?.data[0]?.name}</h6>
                            <span className="p-0 address-text text-white">{ props?.data[0].district + ', ' + props?.data[0].constituency + ', ' + props?.data[0].county}</span>
                        </div>
                    </div>
                </Card>
            </StyledFeaturedPropertyItem>
        </Fragment>
    );
}
export default FeaturedPropertyItem;
