import React, {Fragment, useState} from 'react';
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Lottie from "react-lottie-player";
import likeAnimation from "@doar/shared/lottie/like.json";
import houseStub from "@doar/shared/images/house_stub.png";
import {Icon} from "@iconify/react";
import bedDoubleIcon from "@iconify/icons-uil/bed-double";
import bathIcon from "@iconify/icons-uil/bath";
import chatIcon from "@iconify/icons-uil/comments-alt";
import {StyledHighestRatedPropertyItem} from "./style";
import {Badge} from "@doar/components";

const HighestRatedPropertyItem = (props) => {

    const [isLiked, setIsLiked] = useState(props?.data[0]?.myInteractions?.liked);

    const openChat = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return props?.openChat(props?.data[0]);
    }

    return (
        <Fragment>
            <StyledHighestRatedPropertyItem onClick={(e) => props?.onClick(e)} className={'h-100'}>
                <Card className="p-1 m-1 h-100" >
                    <div className="rated-property-wrapper">
                        <img
                            alt={'highest-rated'}
                            src={props?.data?.length >0 && props?.data[0]?.media?.thumbnails?.length > 0 ? (props?.data[0]?.media?.thumbnails[0]) : houseStub}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src=houseStub;
                            }}
                            />
                        <div
                            className="rated-property-top-actions"
                            style={{top: 0, height: 40}}>
                            {props?.data.length > 1 ? <Badge pill style={{height: "fit-content"}}>{props?.data.length} Houses</Badge> :
                                <div></div>}

                            <Lottie
                                loop={false}
                                play={true}
                                animationData={likeAnimation}
                                direction={isLiked ? 1 : -1}
                                segments={isLiked ? [1, 20] : [0, 20]}
                                style={{
                                    width: 50,
                                    height: 50,
                                    alignSelf: 'center'
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation()
                                    setIsLiked(!isLiked)
                                }}
                            />
                        </div>

                        <div className="rated-property-details" style={{bottom:0}}>
                            <div>
                                <h6 className="price-text">Ksh. {props?.data[0].price.formatCurrency()}</h6>
                                <Badge color={'primary'} pill>{props?.data[0].rate}</Badge>
                            </div>
                            <span className="address-text">{ (props?.data[0]?.district??props?.data[0]?.apartment?.district) + ', ' + (props?.data[0]?.constituency??props?.data[0]?.apartment?.constituency) + ', ' + (props?.data[0]?.county??props?.data[0]?.apartment?.county)}</span>
                            <div className="card-details">
                                <Col>
                                    <Icon icon={bedDoubleIcon} style={{color: "white"}} className="details-icon mr-1"/>
                                    <div className="d-flex">
                                        <span className="font-weight-bold mr-1">{props?.data[0].bedroom}</span>
                                        <span className=""> beds</span></div>
                                </Col>
                                <Col>
                                    <Icon icon={bathIcon} style={{color: "white"}} className="details-icon mr-1"/>
                                    <div className="d-flex">
                                        <span className="font-weight-bold mr-1">{props?.data[0].bathroom}</span>
                                        <span className=""> baths</span></div>
                                </Col>
                                <Col
                                    className="cursor-pointer"
                                    onClick={(e) => openChat(e)}>
                                    <Icon icon={chatIcon} style={{color: "white"}} className="details-icon mr-1"/>
                                    <div className="d-flex"><span className=""> Chat</span></div>
                                </Col>
                            </div></div>
                    </div>
                </Card>
            </StyledHighestRatedPropertyItem>
        </Fragment>
    );
}
export default HighestRatedPropertyItem;
