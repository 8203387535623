import styled, {
    device,
    space,
    border as borderStyle,
    SpaceProps,
    BorderProps,
    css,
    themeGet,
} from "@doar/shared/styled";

interface IProps extends SpaceProps, BorderProps {
    $fullHeight?: boolean;
    $fullWidth?: boolean;
    $hasFooter?: boolean;
    $align: "top" | "center" | "bottom";
    $size: "sm" | "md" | "lg" | "xl" | "xxl";
}

const conentCSS = css<IProps>`
  //padding: 20px;

  ${device.large} {
    //padding: 25px;
    //margin-top: 60px;
  }

  ${device.xlarge} {
    //padding: 30px;
  }

  .container {
    ${({$size}) =>
            $size === "xl" &&
            css`
              ${device.xxlarge} {
                max-width: 1340px;
              }
            `}
  }

  ${({$fullHeight}) =>
          $fullHeight &&
          css`
            min-height: calc(100vh - 107px);
            display: flex;
            flex-direction: column;
            height: 100%;
            //justify-content: center;
          `}
  
  ${({$fullWidth}) =>
          $fullWidth &&
          css`
            width: 100% !important;
            margin-right: 0 !important;
            margin-left: 0 !important;
            padding-right: 0 !important;
            padding-left: 0 !important;
          `}

  ${({$align}) =>
          $align &&
          $align !== "center" &&
          css`
            & > .container {
              flex: 1;
            }
          `}

  ${({$hasFooter}) =>
          $hasFooter &&
          css`
            height: calc(100vh - 47px);
          `}
  
  
  ${({borderBottom, borderBottomWidth}) =>
          (borderBottom || borderBottomWidth) &&
          css`
            border-bottom-style: solid;
            border-bottom-color: ${themeGet("colors.border")};
          `}
  ${(props) =>
          props.theme.name === "dark" &&
          css`
            color: ${themeGet("colors.gray500")};
          `}
  ${space};
  ${borderStyle};
`;

export const StyledContent = styled(
    ({
         p,
         px,
         py,
         m,
         mx,
         my,
         mt,
         borderBottomWidth,
         $fullHeight,
         $fullWidth,
         $hasFooter,
         $align,
         ...rest
     }) => <div {...rest} />
)<IProps>`
  ${conentCSS}
`;
