import React, {Fragment, useState, useEffect, useCallback} from 'react';
import {ArrowRight, ChevronRight, Grid, Map, Search, Sliders, X} from 'react-feather'
import LeftHeader from './leftbar'
import RightHeader from './rightbar'
import {Link} from 'react-router-dom'
import Logo from "../../components/logo";
import {
    Col,
    Row,
    Anchor, Badge, Text
} from "@doar/components";
import {AsyncTypeahead, Menu} from "react-bootstrap-typeahead";
import {MenuItem} from "react-bootstrap-typeahead";
import {fetchLocationAutocomplete} from "../app/api";
import {useRegionCentersLazyQuery} from "../../graphql/generated/tenant-schema";
import {handleErrorResponse} from "../settings/data";
import {Form} from "react-bootstrap";
import {Loading} from "../constant";
import {StyledSearchInputForm} from "./style";
import LogoImg from "@doar/shared/images/logo/logo.svg";

export type Region = {
    name: string;
    location: {
        lat: number;
        long: number;
    }
}
export type County = {
    county: string;
    regions: Region[]
}

const SearchInput = ({
                         dimensions,
                         onSearchLocationSelected,
                         isListingHeader,
                         toggleMapMode,
                         hint,
                         isLandingPage,
                         onSearchClicked,
                         defaultSearchLocation
                     }) => {

    // eslint-disable-next-line
    const [searchValue, setsearchValue] = useState('');
    // eslint-disable-next-line
    const [searchResult, setSearchResult] = useState(false);
    // eslint-disable-next-line
    const [searchResultEmpty, setSearchResultEmpty] = useState(false);
    const [selectedSearchLocation, setSelectedSearchLocation] = useState(null);
    // const layout_type = useSelector(content => content.Customizer.layout)
    // const layout_version = useSelector(content => content.Customizer.mix_background_layout)
    const [options, setOptions] = useState([]);
    const [isMapMode, setIsMapMode] = useState(false);
    const [isTypeAheadOpen, setIsTypeAheadOpen] = useState(false);
    const [selectedRegions, setSelectedRegions] = useState({
        country: {
            id: 1,
            country: "Kenya"
        },
        county: null,
        constituency: null,
        district: null
    });
    const [regions, setRegions] = useState({
        countries: [],
        counties: [],
        constituencies: [],
        districts: []
    });
    const asyncTypeAheadRef = React.createRef();
    const windowSizes = {
        LG: 1230,
        MD: 992,
        SM: 767,
        XS: 479,
    }
    const popularRegions: County[] = [
        {
            county: "Nairobi",
            regions: [
                {
                    name: "Embakasi",
                    location: {
                        lat: -1.3076101,
                        long: 36.93417652550539
                    }
                },
                {
                    name: "Kasarani",
                    location: {
                        lat: -1.2125169,
                        long: 36.88298453552488
                    }
                },
                {
                    name: "Njiru",
                    location: {
                        lat: -1.2545703499999998,
                        long: 36.99205628193555
                    }
                },
                {
                    name: "Dagoretti",
                    location: {
                        lat: -1.2834512500000002,
                        long: 36.72467282028957
                    }
                },
                {
                    name: "Westlands",
                    location: {
                        lat: -1.2465281,
                        long: 36.78607587647184
                    }
                },
                {
                    name: "Kamukunji",
                    location: {
                        lat: -1.27786705,
                        long: 36.86170538305937
                    }
                },
                {
                    name: "Starehe",
                    location: {
                        lat: -1.2974809500000002,
                        long: 36.832560490569605
                    }
                },
                {
                    name: "Mathare",
                    location: {
                        lat: -1.2584051,
                        long: 36.8712435725786
                    }
                },
                {
                    name: "Lang'ata",
                    location: {
                        lat: -1.37087465,
                        long: 36.81841452351336
                    }
                },
                {
                    name: "Makadara",
                    location: {
                        lat: -1.2966106499999999,
                        long: 36.86403938977062
                    }
                },
                {
                    name: "Kibra",
                    location: {
                        lat: -1.3062893,
                        long: 36.780783074256746
                    }
                }
            ]
        }
    ]


    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setsearchValue('')
        }
    }, []);
    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, [escFunction]);

    const handleSearchKeyword = (keyword) => {
        keyword ? addFix() : removeFix()
        const items = [];
        setsearchValue(keyword)
    }

    function getRadiusBasedOnRegionGroup(selectedGroupRegions) {
        return selectedGroupRegions?.district ? 5 : (selectedGroupRegions?.constituency ? 10 : (selectedGroupRegions.county ? 15 : 7));
    }

    const onKeyDown = useCallback(
        (e) => {
            if (e.keyCode === 13) {
                setTimeout(() => {
                    asyncTypeAheadRef?.current?.clear();
                    setSelectedSearchLocation(`${options[0]?.properties?.name}, ${options[0].properties.city ?? options[0].properties.state}`);
                    if (onSearchLocationSelected) {
                        onSearchLocationSelected({
                            lat: options[0]?.geometry?.coordinates[1],
                            lng: options[0]?.geometry.coordinates[0]
                        }, getRadiusBasedOnRegionGroup(selectedRegions), null)
                    }
                    // setSelectedSearchLocation(null);
                }, 1000)
            }
        },
        [options]
    );

    const checkSearchResultEmpty = (items) => {
        if (!items.length) {
            setSearchResultEmpty(true);
            document.querySelector(".empty-menu").classList.add('is-open');
        } else {
            setSearchResultEmpty(false);
            document.querySelector(".empty-menu").classList.remove('is-open');
        }
    }

    const addFix = () => {
        setSearchResult(true);
        document.querySelector(".Typeahead-menu").classList.add('is-open');
        document.body.className = `light box-layout offcanvas`
    }

    const removeFix = () => {
        setSearchResult(false)
        setsearchValue('')
        document.querySelector(".Typeahead-menu").classList.remove('is-open');
        document.body.className = `light box-layout`
    }

    const handleSearch = (query) => {
        if (query.length >= 3) {
            setsearchValue(query);
            fetchLocationAutocomplete(query).then((response) => {
                if (response.ok) {
                    response.json().then((results) => {
                        if (results.hasOwnProperty("features"))
                            results.features.map((feature) => {
                                feature.label = feature.properties.name + "," + (feature?.properties?.city ?? feature?.properties?.state ?? "Unknown city")
                                feature.value = feature?.properties?.city ?? feature?.properties?.state ?? "Unknown city"
                            })
                        const foundLocations = new Set([]);
                        setOptions(results.features.filter((feature) => {
                            if (!foundLocations.has(feature.label)) {
                                foundLocations.add(feature.label);
                                return true;
                            }
                            return false;
                        }));
                    })
                }

            }).catch((error) => {
                console.log()
            })
        } else {
            setsearchValue("");
        }
    };

    const [getRegionCenters] = useRegionCentersLazyQuery(
        {
            onCompleted: (result) => {
                setRegions({
                    countries: null,
                    counties: result?.getRegionCenters?.counties ?? regions.counties,
                    constituencies: result.getRegionCenters.constituencies ?? regions.constituencies,
                    districts: result.getRegionCenters.districts ?? regions.districts,
                });
            },
            onError: (error) => {
                handleErrorResponse(error)

            }
        }
    )
    useEffect(() => {
        getRegionCenters({
            variables: {
                region: {
                    countryId: selectedRegions?.country?.id,
                    countyId: selectedRegions?.county?.id,
                    constituencyId: selectedRegions?.constituency?.id,
                }
            }
        })
    }, [selectedRegions])
    const searchLocationSelected = (location, radius, zoom, displayName) => {
        if (onSearchLocationSelected)
            onSearchLocationSelected(location, radius, zoom, displayName)
    }

    return (
        <Fragment>
            <StyledSearchInputForm
                className={`header-search form-inline flex-grow-1 h-100 ${isListingHeader ? 'open' : ''}`}
                action="#"
                method="get">
                {isListingHeader && <div className="form-group w-100 h-100 m-0">
                    <div className={`Typeahead Typeahead--twitterUsers h-100  ${isLandingPage && "is-landing-page"}`}>
                        <div className="u-posRelative h-100 d-flex flex-row align-items-center pr-3 ">
                            {!isLandingPage && <Search/>}
                            <AsyncTypeahead
                                ref={asyncTypeAheadRef}
                                filterBy={() => true}
                                id="searchMap"
                                isLoading={false}
                                labelKey="label"
                                minLength={0}
                                onFocus={(event) => {
                                    // toggleTypeAheadMenu()
                                }
                                }
                                // open={isTypeAheadOpen}
                                defaultOpen={false}
                                useCache={true}
                                onSearch={handleSearch}
                                onKeyDown={onKeyDown}
                                options={options}
                                paginate={false}
                                onChange={(selected) => {
                                    return onSearchLocationSelected ? onSearchLocationSelected({
                                        lat: selected[0]?.geometry?.coordinates[1],
                                        lng: selected[0]?.geometry.coordinates[0]
                                    }, getRadiusBasedOnRegionGroup(selectedRegions), null, selectedSearchLocation) : null
                                }}
                                className={`Typeahead form-control-plaintext w-100 h-100 location-search pl-2 full`}
                                type="search"
                                placeholder={selectedSearchLocation ? selectedSearchLocation?.toCamelCase() : (defaultSearchLocation?.place ?? hint ?? "Where would you like to live ?")}
                                defaultValue={searchValue}
                                // selected={selectedSearchLocation?[selectedSearchLocation]:null}
                                renderMenu={(options, prop) => (
                                    <Fragment>
                                        <Col className={'search-menu'} p={isLandingPage ? 1 : undefined}
                                             mt={isLandingPage ? 18 : 0}>
                                            <Col m={0} pb={2} mb={3} mt={2} style={{borderBottom: "1px solid #e3e7ed"}}>
                                                <Text fontWeight={600}>Popular regions
                                                    in {popularRegions[0].county} county</Text>
                                                <Row m={0} style={{flexWrap: "wrap"}}>
                                                    {popularRegions[0].regions.map((region, index) =>
                                                        <Badge style={{
                                                            cursor: "pointer"
                                                        }} onClick={() => {
                                                            asyncTypeAheadRef?.current?.clear();
                                                            setSelectedSearchLocation(region?.name)
                                                            searchLocationSelected({
                                                                lat: region?.location?.lat,
                                                                lng: region?.location?.long
                                                            }, 10, 14, region?.name)
                                                        }} key={`pop_region_${index}`} shape={'square'}
                                                               ml={2} mb={2}><Text fontWeight={500} fontSize={12}
                                                                                   color={'white'}>{region.name}</Text></Badge>
                                                    )}
                                                </Row>
                                            </Col>
                                            {searchValue.length > 0 ? (options.length > 0 ?
                                                    <Col sm={isLandingPage ? 12 : 4} className={"search-results"}
                                                         style={{maxHeight: 300}}>
                                                        <h6 className={'pl-3'}>Search Results</h6>
                                                        <div className={"overflow-auto custom-scrollbar"}>
                                                            {options.map((option, index) => <MenuItem
                                                                key={`option_${index}`}
                                                                onClick={(event) => {
                                                                    setSelectedSearchLocation(`${option?.properties?.name}, ${option.properties.city ?? option.properties.state}`);
                                                                    asyncTypeAheadRef?.current?.clear();
                                                                    return onSearchLocationSelected ? onSearchLocationSelected({
                                                                        lat: option?.geometry?.coordinates[1],
                                                                        lng: option?.geometry.coordinates[0]
                                                                    }, 10, null) : null;
                                                                }} option={option} position={index}>
                                                                <div>
                                                                    <span
                                                                        className={"font-weight-bolder"}>{option.properties.name.toCamelCase()}</span><br/>
                                                                    <span
                                                                        className="text-muted">{option.properties.city ?? option.properties.state}</span>
                                                                </div>
                                                            </MenuItem>)}
                                                        </div>
                                                    </Col> : null) :
                                                <Text textAlign={'center'} p={10} style={{width: "100%"}}
                                                      fontWeight={300}>{"Enter name of any place you'd like to find a house above"}</Text>}

                                            {!isLandingPage &&
                                            <Col sm={options.length > 0 ? 8 : 12} className={`select-results`}>
                                                <div className={'select-results-top'}>
                                                    <h6 className={'p-2'}>Select Locations</h6>
                                                    <div className={'d-flex flex-row'}>
                                                        {selectedRegions?.country ? <Badge
                                                            color={'secondary'}
                                                            role="button"
                                                            tabIndex={-1}
                                                            style={{height: 'fit-content'}}>{selectedRegions?.country.country}</Badge> : null}
                                                        {selectedRegions?.county ? <Badge
                                                            color={'secondary'}
                                                            onClick={() => setSelectedRegions((current) => {
                                                                return {
                                                                    ...current,
                                                                    county: null,
                                                                    constituency: null,
                                                                    district: null
                                                                }
                                                            })}
                                                            role="button"
                                                            tabIndex={-1}
                                                            style={{height: 'fit-content'}}>{selectedRegions?.county.county}
                                                            <span></span><X size={10}/></Badge> : null}
                                                        {selectedRegions?.constituency ? <Badge
                                                            onClick={() => {
                                                                setSelectedRegions((current) => {
                                                                    return {
                                                                        ...current,
                                                                        constituency: null,
                                                                        district: null
                                                                    }
                                                                })
                                                            }}
                                                            color={'secondary'}
                                                            role="button"
                                                            tabIndex={-1}
                                                            style={{height: 'fit-content'}}>{selectedRegions?.constituency.constituency}
                                                            <span></span><X size={10}/></Badge> : null}
                                                        {selectedRegions?.district ? <Badge
                                                            onClick={() => setSelectedRegions((current) => {
                                                                return {
                                                                    ...current,
                                                                    district: null
                                                                }
                                                            })}
                                                            color={'secondary'}
                                                            role="button"
                                                            tabIndex={-1}
                                                            style={{height: 'fit-content'}}>{selectedRegions?.district.district}
                                                            <span></span><X size={10}/></Badge> : null}
                                                    </div>
                                                </div>
                                                <div className={"select-results-list"}>
                                                    <Col sm={dimensions == windowSizes.LG ? 4 : 12} style={{
                                                        display: [windowSizes.MD, windowSizes.SM, windowSizes.XS].includes(dimensions) && selectedRegions.county ? "none" : "flex",
                                                        flexDirection: "column"
                                                    }}>
                                                        <p className={''}>COUNTIES</p>
                                                        <div className={" overflow-auto custom-scrollbar"}>
                                                            {regions?.counties?.map((region, index) => {
                                                                return <li
                                                                    key={`region_${index}`}
                                                                    className={`d-flex flex-row align-items-center justify-content-between border-bottom p-1 ${region == selectedRegions.county ? 'bg-primary font-weight-bold' : ''}`}

                                                                    onClick={() => {
                                                                        setSelectedSearchLocation(`${region.county}`)
                                                                        setSelectedRegions((current) => {
                                                                            searchLocationSelected({
                                                                                lat: region.lat,
                                                                                lng: region.lng
                                                                            },  getRadiusBasedOnRegionGroup({
                                                                                ...current,
                                                                                county: current.county == null ? region : current.county,
                                                                                constituency: current.county != null && current.constituency == null ? region : current.constituency,
                                                                                district: current.county != null && current.constituency != null && current.district == null ? region : current.district,
                                                                            }), 11,);
                                                                            return {
                                                                                ...current,
                                                                                county: region,
                                                                                constituency: null,
                                                                                district: null,
                                                                            }
                                                                        })
                                                                    }
                                                                    }
                                                                >{region?.county}<ChevronRight size={10}/></li>
                                                            })}
                                                        </div>
                                                    </Col>
                                                    {selectedRegions.county
                                                        ?
                                                        <Col sm={dimensions == windowSizes.LG ? 4 : 12} style={{
                                                            display: [windowSizes.MD, windowSizes.SM, windowSizes.XS].includes(dimensions) && (selectedRegions.constituency || !selectedRegions.county) ? "none" : "flex",
                                                            flexDirection: "column"
                                                        }}>
                                                            <p className={''}>CONSTITUENCIES</p>
                                                            <div className={"overflow-auto custom-scrollbar"}>
                                                                {regions?.constituencies?.map((region, index) => {
                                                                    return <li
                                                                        key={`const_${index}`}
                                                                        className={`d-flex flex-row align-items-center justify-content-between border-bottom p-1 ${region == selectedRegions.constituency ? 'bg-primary' : ''}`}

                                                                        onClick={() => {
                                                                            setSelectedSearchLocation(`${selectedRegions.county.county}, ${region.constituency}`);
                                                                            setSelectedRegions((current) => {
                                                                                searchLocationSelected({
                                                                                    lat: region.lat,
                                                                                    lng: region.lng
                                                                                },  getRadiusBasedOnRegionGroup({
                                                                                    ...current,
                                                                                    county: current.county == null ? region : current.county,
                                                                                    constituency: current.county != null && current.constituency == null ? region : current.constituency,
                                                                                    district: current.county != null && current.constituency != null && current.district == null ? region : current.district,
                                                                                }), 12);
                                                                                return {
                                                                                    ...current,
                                                                                    county: current.county,
                                                                                    constituency: region,
                                                                                    district: null,
                                                                                }
                                                                            })
                                                                        }
                                                                        }
                                                                    >{region?.constituency}<ChevronRight
                                                                        size={10}/>
                                                                    </li>
                                                                })}
                                                            </div>
                                                        </Col> : null}
                                                    {selectedRegions.constituency
                                                        ? <Col
                                                            className={`col-4 flex-column p-0  ${[windowSizes.MD, windowSizes.SM, windowSizes.XS].includes(dimensions) && (selectedRegions.district || !selectedRegions.constituency) ? 'd-none' : (dimensions == windowSizes.LG ? 'd-flex col-4' : 'd-flex col-12')}`}>
                                                            <p className={''}>DISTRICTS</p>
                                                            <div className={"overflow-auto custom-scrollbar"}>
                                                                {regions?.districts?.map((region, index) => {
                                                                    return <li
                                                                        key={`district_${index}`}
                                                                        className={`d-flex flex-row align-items-center justify-content-between border-bottom border-bottom p-1 ${region == selectedRegions.district ? 'bg-primary' : ''}`}
                                                                        role="button"
                                                                        onClick={() => {
                                                                            setSelectedSearchLocation(`${selectedRegions.county.county}, ${selectedRegions.constituency.constituency}, ${region.district}`);
                                                                            asyncTypeAheadRef?.current?.clear()
                                                                            setSelectedRegions((current) => {
                                                                                searchLocationSelected({
                                                                                    lat: region.lat,
                                                                                    lng: region.lng
                                                                                }, getRadiusBasedOnRegionGroup({
                                                                                    ...current,
                                                                                    county: current.county == null ? region : current.county,
                                                                                    constituency: current.county != null && current.constituency == null ? region : current.constituency,
                                                                                    district: current.county != null && current.constituency != null && current.district == null ? region : current.district,
                                                                                }), 14);
                                                                                return {
                                                                                    ...current,
                                                                                    county: current.county == null ? region : current.county,
                                                                                    constituency: current.county != null && current.constituency == null ? region : current.constituency,
                                                                                    district: region,
                                                                                }
                                                                            })
                                                                        }
                                                                        }
                                                                    >{region?.district}<ChevronRight size={10}/>
                                                                    </li>
                                                                })}
                                                            </div>
                                                        </Col> : null}
                                                </div>
                                            </Col>}
                                        </Col>
                                    </Fragment>
                                )}
                                // renderMenuItemChildren={(option, props) => (
                                //     <Fragment>
                                //         <Col>
                                //             <div>
                                //                 <h6>{option.properties.name}</h6>
                                //                 <span
                                //                     className="text-muted">{option.properties.city ?? option.properties.state}</span>
                                //             </div>
                                //
                                //         </Col>
                                //     </Fragment>
                                // )}
                            />
                            {isLandingPage ?
                                <div
                                    onClick={() => onSearchClicked ? onSearchClicked() : null}
                                    className={'map-mode-selector'}>
                                    <Fragment>
                                        <Search size={20}/>
                                        <span>Search</span>
                                    </Fragment>

                                </div>
                                : <div
                                    onClick={() => setIsMapMode(toggleMapMode())}
                                    className={'map-mode-selector'}>
                                    {isMapMode ? <Fragment>
                                        <Grid size={20}/>
                                        <span>List</span>
                                    </Fragment> : <Fragment>
                                        <Map size={20}/>
                                        <span>Map</span>
                                    </Fragment>}
                                </div>}
                            <div className="spinner-border Typeahead-spinner" role="status">
                                <span className="sr-only">{Loading}</span>
                            </div>
                        </div>
                        {/*<div className="Typeahead-menu custom-scrollbar" id="search-outer">*/}
                        {/*    {searchValue ?*/}
                        {/*        searchValue.map((data, index) => {*/}
                        {/*            return (*/}
                        {/*                <div className="ProfileCard u-cf" key={index}>*/}
                        {/*                    <div className="ProfileCard-avatar">*/}
                        {/*                        <data.icon/>*/}
                        {/*                    </div>*/}
                        {/*                    <div className="ProfileCard-details">*/}
                        {/*                        <div className="ProfileCard-realName">*/}
                        {/*                            <Anchor*/}
                        {/*                                path={data.path}*/}
                        {/*                                className="realname"*/}
                        {/*                                onClick={removeFix}*/}
                        {/*                            >*/}
                        {/*                                {data.title}*/}
                        {/*                            </Anchor>*/}
                        {/*                        </div>*/}
                        {/*                    </div>*/}
                        {/*                </div>*/}
                        {/*            )*/}
                        {/*        }) : ''*/}
                        {/*    }*/}
                        {/*</div>*/}
                        {/*<div className="Typeahead-menu empty-menu">*/}
                        {/*    <div className="tt-dataset tt-dataset-0">*/}
                        {/*        <div className="EmptyMessage">*/}
                        {/*            {"Opps!! There are no result found."}*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>
                </div>}
            </StyledSearchInputForm>
        </Fragment>
    );
}

export default SearchInput;
