import styled, {css, space, SpaceProps, themeGet} from "@doar/shared/styled";
import {CardBody, CardHeader} from "@doar/components";

export const StyledCenteredDiv = styled(({...rest}) => <div {...rest} />)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 10px;

  .loading-buttons {
    display: flex;
    justify-content: space-between;
    padding: 0 10px 10px 0px;

    button {
      margin-top: 10px;
      display: -webkit-inline-box;
      display: -webkit-inline-flex;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-align-items: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      font-weight: 400;
      text-align: center;
      vertical-align: middle;
      cursor: pointer;
      line-height: 1.5;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      border: 1px solid transparent;
      -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

      &.btn-next {
        color: rgba(27, 46, 75, 0.7);
        background-color: #ffffff;
        border-color: ${themeGet("colors.text4")};
      }

      &.btn-prev {
        color: #ffffff;
        background-color: ${themeGet("colors.primary")} !important;
        border-color: ${themeGet("colors.primary")};
      }

      //font-size: 13px;
      padding: 0.391rem 15px;
      border-radius: 0.25rem;
      margin-left: 10px;
    }
  }

`;
