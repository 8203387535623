import {createGlobalStyle} from "styled-components";
import {device, themeGet} from "../styled";

import {IbmFontSans, InterUi, Rubik, FontAwesome} from "../fonts";

const GlobalStyle = createGlobalStyle`
  ${IbmFontSans}

  ;
  ${InterUi}

  ;
  ${Rubik}

  ;
  ${FontAwesome}

  ;
  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    scroll-behavior: smooth;
    height: 100%;
  }

  article,
  aside,
  details,
  figcaption,
  figure,
  footer,
  header,
  nav,
  section,
  summary {
    display: block;
  }

  audio,
  canvas,
  video {
    display: inline-block;
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  [hidden] {
    display: none;
  }

  html,
  button,
  input,
  select,
  textarea {
    font-family: ${themeGet("fonts.body")};
  }

  a:focus {
    outline: thin dotted;
  }

  a:active,
  a:hover {
    outline: 0;
  }

  b,
  strong {
    font-weight: bold;
  }

  blockquote,
  q {
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
    quotes: none;
  }

  small {
    font-size: smaller;
  }

  sub,
  sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
  }

  sup {
    top: -0.5em;
  }

  sub {
    bottom: -0.25em;
  }

  li > ul,
  li > ol {
    margin: 0;
  }

  img {
    -ms-interpolation-mode: bicubic;
    border: 0;
    vertical-align: middle;
  }

  svg:not(:root) {
    overflow: hidden;
  }

  figure {
    margin: 0;
  }

  form {
    margin: 0;
  }

  button,
  input,
  select,
  textarea {
    font-size: 100%;
    margin: 0;
    max-width: 100%;
    vertical-align: baseline;
  }

  button,
  input {
    line-height: normal;
  }

  button,
  html input[type="button"],
  input[type="reset"],
  input[type="submit"] {
    -webkit-appearance: button;
    -moz-appearance: button;
    appearance: button;
    cursor: pointer;
  }

  button[disabled],
  input[disabled] {
    cursor: default;
  }

  input[type="checkbox"],
  input[type="radio"] {
    padding: 0;
  }

  input[type="search"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
    appearance: textfield;
    padding-right: 2px;
    width: 100%;
  }

  input[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
    appearance: none;
  }

  button::-moz-focus-inner,
  input::-moz-focus-inner {
    border: 0;
    padding: 0;
  }

  textarea {
    overflow: auto;
    vertical-align: top;
  }

  img {
    max-width: 100%;
  }

  html {
    overflow: hidden;
    overflow-y: hidden;
  }

  body {
    overflow-x: hidden;
    height: 100%;
    display: inline;
    margin: 0;
    padding: 0;
    line-height: ${themeGet("lineHeights.body")};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: ${themeGet("fonts.body")};
    font-weight: ${themeGet("fontWeights.body")};
    color: ${themeGet("colors.text")};
    background: ${themeGet("colors.background")};
    font-size: ${themeGet("fontSize.body.0")};

    ${device.medium} {
      font-size: ${themeGet("fontSize.body.1")};
    }
  }

  #root {
    height: 100%;
  }

  a {
    transition: ${themeGet("transition")};
    color: ${themeGet("colors.primary")};
    text-decoration: none;

    &:hover {
      text-decoration: none;
    }
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    font-family: ${themeGet("fonts.heading")};
    line-height: ${themeGet("lineHeights.heading")};
    color: ${themeGet("colors.heading")};
    font-weight: ${themeGet("fontWeights.heading")};
    margin: 0;
    margin-bottom: 8px;
  }

  h1,
  .h1 {
    font-size: ${themeGet("fontSize.h1.0")};

    ${device.small} {
      font-size: ${themeGet("fontSize.h1.1")};
    }

    ${device.medium} {
      font-size: ${themeGet("fontSize.h1.2")};
    }

    ${device.large} {
      font-size: ${themeGet("fontSize.h1.3")};
    }
  }

  h2,
  .h2 {
    font-size: ${themeGet("fontSize.h2.0")};

    ${device.medium} {
      font-size: ${themeGet("fontSize.h2.1")};
    }

    ${device.large} {
      font-size: ${themeGet("fontSize.h2.2")};
    }
  }

  h3,
  .h3 {
    font-size: ${themeGet("fontSize.h3.0")};

    ${device.large} {
      font-size: ${themeGet("fontSize.h3.1")};
    }
  }

  h4,
  .h4 {
    font-size: ${themeGet("fontSize.h4.0")};

    ${device.large} {
      font-size: ${themeGet("fontSize.h4.1")};
    }
  }

  h5,
  .h5 {
    font-size: ${themeGet("fontSize.h5.0")};

    ${device.large} {
      font-size: ${themeGet("fontSize.h5.1")};
    }
  }

  h6,
  .h6 {
    font-size: ${themeGet("fontSize.h6.0")};

    ${device.medium} {
      font-size: ${themeGet("fontSize.h6.1")};
    }

    ${device.large} {
      font-size: ${themeGet("fontSize.h6.1")};
    }
  }

  p {
    margin: 0 0 15px;
    font-family: ${themeGet("fonts.body")};
    color: ${themeGet("colors.text")};
    font-weight: ${themeGet("fontWeight.body")};

    &:last-child {
      margin-bottom: 0;
    }

    &:only-child {
      margin-bottom: 0;
    }
  }

  input,
  select,
  textarea {
    background: transparent;
    border: 1px solid ${themeGet("colors.border")};
    transition: ${themeGet("transition")};
    color: ${themeGet("colors.text")};

    &:focus,
    &:active {
      outline: none;
      border-color: ${themeGet("colors.primary")};
    }
  }

  input,
  select,
  textarea {
    width: 100%;
    font-size: 14px;
  }

  input,
  select {
    height: 40px;
    padding: 0 15px;
  }

  .wrapper {
    position: relative;
  }

  .sr-only {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  .link-overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-indent: -99999px;
  }

  table {
    border-collapse: collapse;
  }

  th {
    text-align: inherit;
  }

  hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid ${themeGet("colors.border")};
  }

  .__react_component_tooltip {
    font-size: 11px !important;
    padding: 3px 13px !important;
    width: max-content !important;
    background-color: ${themeGet("colors.black")} !important;

    &.place-top::after {
      border-top-color: ${themeGet("colors.black")} !important;
    }
  }

  .tooltip {
    position: absolute;
    z-index: 1080;
    display: block;
    margin: 0;
    font-family: var(--bs-font-sans-serif);
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .875rem;
    word-wrap: break-word;
    opacity: 0
  }

  .tooltip.show {
    opacity: .9
  }

  .tooltip .tooltip-arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
  }

  .tooltip .tooltip-arrow::before {
    position: absolute;
    content: "";
    border-color: transparent;
    border-style: solid
  }

  .bs-tooltip-auto[data-popper-placement^=top], .bs-tooltip-top {
    padding: .4rem 0
  }

  .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow, .bs-tooltip-top .tooltip-arrow {
    bottom: 0
  }

  .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before, .bs-tooltip-top .tooltip-arrow::before {
    top: -1px;
    border-width: .4rem .4rem 0;
    border-top-color: #000
  }

  .bs-tooltip-auto[data-popper-placement^=right], .bs-tooltip-end {
    padding: 0 .4rem
  }

  .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow, .bs-tooltip-end .tooltip-arrow {
    left: 0;
    width: .4rem;
    height: .8rem
  }

  .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before, .bs-tooltip-end .tooltip-arrow::before {
    right: -1px;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #000
  }

  .bs-tooltip-auto[data-popper-placement^=bottom], .bs-tooltip-bottom {
    padding: .4rem 0
  }

  .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow, .bs-tooltip-bottom .tooltip-arrow {
    top: 0
  }

  .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before, .bs-tooltip-bottom .tooltip-arrow::before {
    bottom: -1px;
    border-width: 0 .4rem .4rem;
    border-bottom-color: #000
  }

  .bs-tooltip-auto[data-popper-placement^=left], .bs-tooltip-start {
    padding: 0 .4rem
  }

  .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow, .bs-tooltip-start .tooltip-arrow {
    right: 0;
    width: .4rem;
    height: .8rem
  }

  .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before, .bs-tooltip-start .tooltip-arrow::before {
    left: -1px;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #000
  }

  .tooltip-inner {
    max-width: 200px;
    padding: .25rem .5rem;
    color: #fff;
    text-align: center;
    background-color: #000;
    border-radius: .25rem
  }

  .popover {
    //position: absolute;
    //top: 0;
    //left: 0;
    //z-index: 1070;
    //max-width: 276px;
    //font-style: normal;
    //font-weight: 400;
    //line-height: 1.5;
    //text-align: left;
    //text-align: start;
    //text-decoration: none;
    //text-shadow: none;
    //text-transform: none;
    //letter-spacing: normal;
    //word-break: normal;
    //word-spacing: normal;
    //white-space: normal;
    //line-break: auto;
    //font-size: .875rem;
    //word-wrap: break-word;
    //background-color: #fff;
    //background-clip: padding-box;
    //border: 1px solid rgba(0, 0, 0, .2);
    //border-radius: .3rem

    word-wrap: break-word;
    background-clip: padding-box;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 0.3rem;
    font-size: .875rem;
    font-style: normal;
    font-weight: 400;
    left: 0;
    letter-spacing: normal;
    line-break: auto;
    line-height: 1.5;
    max-width: 276px;
    text-align: left;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    top: 0;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    z-index: 1060;
    opacity: 0;
  }

  .popover.show {
    opacity: 1;
  }

  .popover.fade {
    transition: 0.15s;
    transition: opacity .25s linear;
    -moz-transition: opacity .25s linear;
    -webkit-transition: opacity .25s linear;
  }

  .popover .popover-arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem
  }

  .popover .popover-arrow::after, .popover .popover-arrow::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid
  }

  .bs-popover-auto[data-popper-placement^=top] > .popover-arrow, .bs-popover-top > .popover-arrow {
    bottom: calc(-.5rem - 1px)
  }

  .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::before {
    bottom: 0;
    border-width: .5rem .5rem 0;
    border-top-color: rgba(0, 0, 0, .25)
  }

  .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after, .bs-popover-top > .popover-arrow::after {
    bottom: 1px;
    border-width: .5rem .5rem 0;
    border-top-color: #fff
  }

  .bs-popover-auto[data-popper-placement^=right] > .popover-arrow, .bs-popover-end > .popover-arrow {
    left: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem
  }

  .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: rgba(0, 0, 0, .25)
  }

  .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after, .bs-popover-end > .popover-arrow::after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
  }

  .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow, .bs-popover-bottom > .popover-arrow {
    top: calc(-.5rem - 1px)
  }

  .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::before {
    top: 0;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: rgba(0, 0, 0, .25)
  }

  .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after, .bs-popover-bottom > .popover-arrow::after {
    top: 1px;
    border-width: 0 .5rem .5rem .5rem;
    border-bottom-color: #fff
  }

  .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before, .bs-popover-bottom .popover-header::before {
    position: absolute;
    top: 0;
    left: 50%;
    display: block;
    width: 1rem;
    margin-left: -.5rem;
    content: "";
    border-bottom: 1px solid #f0f0f0
  }

  .bs-popover-auto[data-popper-placement^=left] > .popover-arrow, .bs-popover-start > .popover-arrow {
    right: calc(-.5rem - 1px);
    width: .5rem;
    height: 1rem
  }

  .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: rgba(0, 0, 0, .25)
  }

  .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after, .bs-popover-start > .popover-arrow::after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
  }

  .popover-header {
    padding: .5rem 1rem;
    margin-bottom: 0;
    font-size: 1rem;
    background-color: #f0f0f0;
    border-bottom: 1px solid rgba(0, 0, 0, .2);
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px)
  }

  .popover-header:empty {
    display: none
  }

  .popover-body {
    padding: 1rem 1rem;
    color: #212529
  }

  div::-webkit-scrollbar {
    width: 6px;
  }

  div::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #cbe5ff;
  }

  div::-webkit-scrollbar-thumb {
    background-color: ${themeGet("colors.primaryOpaque")};
  }

  .font-weight-bold {
    font-weight: 600;
  }

  .font-weight-bolder {
    font-weight: 700;
  }

  .font-weight-boldest {
    font-weight: 900;
  }

  #sc-launcher {
    z-index: 10;

    .sc-chat-window {
      font-family: ${themeGet("fonts.body")};

      .sc-header {
        background-color: ${themeGet("colors.primary")};
        min-height: 0;
        min-height: auto;
        padding: 0;
      }

      .sc-message--content {
        &:not(.received) {
          .sc-message--text {
            background-color: ${themeGet("colors.background")};
            border-radius: 10px 10px 0 10px;
            color: ${themeGet("colors.primary")};
            font-weight: 500;
            font-weight: 400;
            max-width: none;
            padding: 10px;
            position: relative;
            width: auto;
          }
        }
      }
    }
  }

  .popover-purchase {
    .subscription-wrapper {
      .subscription-header {
        display: flex;
        justify-content: space-between;
        padding: 0.75rem;

        h6 {
          width: 100%;
          text-align: center;
        }
      }

      h5 {
        text-align: center;
      }

      .subscribed-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h5 {
          text-align: center;
        }

      }

      .payment-pending-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        h5 {
          text-align: center;
        }
      }

      .subscriptions {
        display: flex;
        width: 100%;
        padding: 0.75rem;
        margin: 0;

        & > div {
          display: flex;
          flex-direction: column;

          & > div {
            border-radius: 0.25rem !important;
            text-align: center;
            height: 100%;
          }
        }
      }

      .continue-button {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  small, .small {
    font-size: .875em;
    font-weight: 400;
  }

  .text-muted {
    color: #6c757d !important
  }

  .text-white {
    color: white;
  }

  .accordion {
    --bs-accordion-color: black;
    --bs-accordion-bg: var(--bs-body-bg);
    --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: 0px;
    --bs-accordion-border-radius: 10px;
    --bs-accordion-inner-border-radius: 10px;
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: white;
    --bs-accordion-btn-bg: rgba(0, 0, 0, 0.8);
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23052c65'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-focus-border-color: transparent;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: white;
    --bs-accordion-active-bg: rgba(248, 65, 99);
  }

  .accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
    font-size: 0.875rem;
    font-weight: 700;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
  }

  @media (prefers-reduced-motion: reduce) {
    .accordion-button {
      transition: none;
    }
  }

  .accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
  }

  .accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
  }

  .accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
  }

  @media (prefers-reduced-motion: reduce) {
    .accordion-button::after {
      transition: none;
    }
  }

  .accordion-button:hover {
    z-index: 2;
  }

  .accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow);
  }

  .accordion-header {
    margin-bottom: 0;
  }

  .accordion-item {
    /* color: var(--bs-accordion-color); */
    background-color: var(--bs-accordion-bg);
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
  }

  .accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius);
  }

  .accordion-item:first-of-type .accordion-button {
    border-top-left-radius: var(--bs-accordion-inner-border-radius);
    border-top-right-radius: var(--bs-accordion-inner-border-radius);
  }

  .accordion-item:not(:first-of-type) {
    border-top: 0;
  }

  .accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius);
  }

  .accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
  }

  .accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius);
  }

  .accordion-body {
    padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
    font-size: 0.875rem;

  }

  .accordion-flush .accordion-collapse {
    border-width: 0;
  }

  .accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
  }

  .accordion-flush .accordion-item:first-child {
    border-top: 0;
  }

  .accordion-flush .accordion-item:last-child {
    border-bottom: 0;
  }

  .accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0;
  }

  [data-bs-theme=dark] .accordion-button::after {
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%236ea8fe'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  .collapse:not(.show) {
    display: none;
  }

  .collapsing {
    height: 0;
    overflow: hidden;
    transition: height 0.35s ease;
  }

  .splide__arrow {
    background: #f53d68 !important;
    width: 2.5rem !important;
    height: 2.5rem !important;
  }
  .splide__arrow svg{
    fill: #fff !important;
  }
  .ribbon{
    font-size: 10px;
    color: #fff;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold; line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg); /* Needed for Safari */
    width: 100px; display: block;
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px; right: -21px;
  }
  .ribbon.left{
    transform: rotate(315deg) !important;
    -webkit-transform: rotate(315deg) !important; /* Needed for Safari */
    left: -21px !important;
    right: unset;
  }
  .ribbon-success{
    background: #79A70A;
    background: linear-gradient(#9BC90D 0%, #79A70A 100%);
  }
  .ribbon::before {
    content: '';
    position: absolute;
    left: 0px; top: 100%;
    z-index: -1;
    border-left: 3px solid #79A70A;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
  }
  .ribbon::after {
    content: '';
    position: absolute;
    right: 0%; top: 100%;
    z-index: -1;
    border-right: 3px solid #79A70A;
    border-left: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #79A70A;
  }

`;

export default GlobalStyle;
