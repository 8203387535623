import {useState, useEffect} from "react";
import {Settings} from "react-feather";
import {Row, Col} from "@doar/components";
import {TTheme} from "@doar/shared/types";
import {SkinModes} from "./data";
import Mode from "./mode";
import {
    StyledSettings,
    StyledSettingsBtn,
    StyledSettingsBody,
    StyledCard,
    StyledLogo,
    StyledNote,
    StyledLabel,
    StyledNavLink,
} from "./style";

interface IProps {
    themeHandler: (el: TTheme) => void;
    curTheme: TTheme;
}

const SettingsCard = ({themeHandler, curTheme}: IProps) => {
    const [show, setShow] = useState(false);
    const [location, setLocation] = useState("");
    useEffect(() => {
        setLocation(window.location.origin);
    }, []);

    return (
        <StyledSettings $show={show}>
            <StyledSettingsBtn
                $show={show}
                onClick={() => setShow((prev) => !prev)}
            >
                <Settings width={20} height={20}/>
            </StyledSettingsBtn>
            <StyledSettingsBody>
                <StyledCard>
                    <StyledLogo>
                        Keja<span>Rent</span>
                    </StyledLogo>
                    <StyledNote>Easily find affordable houses & apartments to rent in kenya</StyledNote>
                </StyledCard>

                <StyledCard $hasBorder>
                    <StyledLabel>Skin Mode</StyledLabel>
                    <Row gutters={10}>
                        {SkinModes.map((el, i) => (
                            <Col xs={4} sm={4} key={el} mt={i > 2 ? "10px" : 0}>
                                <Mode
                                    mode={el}
                                    onClick={() => themeHandler(el)}
                                    active={curTheme === el}
                                />
                            </Col>
                        ))}
                    </Row>
                </StyledCard>

                <div className="pd-y-20 bd-t" style={{display: "none"}}>
                    <h4 className="tx-sans tx-10 tx-uppercase tx-bold tx-spacing-1 tx-color-02 mg-b-15">
                        Navigation Skin
                    </h4>
                    <div className="row row-xs">
                        <div className="col-4">
                            <a
                                href="/"
                                className="df-skin df-skin-default active"
                                data-title="default"
                            >
                                df
                            </a>
                            <span className="df-skin-name">Default</span>
                        </div>
                        <div className="col-4">
                            <a
                                href="/"
                                className="df-skin df-skin-deepblue"
                                data-title="deepblue"
                            >
                                <span/>
                                <span/>
                            </a>
                            <span className="df-skin-name">Deep Blue</span>
                        </div>
                        <div className="col-4">
                            <a
                                href="/"
                                className="df-skin df-skin-charcoal"
                                data-title="charcoal"
                            >
                                <span/>
                                <span/>
                            </a>
                            <span className="df-skin-name">Charcoal</span>
                        </div>
                        <div className="col-4 mg-t-15">
                            <a
                                href="/"
                                className="df-skin df-skin-gradient1"
                                data-title="gradient1"
                            >
                                <span/>
                                <span/>
                            </a>
                            <span className="df-skin-name">Gradient 1</span>
                        </div>
                    </div>
                </div>

                {/*<StyledCard $hasBorder>*/}
                {/*    <StyledLabel>Navigation Layout</StyledLabel>*/}
                {/*    <Row gutters={10}>*/}
                {/*        <Col xs={6} sm={6}>*/}
                {/*            <StyledNavLink href={`${location}/`}>*/}
                {/*                Horizontal*/}
                {/*            </StyledNavLink>*/}
                {/*        </Col>*/}
                {/*        <Col xs={6} sm={6}>*/}
                {/*            <StyledNavLink*/}
                {/*                href={`${location}/classic-plus/dashboard-one`}*/}
                {/*            >*/}
                {/*                Vertical*/}
                {/*            </StyledNavLink>*/}
                {/*        </Col>*/}
                {/*    </Row>*/}
                {/*</StyledCard>*/}

                {/*<div className="pd-y-20 bd-t">*/}
                {/*    <h4 className="tx-sans tx-10 tx-uppercase tx-bold tx-spacing-1 tx-color-02 mg-b-15">*/}
                {/*        Font Family Base*/}
                {/*    </h4>*/}
                {/*    <div className="row row-xs">*/}
                {/*        <div className="col-7">*/}
                {/*            <a*/}
                {/*                href="/"*/}
                {/*                id="setFontBase"*/}
                {/*                className="btn btn-xs btn-outline-secondary active"*/}
                {/*            >*/}
                {/*                IBM Plex Sans*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*        <div className="col-5">*/}
                {/*            <a*/}
                {/*                href="/"*/}
                {/*                id="setFontRoboto"*/}
                {/*                className="btn btn-xs btn-outline-secondary"*/}
                {/*            >*/}
                {/*                Roboto*/}
                {/*            </a>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </StyledSettingsBody>
        </StyledSettings>
    );
};

export default SettingsCard;


export const ToastWithTitle = ({title, message}: { title: string, message: string }) => (
    <div>
        <h6 className={'text-primary m-0 p-0 pb-2'}> {title}</h6>
        <p className={"m-0"}> {message}</p>
    </div>
)


