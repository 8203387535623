import React, {forwardRef, Fragment, useImperativeHandle, useState} from 'react';
import loadingAnimation from '@doar/shared/lottie/loading.json'
import failedAnimation from '@doar/shared/lottie/success.json'
import successAnimation from '@doar/shared/lottie/success.json'
import idleAnimation from '@doar/shared/lottie/success.json'
import Lottie from "react-lottie-player";
import {Col} from "@doar/components";
import {StyledCenteredDiv} from "./style";


export type LoadingType = {
    loadingState: "LOADING" | "SUCCESS" | "FAILED" | "IDLE"
    successTitle?: string,
    successMessage?: string,
    failTitle?: string,
    failMessage?: string,
    loadingTitle: string,
    loadingMessage: string,
    canRetry?: boolean,
    canCancel?: boolean,
    canContinue?: boolean,
    onRetryClicked?: () => void,
    onContinueClicked?: () => void,
    onCancelClicked?: () => void,
    idleTitle?: string,
    idleMessage?: string,
    cancelText?: string,
    retryText?: string,
    continueText?: string,
}

export const loadingStates = {LOADING: "LOADING", SUCCESS: "SUCCESS", FAILED: "FAILED", IDLE: "IDLE"}

export const Loading = forwardRef(({
                                       loadingState,
                                       successTitle,
                                       successMessage,
                                       failTitle,
                                       failMessage,
                                       loadingTitle,
                                       loadingMessage,
                                       canRetry,
                                       canCancel,
                                       onRetryClicked,
                                       onContinueClicked,
                                       onCancelClicked,
                                       canContinue,
                                       cancelText,
                                       retryText,
                                       continueText,
                                       idleTitle="Nothing to do",
                                       idleMessage="Theres no action selected. Kindly close and retry if this is a mistake"
                                   }: LoadingType, ref) => {
    const [state, setState] = useState<string>(loadingState ?? loadingStates.LOADING);

    useImperativeHandle(ref, () => ({

        isSuccess: () => {
            setState(loadingStates.SUCCESS)
        },
        isFailed: () => {
            setState(loadingStates.FAILED)
        },
        isLoading: () => {
            setState(loadingStates.LOADING)
        },
        isIdle: () => {
            setState(loadingStates.IDLE)
        }

    }));

    function getMessageAndTitle(title: string, message: string) {
        return (<div className="text-center pb-4"> {
                title ? <h4>{title}</h4> : null}
                {message ? <h5 className="text-muted small">{message}</h5> : null}
            </div>
        )
    }

    return (
            <Col textAlign={"center"} p={3}>
                <StyledCenteredDiv style={{height: 300}}>
                    <Lottie
                        loop={state === loadingStates.LOADING}
                        play={true}
                        animationData={state === loadingStates.FAILED ? failedAnimation : (state === loadingStates.SUCCESS ? successAnimation : (state === loadingStates.IDLE ? idleAnimation : loadingAnimation))}
                        direction={1}
                        segments={state === loadingStates.FAILED ? [308, 360] : (state === loadingStates.SUCCESS ? [188, 240] : [0, 120])}
                        style={{
                            width: state === loadingStates.LOADING ? 300 : 130,
                            height: state === loadingStates.LOADING ? 300 : 130,
                            alignSelf: 'center'
                        }}
                    />
                {getMessageAndTitle(
                    successTitle && state === loadingStates.SUCCESS ? successTitle : (failTitle && state === loadingStates.FAILED ? failTitle : (loadingTitle && state === loadingStates.LOADING ? loadingTitle : idleTitle)),
                    successMessage && state === loadingStates.SUCCESS ? successMessage : (failMessage && state === loadingStates.FAILED ? failMessage : (loadingMessage && state === loadingStates.LOADING ? loadingMessage : idleMessage))
                )}
                <div className="loading-buttons">
                    {((canContinue ?? false) && state === loadingStates.SUCCESS) ?
                        <button type="button" className="btn btn-primary btn-sm m-2"
                                onClick={() => onContinueClicked ? onContinueClicked() : null}>{continueText ?? "Add More"}</button> : null}
                    {((canRetry ?? false) && state === loadingStates.FAILED) ?
                        <button type="button" className="btn btn-primary btn-sm m-2"
                                onClick={() => onRetryClicked ? onRetryClicked() : null}>{retryText ?? "Retry"}</button> : null}
                    {((canCancel ?? false) && (state === loadingStates.LOADING || state === loadingStates.FAILED)) ?
                        <button type="button" className="btn btn-danger btn-sm m-2"
                                onClick={() => onCancelClicked ? onCancelClicked() : null}>{cancelText ?? "Cancel"}</button> : null}
                </div>

                </StyledCenteredDiv>
            </Col>
    );
});
