import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState} from 'react';
import LeafletMap from "./LeafletMap";
import ContentShimmer from "react-content-shimmer";
import {Icon} from "@iconify/react";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Col,
    Row,
    Anchor, Card, Badge, Button
} from "@doar/components";
import {
    RentPaymentType, ChargeType
} from "../../graphql/generated/tenant-schema";
import {defaultSearchLocation, houseFeatures} from "../settings/data";

const TenantPropertyOverview = forwardRef((props, ref) => {

    useImperativeHandle(ref, () => ({}));

    const leafletMap = useRef()

    function onLocationSelected(latlng) {
        console.log()
    }

    return (
        <Fragment>
            <Col className=" d-flex flex-wrap p-0">
                <Col xs={12} className="property-section property-description">
                    <h6 className={`property-section-header`}>Property Description
                        <div className="element-actions d-none d-sm-block">
                            <Button> <i className="os-icon"></i>
                                <span>share</span>
                            </Button>
                        </div>
                    </h6>
                    <div className="property-section-body">
                        {(!props?.propertyDetails || Object.keys(props?.propertyDetails).length <= 0) ?
                            <ContentShimmer size={{height: 30, width: "100%"}}
                                            style={{marginTop: 10, borderRadius: 10}}/> :
                            <p>{props?.propertyDetails?.description ?? 'Description not set'}</p>}
                    </div>
                </Col>
                <Col xs={12} md={6} className="property-section property-charges">
                    <h6
                        className={`property-section-header`}>Extra
                        Charges
                        <div className="element-actions d-none d-sm-block">

                        </div>
                    </h6>
                    <div className="property-section-body">
                        {((!props?.propertyDetails || Object.keys(props?.propertyDetails).length <= 0) ?
                            <ContentShimmer size={{height: 30, width: "100%"}}
                                            style={{marginTop: 10, borderRadius: 10}}/> :
                            <Fragment>
                                {props?.propertyDetails?.houseChargesVersions?.reduce((a, b) => a.createdAt > b.createdAt ? a : b, {})?.charges?.map((charge, index) =>
                                    <Col key={index} className="property-charges-details">
                                        <span className="mr-2 m-0 font-weight-bold">{`${charge?.chargeType}: `}</span>
                                        <Badge color={"warning"} className="small font-weight-bold" style={{
                                            height: "fit-content",
                                            fontSize: "0.5rem"
                                        }}> {charge?.paymentType}</Badge>
                                        {/*<span className="badge badge-warning badge-pill small" style={{height:"fit-content"}}> {charge?.rate}</span>*/}

                                        <div className="d-flex flex-column ml-1"><p
                                            className="font-weight-bold">{charge?.paymentType == RentPaymentType.Variable ? `Variable per ${charge?.rate}` : (charge?.chargeType == ChargeType.Rent ? `Ksh. ${props?.propertyDetails?.price?.formatCurrency()}` : `Ksh. ${parseInt(charge?.amount)?.formatCurrency()} per ${charge?.rate}`)}</p>
                                        </div>
                                    </Col>
                                )}
                                <div className="property-note">
                                    <h6>Always communicate
                                        through KejaRent Contacts</h6>
                                    <p>To protect your payment, never transfer money or communicate with contacts outside our website or app.</p>
                                    <p>We have vetted every landlord to provide you with the safest experience</p>
                                </div>
                            </Fragment>)}
                    </div>
                </Col>
                <Col xs={12} md={6} className="property-section property-features">
                    <h6 className={`property-section-header`}>Facts
                        & Features
                        <div
                            className="element-actions d-none d-sm-block">

                        </div>
                    </h6>
                    <div className="property-section-body ">
                        {<div
                            className="property-details-features">
                            {typeof props?.propertyDetails !== 'undefined' && Object.keys(props?.propertyDetails).length <= 0 ?
                                <ContentShimmer size={{height: 30, width: 40}}
                                                style={{
                                                    marginTop: 10,
                                                    borderRadius: 10
                                                }}/> :
                                Object.entries(props?.propertyDetails?.features ?? {}).map(([featureName, featureValue]) => {
                                    return <div key={featureName}
                                                className={`feature-item font-weight-bold`}
                                                disabled={houseFeatures?.hasOwnProperty(featureName) && featureValue}>
                                        <Icon className={`${featureValue ? 'text-primary' : ''}`} icon={
                                            houseFeatures[featureName]
                                        } style={{width: 20, height: 20}}/>
                                        <span className="w-100 font-bold">{featureName?.toCamelCase()}</span>
                                    </div>;
                                })}
                        </div>
                        }
                    </div>
                </Col>
                <Col xs={12} md={6} className="property-section property-rules">
                    <h6 className={`property-section-header`}>House
                        Rules
                        <div className="element-actions d-none d-sm-block">

                        </div>
                    </h6>
                    <div className="property-section-body">
                        <div>
                            {(!props?.propertyDetails || Object.keys(props?.propertyDetails).length <= 0 ?
                                <ContentShimmer size={{height: 30, width: "100%"}}
                                                style={{marginTop: 10, borderRadius: 10}}/> :
                                <div className="col-md-12">
                                    <ul>
                                        {props?.propertyDetails?.houseRules?.map((rule, index) => <li key={index}
                                                                                                      className="">
                                            <p className="m-0 ">{rule ?? 'No Rule set'}</p>
                                        </li>)}
                                    </ul>
                                    {props?.propertyDetails?.houseRules?.length <= 0 ? <p className="text-center small">
                                        No House rules Added.</p> : null}

                                </div>)}


                        </div>
                    </div>
                </Col>
                <Col xs={12} md={6} className="property-section property-cancellation">
                    <h6 className={`property-section-header`}>Cancellation
                        Policy
                        <div
                            className="element-actions d-none d-sm-block">
                        </div>
                    </h6>
                    <div className="property-section-body">
                        {(!props?.propertyDetails || Object.keys(props?.propertyDetails).length <= 0 ?
                            <ContentShimmer size={{height: 30, width: "100%"}}
                                            style={{marginTop: 10, borderRadius: 10}}/> :
                            <p>{props?.propertyDetails?.cancellationPolicy ?? 'No cancellation policy defined'}</p>)}

                    </div>
                </Col>
                <Col xs={12} className="property-section property-location">
                    <h6 className={`property-section-header`}>Location
                        Info
                        <div
                            className="element-actions d-none d-sm-block">
                        </div>
                    </h6>
                    <div className="property-section-body">
                        <LeafletMap ref={leafletMap}
                                    onLocationSelected={(latlng) => onLocationSelected(latlng)}
                                    height={500}
                                    disableZoom
                                    disableClick
                                    initialLocation={{
                                        lat: props?.propertyDetails?.lat ?? props?.propertyDetails?.apartment?.lat ?? defaultSearchLocation.lat,
                                        lng: props?.propertyDetails?.lng ?? props?.propertyDetails?.apartment?.lng ?? defaultSearchLocation.lng,
                                    }}
                        />
                    </div>
                </Col>
            </Col>
        </Fragment>


    );

});

export default TenantPropertyOverview;
