import styled, {themeGet} from "@doar/shared/styled";
import {css} from "styled-components";
import {device} from "@doar/shared";
import {Form} from "react-bootstrap";
import { Modal } from "@doar/components";


export const StyledUserImage = styled(({...rest}) => <div {...rest} />)`
    position: relative;

    .icon-wrapper {
        position: absolute;
        bottom: 0;
        right: 0;

        #file {
            display: none;
        }
    }

`;

export const StyledPageWrapper = styled(({...rest}) => <div {...rest} />)`
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;

    .page-header {
        &.listing-page {
            margin-left: 0;
            padding-left: 20px;
            width: 100%;
            box-shadow: 0 0 20px rgba(89, 102, 122, .1);
            max-width: 100vw;
            position: relative;
            top: 0;
            transition: .5s;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            font-family: Poppins, sans-serif;
            background-color: ${themeGet("colors.background")};

            ${(props) =>
                    props.theme.name !== "dark" &&
                    css`
                        background-color: ${themeGet("colors.white")};
                    `}
            .logo-wrapper {
                padding-right: 15px !important;
                border-right: 1px solid rgba(0, 0, 0, .1);

                a {
                    & > *:not(img) {
                        ${device.small} {
                            display: flex;
                        }

                        display: none;
                    }

                    font-family: Poppins, sans-serif !important;
                    font-weight: 900;

                    span {
                        //font-family: Poppins, sans-serif !important;
                        //font-weight: 900;
                    }
                }

            }


            .header-wrapper {
                align-items: center;
                height: 80px;
                display: flex;
                padding-right: 1.5rem !important;
                padding-left: 0.5rem !important;
                position: relative;
                width: 100%;

                .right-header {
                    display: flex;
                }

            }

        }

        .right-header {
            .nav-menus {
                align-items: center;
                display: flex;
                justify-content: flex-end;

                li {
                    display: flex;
                    align-items: center;
                    padding: 10px;
                }


                .onhover-dropdown {
                    cursor: pointer;
                    position: relative;
                    display: none;

                    ${device.small} {
                        display: flex;
                    }


                    &:hover {
                        .onhover-show-div {
                            box-shadow: 0 0 20px rgba(#59667a, 0.1);
                            left: 0;
                            border-radius: 5px;
                            opacity: 1;
                            overflow: hidden;
                            -webkit-transform: translateY(0);
                            transform: translateY(0);
                            visibility: visible;
                        }
                    }
                }

                .onhover-show-div {
                    top: 80px;
                    position: absolute;
                    z-index: 8;
                    background-color: white;
                    transition: all linear 0.3s;
                    box-shadow: 0 0 20px rgba(#59667a, 0.1);
                    transform: translateY(30px);
                    opacity: 0;
                    visibility: hidden;
                    left: 0;

                    li {
                        a {
                            svg {
                                margin-top: 0 !important;

                                path,
                                line {
                                    //color: $theme-body-font-color !important;
                                }
                            }
                        }
                    }
                }


                .maximize {
                    display: none;

                    ${device.small} {
                        display: flex;
                    }
                }

                .profile-nav {
                    padding: unset !important;

                    ${device.small} {
                        display: unset;
                        padding: 0.5rem !important;
                    }

                    span {
                        display: none;

                        ${device.small} {
                            padding: 0.5rem !important;
                        }
                    }
                }

            }

        }
    }

    .filter-header {
        padding: 10px;
        width: 100%;
        max-width: 100vw;
        top: 0;
        transition: .5s;
        display: flex;
        flex-direction: row;
        flex-flow: wrap;
        background-color: ${themeGet("colors.background")};

        ${(props) =>
                props.theme.name !== "dark" &&
                css`
                    background-color: ${themeGet("colors.white")};
                `}
        .results-filter {
            display: none;
            width: unset;
            flex-direction: column;
            padding-left: 0.5rem !important;
            padding-right: 1rem !important;
            align-items: start;
            white-space: nowrap;
            border-right: 1px solid ${themeGet("colors.border")};

            ${(props) => props.theme.name !== "dark" && css`
                border-right: 1px solid rgba(0, 0, 0, 0.1);
            `}
            ${device.small} {
                display: flex
            }
        }


        .dropdown {

            & > div {
                display: flex;
                padding-left: 0.5rem !important;
                align-items: center;
                white-space: nowrap;
                border-right: 1px solid ${themeGet("colors.border")};
                ${(props) => props.theme.name !== "dark" && css`
                    border-right: 1px solid rgba(0, 0, 0, 0.1);
                `}
            }

            .dropdown-header {
                & > div {
                    display: flex;
                    justify-content: space-between;

                    .clear-button {
                        color: ${themeGet("colors.primary")}
                    }
                }
            }


            &.lease-type-dropdown {
                display: flex;
                flex-flow: wrap;

                .dropdown-content {
                    background-color: ${themeGet("colors.background")};
                }

                .dropdown-item {
                    display: block;
                    width: 100%;
                    padding: 0.5rem 1.5rem;
                    clear: both;
                    font-weight: 400;
                    color: ${themeGet("colors.text3")};
                    text-align: inherit;
                    white-space: nowrap;
                    background-color: ${themeGet("colors.background")};
                    border: 0;
                    border-bottom: 1px solid #00000024;

                    font-size: 0.775rem;

                    ${device.medium} {
                        font-size: 0.775rem;
                    }

                    ${device.large} {
                        font-size: 0.875rem;
                    }

                }

            }


            &.house-type-dropdown {
                display: flex;
                flex-flow: wrap;

                .dropdown-item.house-type-dropdown-item {
                    display: flex;

                    & > div {
                        padding: 10px;
                        display: flex;
                        flex-direction: column;
                    }
                }

            }


            .dropdown-menu {
                background-clip: padding-box;
                border: 1px solid rgba(0, 0, 0, .15);
                border-radius: 0.25rem;
                color: #212529;
                display: none;
                float: left;
                font-size: 1rem;
                left: 0;
                list-style: none;
                margin: 0.125rem 0 0;
                min-width: 10rem;
                padding: 0.5rem;
                position: absolute;
                text-align: left;
                top: 100%;
                z-index: 1000;
                background-color: #fff;

                ${(props) =>
                        props.theme.name === "dark" &&
                        css`
                            background-color: ${themeGet("colors.gray900")};
                        `}
                &.show {
                    display: unset;
                }
            }
        }


    }

    .dropdown {
        .dropdown-menu {
            box-shadow: 0px 0px 4px 0px #0000001c;
        }
    }

    .page-body-wrapper {
        //height: 100%;
        flex-grow: 1;
        overflow: hidden;
        display: flex;
        flex-direction: column;

        & > div {
            &.vertical-card-view-wrapper {
                padding-top: 30px;
            }
            flex-grow: 1;
            //height: 100%;

            .vertical-grid-card-view {
                height: 100%;

                & > div {
                    height: 100%;
                }

            }

        }

        .apartment-details {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            top: 0;
            background-color: white;
            z-index: 10;
            transition-duration: 5s;
            overflow-y: scroll;

            .close-button {
                position: absolute;
                top: 5px;
                right: 5px;
            }
        }

        .property-details {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            top: 0;
            z-index: 10;
            transition-duration: 5s;
            overflow: auto;
            background-color: ${themeGet("colors.background")};

            ${(props) =>
                    props.theme.name !== "dark" &&
                    css`
                        background-color: ${themeGet("colors.white")};
                    `}
            .close-button {
                position: absolute;
                top: 5px;
                right: 5px;
            }
        }

        .property-note {
            background-color: ${themeGet('colors.background')};
            color: #383328;
            font-size: .81rem;
            margin: 20px 0;
            padding: 20px;
            border-radius: 10px;
        }

        .footer {
            position: fixed;
            bottom: 0;
            width: 100%;
        }
    }


`;
export const StyledSearchInputForm = styled(({...rest}) => <Form {...rest} />)`
  flex-grow: 1;
  height: 100%;
  /* padding-right: 20px; */

  .map-mode-selector {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: ${themeGet("colors.primary")};
    cursor: pointer;
  }


  /**=====================
87. typahead-search  CSS Start
==========================**/

  .Typeahead {
    width: 100%;
    height: 100%;

    * {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;

      &:before {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }

      &:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
      }
    }


    &.is-landing-page {
      .location-search {
        position: unset !important;
      }

      .u-posRelative {
        position: unset !important;
      }

      .search-menu {
        left: 0;
        right: 0;
      }

    }

    .tt-hint {
      display: none !important;
    }


    &.location-search {

      &.full {
        input {
          width: 100%;
          height: 100%;
          border: none;
          font-size: 0.775rem;

          ${device.medium} {
            font-size: 0.775rem;
          }

          ${device.large} {
            font-size: 0.875rem;
          }

          &:focus {
            box-shadow: none;
          }
        }
      }

    }

    .search-menu {
      display: flex;
      flex-flow: wrap;
      box-shadow: 2px 10px 8px 0px #0000004d;
      border-radius: 0 0px 5px 5px;
      background-color: ${themeGet("colors.dark")};

      ${(props) => props.theme.name !== "dark" &&
              css`
                background-color: ${themeGet("colors.white")};
              `}
      .search-results {
        overflow: hidden;
        display: flex;
        flex-direction: column;
        padding: 0;

        & > div {
          flex-grow: 1;
          overflow-y: scroll;

          .dropdown-item {
            div {
              height: 39px;
              border-bottom: 1px solid ${themeGet("colors.gray900")};

              ${(props) => props.theme.name !== "dark" &&
                      css`
                        border-bottom: 1px solid ${themeGet("colors.gray200")};
                      `}
            }
          }

        }
      }


      .select-results {
        display: flex;
        flex-direction: column;
        padding: 0;
        max-height: 300px;

        .select-results-top {
          display: flex;
          padding: 0.25rem !important;

          & > div {
            display: flex;

            span {
              &.badge {
                margin-left: 5px;
              }
            }

          }

        }

        .select-results-list {
          display: flex;
          overflow: hidden;
          padding: 0.25rem !important;

          & > div {
            overflow: auto;
            padding: 0;
          }

          li {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid #dee2e6 !important;
            padding: 0.25rem !important;
          }

        }

      }


    }

    & > div {
      display: flex;
      position: relative;
      z-index: 2;
      background-color: ${themeGet("colors.background")};

      ${(props) => props.theme.name !== "dark" &&
              css`
                background-color: ${themeGet("colors.white")};
              `}
      &:not(.search-menu) {
        justify-content: center;
        align-items: center;
      }
    }
  }

  .u-cf {
    &:before {
      content: " ";
      display: table;
    }

    &:after {
      content: " ";
      display: table;
      clear: both;
    }
  }

  .u-hidden {
    display: none !important;
  }

  .u-posRelative {
    position: relative;
    height: 100%;
  }

  .Typeahead-spinner {
    position: absolute;
    top: 14px;
    right: 18px;
    display: none;
    color: #c5c5c5;
  }

  .ProfileCard-avatar {
    svg {
      color: #7e7c7c !important;
      width: 18px;
      height: 18px;

      path,
      polyline,
      polygon,
      line {
        color: #7e7c7c !important;
      }
    }
  }

  .Typeahead-hint {
    width: 100%;
    padding: 5px 8px;
    font-size: 24px;
    line-height: 30px;
    border: 1px solid #024e6a;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    border-radius: 8px;
    position: absolute;
    top: 0;
    left: 0;
    color: #ccd6dd;
    opacity: 1;
  }

  .Typeahead-menu {
    position: absolute;
    top: 100%;
    padding: 12px;
    z-index: 100;
    display: none;
    width: 100%;
    max-height: 300px;
    overflow: auto;
    background-color: #ffffff;
    border-radius: 0;
    box-shadow: 0px 0px 0px 1px green;
    -webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  }

  .Typeahead-menu.is-open {
    display: block;
  }

  .Typeahead-selectable {
    cursor: pointer;

    + {
      .Typeahead-selectable {
        border-top: 1px solid #ccd6dd;
      }
    }
  }

  .ProfileCard {
    position: relative;
    padding: 8px;
    border: none !important;

    &:hover {
      .ProfileCard-avatar {
        border-color: #f5f8fa;
      }

      color: #414345;
      background: #f8f8f8;

      .ProfileCard-screenName {
        color: #fff;
      }

      .ProfileCard-stat-label {
        color: #fff;
      }
    }
  }

  .ProfileCard-avatar {
    position: absolute;
    border-radius: 5px;
  }

  .ProfileCard-details {
    padding-left: 30px;
    float: left;
    width: 100%;
    display: block;
  }

  .ProfileCard-realName {
    display: block;

    a,
    span {
      color: $theme-body-font-color;
    }
  }

  .ProfileCard-screenName {
    display: inline-block;
    color: #8899a6;
  }

  .ProfileCard-description {
    margin-top: 5px;
    font-size: 14px;
    line-height: 18px;
  }

  .ProfileCard-stats {
    float: right;
    text-align: right;
  }

  .ProfileCard-stat {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;

    + {
      .ProfileCard-stat {
        margin-left: 5px;
      }
    }
  }

  .ProfileCard-stat-label {
    color: #8899a6;
    font-weight: 500;
  }

  .ProfileCard.is-active {
    color: #fff;
    background: #55acee;

    .ProfileCard-screenName {
      color: #fff;
    }

    .ProfileCard-stat-label {
      color: #fff;
    }
  }

  .EmptyMessage {
    position: relative;
    padding: 10px;
    font-size: 14px;
    line-height: 30px;
    text-align: center;
  }

  .Typeahead-spinner {
    width: 1rem;
    height: 1rem;
  }

  .starred {
    color: orange;
  }

  /**=====================
       87. typahead-search CSS Ends
  ==========================**/

  & > div {
    display: flex;
    height: 100%;
    width: 100%;
  }
`;


export const StyledPropertyListingDetails = styled(({...rest}) => <div {...rest} />)`
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 2rem;
    height: fit-content;
    background-color: ${themeGet('colors.background')};

    & > div {
        height: 100%;

        .property-listing-details-wrapper {
            display: flex;
            justify-content: center;
            flex-flow: wrap;
            padding: 0;
            margin: 0;

            .main_slide {
                cursor: pointer;
                margin-bottom: 10px;

                img {
                    border-radius: 10px;
                }
            }

            .thumb_slide {
                img {
                    border-radius: 10px;
                }
            }

            .main-slide-overlay {
                pointer-events: none;
                height: -webkit-fill-available;
                position: absolute;
                top: 0;
                width: 100%;

                .main-slide-overlay-top {
                    position: absolute;
                    display: flex;
                    justify-content: end;
                    width: 100%;
                    padding-left: 0.5rem !important;
                    align-items: center;


                    .vr_badge {
                        background-color: rgba(0, 0, 0, .43);
                        border-radius: 30px;
                        color: #fff;
                        font-size: 75%;
                        padding-right: 10px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin-right: 0.5rem !important;
                    }
                }

                .main-slide-overlay-bottom {
                    position: absolute;
                    display: flex;
                    flex-direction: column;
                    justify-content: end;
                    width: 100%;
                    padding-left: 1rem !important;
                    align-items: start;
                    background: linear-gradient(transparent, rgba(0, 0, 0, .62));
                    border-radius: 0 0 20px 20px;
                    color: #fff;

                    h5, h6 {
                        color: #fff;
                    }

                    & > div {
                        display: flex;

                        & > div {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            align-items: center;
                            margin: 0.25rem !important;
                        }
                    }
                }


            }

            .house-details-wrapper {
                padding: 0;
                text-align: left;
                display: flex;
                flex-direction: column;
                padding-right: 0.75rem !important;
                padding-left: 0.75rem !important;
                padding-bottom: 0.75rem !important;

                .house-details {
                    width: 100%;
                    align-items: start;
                    text-align: left;
                    padding-bottom: 0.25rem !important;
                    border-bottom: 1px solid #0000001c;

                    .house-room-details {
                        margin: 0px;

                        & > div {
                            padding: 5px;
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            justify-content: center;

                            & h6 {
                                margin-bottom: 0px;
                            }
                        }
                    }

                }

                .price-details {
                    width: 100%;
                    align-items: start;
                    text-align: left;
                    padding: 0.75rem !important;
                    border-bottom: 1px solid #0000001c;
                    display: flex;
                    align-items: center;

                    & > * {
                        margin: 0px;
                        margin-right: 5px;
                    }

                }

                .property-details-features {
                    width: 100%;
                    align-items: start;
                    text-align: left;
                    margin-top: 0.75rem;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                    flex-flow: wrap;
                    padding: 0.5rem;


                    .feature-item {
                        display: flex;
                        align-self: start;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 5px;

                        &[disabled] {
                            color: ${themeGet('colors.gray300')};
                        }

                    }

                }

                .property-details-actions {
                    display: flex;
                    align-items: start;
                    width: 100%;
                    text-align: left;
                    justify-content: center;
                    flex-flow: wrap;

                    .btn-group {
                        width: 100%;
                        display: flex;
                        align-items: center;

                        button {
                            display: flex;
                            align-items: center;
                            flex-direction: column;

                            ${device.small} {
                                flex-direction: row;
                            }

                            ${device.medium} {
                                flex-direction: column;
                            }

                            ${device.large} {
                                flex-direction: row;
                            }

                        }
                    }


                }

            }
        }

        .property-single {
            & > div {
                display: flex;
                flex-flow: wrap;

                .property-section {
                    margin: 30px 0;

                    .property-section-header {
                        border-bottom: 1px solid rgba(0, 0, 0, .1);
                        border-top: 1px solid rgba(0, 0, 0, .1);
                        font-weight: 700;
                        letter-spacing: 1px;
                        padding: 10px 0;
                        position: relative;
                        text-transform: uppercase;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                    }

                    .property-section-body {


                        .property-details-features {
                            width: 100%;
                            align-items: start;
                            text-align: left;
                            margin-top: 0.75rem;
                            display: flex;
                            flex-direction: row;
                            justify-content: space-around;
                            flex-flow: wrap;
                            padding: 0.5rem;


                            .feature-item {
                                display: flex;
                                align-self: start;
                                flex-direction: column;
                                justify-content: center;
                                align-items: center;
                                padding: 5px;

                                &[disabled] {
                                    color: ${themeGet('colors.gray300')};
                                }

                            }

                        }

                    }

                }

            }
        }

        .property-charges {

            .property-section-body {
                .property-charges-details {
                    display: flex;
                    align-items: center;
                    padding: 0.25rem;

                    & > div, span {
                        margin-right: 0.25rem !important;
                    }

                }

            }

        }

    }

    .login-card {
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        //background: url('../../images/login/login_bg.jpg');
        background-position: center;
        padding: 30px 0;

        .logo {
            display: block;
            margin-bottom: 30px;
            text-align: center;
        }

        .btn-showcase {
            .btn {
                line-height: 1;
                padding: 10px 15px;
                margin: 0;

                & + .btn {
                    margin-left: 5px;
                }

                svg {
                    height: 16px;
                    vertical-align: bottom;
                }

                .icon-social-google {
                    margin: 0 6px;
                }
            }
        }
    }
`;
export const StyledModalCarousel = styled(({...rest}) => <Modal {...rest} />)`
    background: transparent;

    .modal-dialog {
        .modal-content {
            background-color: transparent;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            position: absolute;
            justify-content: center;
            align-items: end;
            .close-button-nav {
                pointer-events: all;
                cursor: pointer;
                background: white;
                padding: 10px;
                border-radius: 30px;
                height: 40px;
                width: 40px;
                position: absolute;
                right: 0;
                top: 0;
                display: flex;
                align-items: center;
                z-index: 10;
                box-shadow: 0px 0px 12px 0px #00000096;

            }

        }
    }
`;
