import React, {Fragment, useState} from 'react';
import Lottie from "react-lottie-player";
import likeAnimation from "@doar/shared/lottie/like.json";
import houseStub from "@doar/shared/images/house_stub.png";
import {Icon} from "@iconify/react";
import bedDoubleIcon from "@iconify/icons-uil/bed-double";
import bathIcon from "@iconify/icons-uil/bath";
import chatIcon from "@iconify/icons-uil/comments-alt";
import {useLikeHouseMutation} from "../../graphql/generated/tenant-schema";
import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Label,
    Col,
    Row,
    Anchor, Card, Badge, Nav, NavLink, HRLine, Modal
} from "@doar/components";
import {StyledPropertyItem} from "./style";
import {StyledCard} from "../settings/style";
import {handleErrorResponse} from "../settings/data";
import {StyledBody, StyledHeader, StyledTitle} from "../templates/apps/mail/compose-mail/style";
import {X} from "react-feather";
import Editor from "../templates/apps/mail/compose-mail/editor";

const PropertyItem = ({data, onItemClick}, props) => {

    const [isLiked, setIsLiked] = useState(data.house?.myInteractions?.liked);
    const openChat = (e) => {
        e.preventDefault();
        e.stopPropagation();
        return props?.openChat(data.house);
    }


    const [likeHouse] = useLikeHouseMutation({
        variables: {
            houseId: data.house?.id,
        },
        onCompleted: () => {
            setIsLiked(!isLiked);
        },
        onError: (error) => {
            handleErrorResponse(error)
            setIsLiked(isLiked);
        }
    })
    return (
        <Fragment>
            <StyledPropertyItem onClick={(e) => onItemClick(e)}>
                <StyledCard $isPropertyItem className={`property-item p-1 m-1 ${props?.row ? 'horizontal' : ''}`}>
                    <Col p={0} pb={props?.row?0:1} m={0}>
                        <img
                            alt={""}
                            style={{}}
                            src={data?.house && data.house?.media?.thumbnails?.length > 0 ? (data.house?.media?.thumbnails[0]) : houseStub}
                            onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src=houseStub;
                            }}
                        />
                        <div
                            className={`property-item-actions position-absolute d-flex flex-row justify-content-between w-100 pl-2 align-items-center`}
                            style={{top: 0, height: 40}}>
                            {data.count > 1 ? <Badge color={'secondary'} pill style={{height: "fit-content", backgroundColor:'#f53d68'}}>{data.count} Houses</Badge> :
                                <div></div>}

                            <Lottie
                                loop={false}
                                play={true}
                                animationData={likeAnimation}
                                direction={isLiked ? 1 : -1}
                                segments={isLiked ? [1, 20] : [0, 20]}
                                style={{
                                    width: 50,
                                    height: 50,
                                    alignSelf: 'center'
                                }}
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation()
                                    likeHouse()
                                }}
                            />
                        </div>
                    </Col>
                    <Col p={0} pl={props?.row ?2:0} pt={props?.row ?0:1} className={` ${props?.row ? 'horizontal' : ''}`}>
                        <Row
                            className={`pricing-details  d-flex flex-row ${props?.row ? 'justify-content-start' : 'justify-content-around'} align-items-center w-100`}>
                            <h6 className="m-0 price-text">Ksh. {data.house.price.formatCurrency()}</h6>
                            <Badge color={'primary'} >{`${data.house.rate}`.toCamelCase()}</Badge>
                        </Row>
                        <span
                            className="text-muted p-0 address-text">{data.house.address ?? ((data.house.district ?? data.house?.apartment.district) + ', ' + (data.house.constituency ?? data.house?.apartment.constituency) + ', ' + (data.house.county ?? data.house?.apartment.county))}</span>
                        <div className="d-flex flex-row justify-content-around align-items-center w-100 p-1 card-details">
                            <Col
                                className={`col-4 d-flex  ${props?.row ? 'flex-row p-0 ml-1' : 'flex-column'} justify-content-center align-items-center`}>
                                <Icon icon={bedDoubleIcon} style={{color: "grey"}} className="details-icon"/>
                                <Row className="d-flex">
                                    <span className="m-0 font-weight-bold">{data.house.bedroom}</span>
                                    <span className="text-muted"> beds</span></Row>
                            </Col>
                            <Col className={`col-4 d-flex ${props?.row ? 'flex-row p-0 ml-1' : 'flex-column'} justify-content-center align-items-center`}>
                                <Icon icon={bathIcon} style={{color: "grey"}} className="details-icon"/>
                                <Row className="d-flex">
                                    <span className="m-0 font-weight-bold">{data.house.bathroom}</span>
                                    <span className="text-muted"> baths</span></Row>
                            </Col>
                            <Col
                                className={`col-4 d-flex ${props?.row ? 'flex-row p-0 ml-1' : 'flex-column'} justify-content-center align-items-center cursor-pointer`}
                                onClick={(e) => openChat(e)}>
                                <Icon icon={chatIcon} style={{color: "grey"}} className="details-icon"/>
                                <div className="d-flex"><span className="text-muted"> Chat</span></div>
                            </Col>
                        </div>
                    </Col>
                </StyledCard>
            </StyledPropertyItem>
        </Fragment>
    );
}
export default PropertyItem;
