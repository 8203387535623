import styled, {device, themeGet, css} from "@doar/shared/styled";
import {Anchor} from "@doar/components";

export const StyledLogo = styled(({...rest}) => <Anchor {...rest} />)`
  font-weight: 700;
  font-size: 22px;
  letter-spacing: -1px;
  color: inherit;
  display: flex;
  align-items: center;
  position: relative;
  color: ${themeGet("colors.cod")};
  margin-top: -4px;

  span {
    display: inline-block;
    font-weight: 400;
    color: ${themeGet("colors.primary")};
  }

  .img-fluid {
    width: 35px;
    height: 35px;
    max-width: unset;
    padding: 0.25rem;
  }

  ${device.small} {
    font-size: 22px;
  }

  ${device.large} {
    font-size: 24px;
  }

  ${device.xxlarge} {
    font-size: 30px;
  }

  ${({theme}) =>
          theme.name === "dark" &&
          css`
            color: ${themeGet("colors.white")};
          `}
`;
