import React, {forwardRef, Fragment, useEffect, useImperativeHandle, useRef, useState} from 'react';
import * as protomaps from "protomaps"
import {MapContainer, TileLayer, Marker, Popup, GeoJSON, useMapEvent, useMapEvents} from 'react-leaflet'
import {
    fetchAddressDetailsByLocation,
    fetchLocationAutocomplete,
    fetchLocationBoundary
} from "./api";
import L from 'leaflet';
import {AsyncTypeahead} from "react-bootstrap-typeahead";
import {renderToString} from "react-dom/server";
import {GroupBy} from "@ysfe/array-groupby";
import {
    BoundaryType,
    HouseGroupType,
    HouseStatus,
    useGetGroupedHousesLazyQuery
} from "../../graphql/generated/tenant-schema";
import {defaultSearchLocation, handleAxiosResponse, HouseTypes} from "../settings/data";
import ProfilePicture from '@doar/shared/images/User.jpg'
import {Col, Text} from "@doar/components";
import houseStub from "@doar/shared/images/house_stub.png";

const LeafletMap = forwardRef((props, ref) => {

    const [map, setMap] = useState(null)
    const [selectedMarkerLocation, setSelectedMarkerLocation] = useState(null)
    const [disappearingMarkerLocation, setDisappearingMarkerLocation] = useState({})
    const [selectedMarkerAddress, setSelectedMarkerAddress] = useState("Fetching Address ...")
    const [isLoading, setIsLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [circleLayers, setCircleLayers] = useState([])
    const [houseLocationCircle, setHouseLocationCircle] = useState([])
    const [boundariesData, setBoundariesData] = useState({})
    const elementRef = useRef(null);
    const boundariesRef = useRef(null);
    const [priceFilter, setPriceFilter] = useState(props?.filters?.priceRange);
    const [houseTypeRangeFilter, setHouseTypeRangeFilter] = useState(props?.filters?.houseType);
    const [leaseTypeFilter, setLeaseTypeFilter] = useState(props?.filters?.leaseType);
    const mapTilerKey = 'rz8g6QAUUNORP9TAmuDa';


    useEffect(() => {
        if (elementRef.current)
            L.DomEvent.disableClickPropagation(elementRef.current);
        if ((selectedMarkerLocation ?? props?.initialLocation) && !props?.loadHouses) {
            setMapView(selectedMarkerLocation ?? props?.initialLocation, true)
        }
    })

    useEffect(() => {
        if (map) {
            if (props?.loadHouses) {
                const boundaryBox = map.getBounds();
                const zoom = map.getZoom();
                if (props?.loadHouses)
                    getGroupedHouses({
                        variables: {
                            country: "Kenya",
                            houseStatus: "VACANT",
                            priceFrom: priceFilter?.min ?? 0,
                            priceTo: priceFilter?.max ?? 10000000,
                            roomsFrom: houseTypeRangeFilter?.min ?? 0,
                            roomsTo: houseTypeRangeFilter?.max ?? 20,
                            boundaryGroup: zoom >= 15 ? "DISTRICT" : (zoom >= 13 && zoom < 15 ? "DISTRICT" : (zoom >= 11 && zoom < 13 ? "CONSTITUENCY" : "COUNTY")),
                            boundingBox:
                                {
                                    bottomLeft: {
                                        "lat": boundaryBox._southWest.lat,
                                        "lng": boundaryBox._southWest.lng
                                    },
                                    topRight: {
                                        "lat": boundaryBox._northEast.lat,
                                        "lng": boundaryBox._northEast.lng
                                    }
                                },
                            groupBy: [HouseGroupType.Price, HouseGroupType.Apartment]

                        }
                    })
            }
            // loadHousesWithinBounds(
            //     map.getBounds(),
            //     priceFilter?.min ?? 0,
            //     priceFilter?.max ?? 0,
            //     createHouseRangeList(),
            //     map.getZoom())
        }
    }, [map, priceFilter, houseTypeRangeFilter]);

    const onClickMap = (event) => {
        setSelectedMarkerLocation(event.latlng)
        if (props?.onLocationSelected)
            props.onLocationSelected(event.latlng)

        if (props?.loadHouses)
            fetchAddressDetailsByLocation(event.latlng).then(async (response) => {
                if (response.ok) {
                    const addressDetails = await response.json();
                    const locationText = (addressDetails?.address?.city
                            ?? addressDetails?.address?.state
                            ?? addressDetails?.address?.region
                            ?? "")
                        + ", "
                        + (addressDetails?.address?.road ?? "")
                        + ", "
                        + (addressDetails?.address?.residential ?? addressDetails?.address?.suburb ?? "");
                    setSelectedMarkerAddress(locationText.replaceAll(", ,", ","));
                }
            }).catch((error) => {
                console.log()
            })
    }
    const handleMoveEnd = (event, bounds, mapZoom) => {
        const boundaryBox = bounds ?? event.target.getBounds();
        const zoom = mapZoom ?? event.target.getZoom();
        if (props?.loadHouses)
            getGroupedHouses({
                variables: {
                    country: "Kenya",
                    houseStatus: HouseStatus.Vacant,
                    priceFrom: priceFilter?.min ?? 0,
                    priceTo: priceFilter?.max ?? 10000000,
                    roomsFrom: houseTypeRangeFilter?.min ?? 0,
                    roomsTo: houseTypeRangeFilter?.max ?? 20,
                    boundaryGroup: zoom >= 15 ? "DISTRICT" : (zoom >= 13 && zoom < 15 ? "DISTRICT" : (zoom >= 11 && zoom < 13 ? "CONSTITUENCY" : (zoom >= 8 ? "COUNTY" : "COUNTRY"))),
                    boundingBox:
                        {
                            bottomLeft: {"lat": boundaryBox._southWest.lat, "lng": boundaryBox._southWest.lng},
                            topRight: {"lat": boundaryBox._northEast.lat, "lng": boundaryBox._northEast.lng}
                        },
                    groupBy: [HouseGroupType.Price, HouseGroupType.Apartment]
                }
            })
    }


    const handleCircleMarkerClick = (event, circleDetails) => {
        if (map && boundariesRef.current) {
            // map.fitBounds(boundariesRef.current.getBounds())
            if (map && boundariesRef.current) {
                map.setView([circleDetails.house?.apartment?.lat ?? circleDetails.house?.lat, circleDetails.house?.apartment?.lng ?? circleDetails.house?.lng])
                setTimeout(() => map.setZoom(map.getZoom() + 2), 200)
            }
        }
    }
    const handleCircleMarkerMouseOver = (event, circleDetail) => {
        if (typeof boundariesData == 'undefined' || Object.keys(boundariesData).length <= 0)
            fetchLocationBoundary(circleDetail).then(async (response) => {
                const boundaryData = await handleAxiosResponse(response);
                setBoundariesData((prevState => boundaryData));
            })
    }
    const handleHouseMarkerMouseOver = (event, circleDetail) => {
        if (event?.target?.bringToFront)
            event.target.bringToFront();
    }
    const handleCircleMarkerMouseOut = (event, circleDetails) => {
        if (typeof boundariesData == 'undefined' || Object.keys(boundariesData).length > 0)
            setBoundariesData(prevState => {
                console.log()
            })

    }
    const handleSearch = (query) => {
        fetchLocationAutocomplete(query).then((response) => {
            if (response.ok) {
                response.json().then((results) => {
                    if (results.hasOwnProperty("features"))
                        results.features.map((feature) => {
                            feature.label = feature.properties.name + "," + (feature?.properties?.city ?? feature?.properties?.state ?? "Unknown city")
                            feature.value = feature?.properties?.city ?? feature?.properties?.state ?? "Unknown city"
                        })
                    setOptions(results.features);
                })
            }

        }).catch((error) => {
            console.log()
        })
    };

    const [getGroupedHouses] = useGetGroupedHousesLazyQuery({
        variables: {
            country: "Kenya",
            houseStatus: HouseStatus.Vacant,
            priceFrom: 0,
            priceTo: 100000,
            boundaryGroup: BoundaryType.Country,
            boundingBox: {
                bottomLeft: {"lat": -1.2435029812737037, "lng": 36.85784339904786},
                topRight: {"lat": -1.217416468911733, "lng": 36.89393520355225}
            },
            groupBy: [HouseGroupType.Price, HouseGroupType.Apartment]
        },

        onCompleted: (result) => {

            if (props?.onHousesFound)
                props.onHousesFound(result.getGroupedHouses)
            setCircleLayers((previousState) => GroupBy(result.getGroupedHouses, (h1, h2) =>

                h1.country == h2.country
                && (h1.county == h2.county)
                && (h1.constituency == h2.constituency)
                && (h1.district == h2.district && (map?.getZoom() < 15 ? true : (h1.house.apartmentId == h2.house.apartmentId)))
            ).map((houseGroup, index) => {
                return {
                    ...houseGroup[0],
                    count: houseGroup.map(h => h.count).reduce((a, b) => a + b, 0),
                    house: {
                        ...(houseGroup[0].house),
                        count: houseGroup.map(h => h.count).reduce((a, b) => a + b, 0),
                    },
                    maxPrice: Math.max(...houseGroup.map(h => h.maxPrice)) ?? 0,
                    minPrice: Math.min(...houseGroup.map(h => h.minPrice)) ?? 0,
                    size: 150,
                    component: <div key={`location_circle_${index}`} className="locationsCircle"></div>
                }
            }))
        },
        onError: (er) => {
            setCircleLayers([])
        }
    });


    function loadHousesWithinBounds(bounds, priceFrom, priceTo, houseType, zoom) {
        // fetchHousesByBounds(bounds, priceFrom, priceTo, houseType, zoom).then(async (response) => {
        //     let houseGroups = (await handleResponse(response)).body;
        //     if (props?.onHousesFound)
        //         props.onHousesFound(houseGroups)
        //
        //
        //     setCircleLayers((previousState) => houseGroups.map((houseGroup, index) => {
        //         return {
        //             county: houseGroup?.countyName,
        //             constituency: houseGroup?.constituencyName,
        //             district: houseGroup?.districtName,
        //             count: houseGroup?.count,
        //             houses: houseGroup?.houses,
        //             lng: houseGroup?.center[0],
        //             lat: houseGroup?.center[1],
        //             size: 150,
        //             component: <div key={πocation_circle_${index}`} className="locationsCircle"></div>
        //         }
        //     }))
        // }).catch((error) => {
        //     setCircleLayers([])
        // })
    }

    useImperativeHandle(ref, () => ({
        setMapView: (latLng) => setMapView(latLng),
        setZoom: (zoom) => setZoom(zoom),
        setHouseLocationCircle: (latLng) => {
            setHouseLocationCircle([latLng])
            setMapView(latLng, true)
        },
        setPriceFilter: (priceFilter) => {
            if (priceFilter) setPriceFilter(prevState => priceFilter);
        },
        setHouseTypeFilter: (houseTypeFilter) => {
            if (houseTypeFilter) setHouseTypeRangeFilter(prevState => houseTypeFilter);
        },
        setLeaseTypeFilter: (leaseTypeFilter) => {
            if (leaseTypeFilter) setLeaseTypeFilter(prevState => leaseTypeFilter);
        },
        reloadHouses: () => {
            if (map) {
                const boundaryBox = map.getBounds();
                const zoom = map.getZoom();
                if (props?.loadHouses)
                    getGroupedHouses({
                        variables: {
                            country: "Kenya",
                            houseStatus: "VACANT",
                            priceFrom: priceFilter?.min ?? 0,
                            priceTo: priceFilter?.max ?? 10000000,
                            roomsFrom: houseTypeRangeFilter?.min ?? 0,
                            roomsTo: houseTypeRangeFilter?.max ?? 20,
                            boundaryGroup: zoom >= 15 ? "DISTRICT" : (zoom >= 13 && zoom < 15 ? "DISTRICT" : (zoom >= 11 && zoom < 13 ? "CONSTITUENCY" : "COUNTY")),
                            boundingBox:
                                {
                                    bottomLeft: {"lat": boundaryBox._southWest.lat, "lng": boundaryBox._southWest.lng},
                                    topRight: {"lat": boundaryBox._northEast.lat, "lng": boundaryBox._northEast.lng}
                                },
                            groupBy: [HouseGroupType.Price, HouseGroupType.Apartment]

                        }
                    })
            }
        }
    }));


    const setMapView = (latLng, persistentMarker) => {

        if (map && latLng?.lat && latLng?.lng) {
            setDisappearingMarkerLocation(prevState => latLng)
            if (!persistentMarker)
                setTimeout(() => setDisappearingMarkerLocation(prevState => {
                    console.log()
                }), 5000)
            map.setView(latLng, map.getZoom(), {
                "animate": false,
                "pan": {
                    "duration": 1
                }
            })
        }
    }

    const setZoom = (zoom) => {
        if (map && zoom) {
            map.setZoom(zoom)
        }
    }

    const createHouseRangeList = () => {
        const houseRangeList = {
            bedrooms: {min: 0, max: 0},
            houseTypes: []
        };
        Object.keys(HouseTypes).flatMap((houseTypeKey) => {
            if (houseTypeKey == "SELF_CONTAINED") {
                return [...Array(10).keys()].map((bedrooms) => bedrooms + 1)
            } else
                return [houseTypeKey]
        }).filter(houseTypeKey => houseTypeKey >= houseTypeRangeFilter?.min && houseTypeKey <= houseTypeRangeFilter?.max)
            .map(houseTypeKey => houseTypeKey <= 0 ? houseRangeList.houseTypes.push(HouseTypes[houseTypeKey]) : houseRangeList.bedrooms = {
                ...houseRangeList.bedrooms,
                max: houseTypeKey > houseRangeList.bedrooms.max ? houseTypeKey : houseRangeList.bedrooms.max,
                min: houseTypeKey < houseRangeList.bedrooms.min ? houseTypeKey : houseRangeList.bedrooms.min,
            })
        return houseRangeList;
    }


    const PAINT_RULES = [
        {
            dataLayer: "water",
            symbolizer: new protomaps.PolygonSymbolizer({fill: "#99d3ff"})
        },
        {
            dataLayer: "earth",
            symbolizer: new protomaps.PolygonSymbolizer({fill: "#f6f5f5"})
        },
        // {
        //     dataLayer:"park",
        //     symbolizer:new protomaps.PolygonSymbolizer({fill:"#d1e6d1"})
        // },
        // {
        //     dataLayer:"roads",
        //     symbolizer:new protomaps.LineSymbolizer({fill:"#d9e0e7", opacity:0.3})
        // },
        // {
        //     dataLayer:"highway",
        //     symbolizer:new protomaps.LineSymbolizer({fill:"#8ba5c1"})
        // },
        // {
        //     dataLayer:"majorRoad",
        //     symbolizer:new protomaps.LineSymbolizer({fill:"#8ba5c1"})
        // },
        // {
        //     dataLayer:"mediumRoad",
        //     symbolizer:new protomaps.LineSymbolizer({fill:"#d9e0e7"})
        // },
        // {
        //     dataLayer:"minorRoad",
        //     symbolizer:new protomaps.LineSymbolizer({fill:"#d9e0e7"})
        // }


    ];

    const InitalizeMapData = () => {
        const map = useMapEvents({
            click: (e) => !props?.disableClick ? onClickMap(e) : null,
            moveend: (e) => handleMoveEnd(e),
            locationfound: (location) => {
                // console.log('location found:', location)
            },
        })
        setMap(map)
        // map.setView(selectedMarkerLocation ?? location ?? defaultSearchLocation);


        // const key = 'rz8g6QAUUNORP9TAmuDa';
        // L.tileLayer(`https://api.maptiler.com/maps/basic-v2/{z}/{x}/{y}.png?key=${key}`,{
        //     tileSize: 512,
        //     zoomOffset: -1,
        //     minZoom: 1,
        //     attribution: "\u003ca href=\"https://www.maptiler.com/copyright/\" target=\"_blank\"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href=\"https://www.openstreetmap.org/copyright\" target=\"_blank\"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e",
        //     crossOrigin: true
        // }).addTo(map);


        // const mapLayer = protomaps.leafletLayer({
        //     url: 'https://api.protomaps.com/tiles/v3/{z}/{x}/{y}.mvt?key=be46aaabd3c86045',
        //     paint_rules: protomaps.paintRules({
        //         earth: "#F6F5F5",
        //         glacier: "#ffffff",
        //         residential: "#F4F4F8",
        //         hospital: "#FFF6F6",
        //         cemetery: "#EFF2EE",
        //         school: "#F7F6FF",
        //         industrial: "#FFF9EF",
        //         wood: "#F4F9EF",
        //         grass: "#EBF9E3",
        //         park: "#C3F1D5",
        //         water: "#8FDAEE",
        //         sand: "#ebebeb",
        //         buildings: "#F2EDE8",
        //         highwayCasing: "#465D87",
        //         majorRoadCasing: "#465D87",
        //         mediumRoadCasing: "#C4CED5",
        //         minorRoadCasing: "#CFD9E3",
        //         highway: "#8BA5C1",
        //         majorRoad: "#8BA5C1",
        //         mediumRoad: "#D9E0E7",
        //         minorRoad: "#CFD9E3",
        //         boundaries: "#9e9e9e",
        //         mask: "#dddddd",
        //         countryLabel: "#aaaaaa",
        //         cityLabel: "#6C6C6C",
        //         stateLabel: "#999999",
        //         neighbourhoodLabel: "#888888",
        //         landuseLabel: "#898989",
        //         waterLabel: "#41ABDC",
        //         naturalLabel: "#4B8F14",
        //         roadsLabel: "#888888",
        //         poisLabel: "#606060",
        //     })
        // })
        // mapLayer.addTo(map)
        return null
    }


    return (
        <Fragment>
            <MapContainer
                style={{height: props?.height ?? 300, width: props?.width ?? "unset"}}
                center={props?.initialLocation?.hasOwnProperty("lat") && props?.initialLocation?.hasOwnProperty("lng") ? props?.initialLocation : defaultSearchLocation}
                zoom={13}
                whenReady={(map) => {
                    // setMap(map)
                    // if (map) {
                    //     if (props?.loadHouses) {
                    //         let boundaryBox = map.getBounds();
                    //         let zoom = map.getZoom();
                    //         if (props?.loadHouses)
                    //             getGroupedHouses({
                    //                 variables: {
                    //                     country: "Kenya",
                    //                     houseStatus: "VACANT",
                    //                     priceFrom: priceFilter?.min ?? 0,
                    //                     priceTo: priceFilter?.max ?? 10000000,
                    // roomsFrom: houseTypeRangeFilter?.min ?? 0,
                    //     roomsTo: houseTypeRangeFilter?.max ?? 20,
                    //                     boundaryGroup: zoom >= 15 ? "DISTRICT" : (zoom >= 13 && zoom < 15 ? "DISTRICT" : (zoom >= 11 && zoom < 13 ? "CONSTITUENCY" : "COUNTY")),
                    //                     boundingBox:
                    //                         {
                    //                             bottomLeft: {
                    //                                 "lat": boundaryBox._southWest.lat,
                    //                                 "lng": boundaryBox._southWest.lng
                    //                             },
                    //                             topRight: {
                    //                                 "lat": boundaryBox._northEast.lat,
                    //                                 "lng": boundaryBox._northEast.lng
                    //                             }
                    //                         }
                    //
                    //                 }
                    //             })
                    //     }
                    //     // loadHousesWithinBounds(
                    //     //     map.getBounds(),
                    //     //     priceFilter?.min ?? 0,
                    //     //     priceFilter?.max ?? 0,
                    //     //     createHouseRangeList(),
                    //     //     map.getZoom())
                    // }
                }}
                dragging={true}
                doubleClickZoom={!props?.disableZoom}
                scrollWheelZoom={!props?.disableZoom}
                attributionControl={true}
            >
                {
                    <InitalizeMapData/>
                }
                {typeof boundariesData != 'undefined' && Object.keys(boundariesData).length > 0 ? <GeoJSON
                    ref={boundariesRef}
                    data={boundariesData}
                    style={
                        {
                            color: "#ff0000",
                            'fillcolor': '#ff0000',
                            "opacity": 0.3
                        }
                    }
                /> : null}
                {/*<TileLayer*/}
                {/*    attribution='&copy; <Anchor path="http://osm.org/copyright">OpenStreetMap</Anchor> contributors'*/}
                {/*    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"*/}
                {/*/>*/}
                <TileLayer
                    attribution='&copy; <Anchor path="http://osm.org/copyright">OpenStreetMap</Anchor> contributors'
                    url={`https://api.maptiler.com/maps/streets-v2/{z}/{x}/{y}.png?key=${mapTilerKey}`}
                    tileSize={512}
                    zoomOffset={-1}
                    minZoom={1}
                    crossOrigin={true}
                />
                {circleLayers.map((circleLayer, index) => {
                    return map?.getZoom() < 15 ? <Marker
                            key={`general_marker_${index}`}
                            position={{lat: circleLayer.lat, lng: circleLayer.lng}}
                            eventHandlers={{
                                click: (e) => handleCircleMarkerClick(e, circleLayer),
                                mouseover: (e) => handleCircleMarkerMouseOver(e, circleLayer),
                                mouseout: (e) => handleCircleMarkerMouseOut(e, circleLayer)
                            }}
                            icon={L.divIcon({
                                iconAnchor: [circleLayer.size / 3, circleLayer.size / 3],
                                className: 'apartment',
                                html: renderToString(
                                    <div className="locationCircle d-flex flex-column">

                                        {circleLayer.county == null && circleLayer.constituency == null && circleLayer.district == null &&
                                        <strong> {circleLayer.country}</strong>}
                                        {circleLayer?.district && circleLayer.district !== null ?
                                            <strong> {circleLayer.district}</strong> : null}
                                        {circleLayer?.constituency && circleLayer.constituency !== null ?
                                            <strong
                                                className={`${circleLayer?.district && circleLayer.district !== null ? 'small' : ''}`}> {circleLayer.constituency}</strong> : null}
                                        <strong className="small"> {circleLayer.county} </strong>
                                        {circleLayer.count} Houses</div>
                                )
                            })}
                        />
                        :
                        // let highestPrice = Math.max(...(circleLayers.map(layer => layer.houses.house.price)))
                        // let lowestPrice = Math.min(...(circleLayers.map(layer => layer.houses.house.price)))
                        // const bedsList = new Set()
                        // const bathsList = new Set()
                        // houseGroup.map(house => {
                        //     if (highestPrice < house.price)
                        //         highestPrice = house.price;
                        //     if (lowestPrice > house.price)
                        //         lowestPrice = house.price;
                        //     bedsList.add(house.bedroom)
                        //     bathsList.add(house.bathroom)
                        // })
                        // return
                        circleLayer.count > 0 ? <Marker
                            key={`group_marker_${index}`}
                            position={{
                                lat: circleLayer.house?.apartment?.lat ?? circleLayer.house?.lat,
                                lng: circleLayer.house?.apartment?.lng ?? circleLayer.house?.lng
                            }}
                            eventHandlers={{
                                click: (e) => handleCircleMarkerClick(e, circleLayer),
                                mouseover: (e) => handleHouseMarkerMouseOver(e, circleLayer),
                            }}
                            icon={L.divIcon({
                                iconAnchor: [circleLayer.size / 2, (circleLayer.length > 1 ? 53 : 31) * 1.2],
                                className: '',
                                html: renderToString(
                                    <div className="d-flex flex-column">
                                        <div className="housePin d-flex flex-column">
                                            <div className="imageWrapper p-1">
                                                <img src={circleLayer.house.media?.thumbnails[0]} onError={({currentTarget}) => {
                                                    currentTarget.onerror = null; // prevents looping
                                                    currentTarget.src = houseStub;
                                                }} alt={'house-pin-img'}/>
                                                <Col className={'hover-details'} m={0} p={0}>
                                                    {circleLayer.count > 1 ? <strong
                                                        className="small w-100"> {circleLayer.count} Houses </strong> : null}
                                                    <i className="uil uil-directions"></i>
                                                    {/*{houseGroup.length > 1 ?*/}
                                                    {/*    <strong className="small text-muted"><span>{[...bedsList].toString()} Beds & {[...bathsList].toString()} Baths</span>*/}
                                                    {/*</strong> :*/}
                                                    {/*    <strong className="small text-muted"> {houseGroup[0]?.bedrooms} Bed, {houseGroup[0]?.bathrooms} Bath</strong>}*/}
                                                    <Text fontWeight={700} fontSize={14} color={'white'} m={0}
                                                          p={0}> Ksh. {circleLayer?.minPrice != circleLayer.maxPrice ? (circleLayer.minPrice + " - " + circleLayer.maxPrice?.formatCurrency()) : circleLayer.house?.price?.formatCurrency()}</Text>
                                                </Col>
                                            </div>
                                            <div className="p-1">
                                                {circleLayer.count > 1 ? <strong
                                                    className="small w-100"> {circleLayer.count} Houses </strong> : null}
                                                <i className="uil uil-directions"></i>
                                                {/*{houseGroup.length > 1 ? */}
                                                {/*    <strong className="small text-muted"><span>{[...bedsList].toString()} Beds & {[...bathsList].toString()} Baths</span>*/}
                                                {/*</strong> :*/}
                                                {/*    <strong className="small text-muted"> {houseGroup[0]?.bedrooms} Bed, {houseGroup[0]?.bathrooms} Bath</strong>}*/}
                                                <Text fontWeight={700} color={'white'} m={0}
                                                      p={0}> Ksh. {circleLayer?.minPrice != circleLayer.maxPrice ? (circleLayer.minPrice + " - " + circleLayer.maxPrice?.formatCurrency()) : circleLayer.house?.price?.formatCurrency()}</Text>


                                            </div>
                                        </div>
                                        <div className="housePinArrow d-flex justify-content-center"
                                             style={{width: 150}}>
                                            <div></div>
                                        </div>
                                    </div>
                                )
                            })}
                        /> : null
                })}
                {houseLocationCircle.map((houseCircle, index) => {

                    return <Fragment key={`hc_${index}`}>
                        <Marker
                            key={`house_location_marker_${index}_big`}
                            position={{lat: houseCircle.lat, lng: houseCircle.lng}}
                            icon={L.divIcon({
                                iconAnchor: [20, 20],
                                className: 'apartment',
                                html: renderToString(
                                    <div className="locationCircle d-flex flex-column" style={{width: 40, height: 40}}>
                                    </div>
                                )
                            })}
                        />
                    </Fragment>
                })}
                {(((selectedMarkerLocation ?? props?.initialLocation)?.hasOwnProperty("lat") ?? false) && ((selectedMarkerLocation ?? props?.initialLocation)?.hasOwnProperty("lng") ?? false)) ?
                    <Marker position={selectedMarkerLocation ?? props?.initialLocation}>
                        <Popup>{selectedMarkerAddress}</Popup>
                    </Marker> : null}
                {((disappearingMarkerLocation?.hasOwnProperty("lat") ?? false) && (disappearingMarkerLocation?.hasOwnProperty("lng") ?? false)) ?
                    <Marker position={disappearingMarkerLocation}>
                    </Marker> : null}
                {props.enableSearch ?
                    <div ref={elementRef} className="d-flex justify-content-center align-items-center m-2">
                        <AsyncTypeahead
                            filterBy={() => true}
                            id="searchMap"
                            isLoading={isLoading}
                            onChange={(selected) => selected?.length > 0 ? setSelectedMarkerLocation({
                                lat: selected[0]?.geometry?.coordinates[1],
                                lng: selected[0]?.geometry.coordinates[0]
                            }) : null}
                            labelKey="label"
                            minLength={3}
                            onSearch={handleSearch}
                            options={options}
                            placeholder="Search for the house area ..."
                            className="col-md-8"
                            style={{zIndex: 20, borderRadius: 10}}
                            renderMenuItemChildren={(option, props) => (
                                <Fragment>
                                    <Col>
                                        <div>
                                            <h6>{option.properties.name}</h6>
                                            <span
                                                className="text-muted">{option.properties.city ?? option.properties.state}</span>
                                        </div>

                                    </Col>
                                </Fragment>
                            )}
                        /></div> : null}

            </MapContainer>
        </Fragment>
    );
});
export default LeafletMap;
