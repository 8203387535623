import {FormGroup, Label, Input, Anchor, Button, Card, SectionTitle} from "@doar/components";
import {useForm, SubmitHandler} from "react-hook-form";
import {hasKey} from "@doar/shared/methods";
import {
    StyledWrap,
    StyledTitle,
    StyledDesc,
    StyledLabelWrap,
    StyledDivider,
    StyledBottomText,
} from "./style";
import {authenticationService, phoneNumberPrefix, phoneRegex} from "../settings/data";
import {useState} from "react";
import {toast, ToastContainer} from "react-toastify";
import {useLoginMutation} from "../../graphql/generated/tenant-schema";
import {useAppSelector} from "../../redux/hooks";
import {StyledCardHeader, StyledCardBody} from "./style";
import {X} from "react-feather";

interface IFormValues {
    phone: string;
    code: string;
}
interface SigninConfig {
    hasPadding?: boolean;
    hasMargin?: boolean;
    onLoginCallback?:(user:any)=>void;
    onClose?:()=>void;
}

const SigninForm = ({hasPadding = true, hasMargin = true, onLoginCallback, onClose}:SigninConfig) => {


    const {
        register,
        handleSubmit,
        formState: {errors},
    } = useForm<IFormValues>();
    const [loading, setLoading] = useState(false);
    const [firebaseConfirmationResult, setFirebaseConfirmationResult] = useState<any>(null);
    const [verifyTokenAndLoginOwner] = useLoginMutation();
    const { theme } = useAppSelector((state) => state.theme);


    const onSubmit: SubmitHandler<IFormValues> = (data) => {
        if (!firebaseConfirmationResult)
            loginWithPhone(data.phone);
        else
            confirmVerificationCode(data.code)
    };


    const loginWithPhone = async (phone: string) => {
        setLoading(true)
        setFirebaseConfirmationResult(await authenticationService.loginWithPhone(`+${phoneNumberPrefix.substring(1)}${parseInt(phone, 10)}`, theme));
        setLoading(false);
    }
    const confirmVerificationCode = async (code: string) => {
        setLoading(true)

        authenticationService.confirmVerificationCode(code, firebaseConfirmationResult, theme).then(async (verificationResult) => {
            await verifyTokenAndLoginOwner({
                variables: {firebaseToken: (await verificationResult.user.getIdToken())},
                onCompleted: (results) => {
                    if (results.login) {
                        authenticationService.currentUserSubject.next(results.login)
                        if (onLoginCallback) {
                            onLoginCallback(results.login)
                        }
                    } else {
                        toast.error("Your account might be de-activated. Kindly reach out to KejaRent for support", {
                            theme:theme=="dark"?"dark":"light"
                        });
                        setLoading(false)
                    }

                    // setTimeout(() => {
                    //   props.history.push(`${process.env.PUBLIC_URL}/dashboard`);
                    // }, 200);

                }, onError: (error) => {
                    toast.error(error?.message ?? "Kindly retry or reach out to KejaRent for support incase it persists",{
                        theme:theme=="dark"?"dark":"light"
                    });
                    setLoading(false)
                }
            });
        }).catch((error) => {
            toast.error(error?.message ?? "Kindly retry or reach out to KejaRent for support incase it persists",{
                theme:theme=="dark"?"dark":"light"
            });
        })
    }


    return (
        <StyledWrap>
            <Card p={hasPadding?3:0} m={hasMargin?2:0}>
                {onClose?<div className={'position-absolute d-flex align-items-start'} style={{top: 5, right: 5, cursor: "pointer"}}
                                     onClick={() => onClose()}><X size={20}/></div>:null}
                <StyledCardHeader mb={2}>
                    <StyledTitle>Sign In With Verification code</StyledTitle>
                    <StyledDesc>Enter your phone to receive the signin code.</StyledDesc>
                </StyledCardHeader>
                <StyledCardBody>
                    <form action="#" onSubmit={handleSubmit(onSubmit)} noValidate>
                        <FormGroup mb="20px">
                            <Label display="block" mb="5px" htmlFor="email">
                                Phone
                            </Label>
                            <Input
                                type="text"
                                id="phone"
                                placeholder="0712345678"
                                feedbackText={errors?.phone?.message}
                                state={hasKey(errors, "phone") ? "error" : "success"}
                                showState={!!hasKey(errors, "phone")}
                                {...register("phone", {
                                    required: "phone is required",
                                    pattern: {
                                        value: phoneRegex,
                                        message: "invalid phone number",
                                    },
                                })}
                            />
                        </FormGroup>
                        {firebaseConfirmationResult?<FormGroup mb="20px">
                            <StyledLabelWrap>
                                <Label display="block" mb="5px" htmlFor="password">
                                    Verification code
                                </Label>
                                <Anchor path="/forgot-password" fontSize="13px">
                                    Didn&apos;t receive code?
                                </Anchor>
                            </StyledLabelWrap>
                            <Input
                                id="code"
                                type="password"
                                placeholder="Enter verification code"
                                feedbackText={errors?.code?.message}
                                state={hasKey(errors, "code") ? "error" : "success"}
                                showState={!!hasKey(errors, "code")}
                                {...register("code", {
                                    required: "Verification code is required",
                                    minLength: {
                                        value: 6,
                                        message: "Minimum length is 6",
                                    },
                                    maxLength: {
                                        value: 6,
                                        message: "Minimum length is 6",
                                    },
                                })}
                            />
                        </FormGroup>:null}
                        <Button type="submit" color="primary" disabled={loading} fullwidth>
                            {loading?'Loading...':(firebaseConfirmationResult?'Verify Code':'Send Verification code')}
                        </Button>
                        <StyledDivider>or</StyledDivider>
                        {/*<Button variant="outlined" color="facebook" fullwidth>*/}
                        {/*    Sign In With Facebook*/}
                        {/*</Button>*/}
                        {/*<Button*/}
                        {/*    variant="outlined"*/}
                        {/*    color="twitter"*/}
                        {/*    mt="0.5rem"*/}
                        {/*    fullwidth*/}
                        {/*>*/}
                        {/*    Sign In With Twitter*/}
                        {/*</Button>*/}
                        <StyledBottomText>
                            Not a tenant?{" "}
                            <Anchor path={process.env.REACT_APP_OWNER_ENDPOINT??'http://owner.kejarent.com'}>Visit Owner Platform</Anchor>
                        </StyledBottomText>
                    </form>
                </StyledCardBody>
            </Card>
            <div id={"sign-in-button"}></div>
        </StyledWrap>
    );
};

export default SigninForm;
