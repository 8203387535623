import {
    Edit3,
    User,
    HelpCircle,
    LifeBuoy,
    Settings,
    LogOut,
} from "react-feather";
import {
    DropdownToggle,
    Dropdown,
    Avatar,
    AvatarInitial,
} from "@doar/components";
import {
    StyledDropMenu,
    StyledAuthorName,
    StyledAuthorRole,
    StyledDropItem,
    StyledDivider,
    StyledAvatar,
} from "./style";
import {authenticationService} from "../../settings/data";

const ProfileDropdown = ({user, onEdit, loginButtonRef, showLogin}:any) => {
    return (
        <Dropdown direction="down" className="dropdown-profile">
            <DropdownToggle variant="texted">
                <StyledAvatar avatarRef={loginButtonRef} size="sm" shape="circle" onClick={()=>user?null:showLogin()}>
                    <AvatarInitial>{`${(user?.firstName??'Anonymous').substr(0,1)}${(user?.lastName??'').substr(0,1)}`}</AvatarInitial>
                </StyledAvatar>
            </DropdownToggle>
            {user && <StyledDropMenu>
                <Avatar size="xl" shape="circle">
                    <AvatarInitial>{`${(user?.firstName??'Anonymous').substr(0,1)}${(user?.lastName??'').substr(0,1)}`}</AvatarInitial>
                </Avatar>
                <StyledAuthorName>{`${user?.firstName??'Anonymous'} ${user?.lastName??''}`}</StyledAuthorName>
                <StyledAuthorRole>{user?.role}</StyledAuthorRole>
                <StyledDropItem onClick={()=>onEdit()}>
                    <Edit3 size="24" />
                    Edit Profile
                </StyledDropItem>
                <StyledDropItem path="/houses" mt="10px">
                    <User size="24" />
                    View Profile
                </StyledDropItem>
                <StyledDivider />
                <StyledDropItem
                    path="https://hasthemes.com/contact-us/"
                    mt="10px"
                >
                    <HelpCircle size="24" />
                    Help Center
                </StyledDropItem>
                <StyledDropItem onClick={()=>authenticationService.logout()} mt="10px">
                    <LogOut size="24" />
                    Sign Out
                </StyledDropItem>
            </StyledDropMenu>}
        </Dropdown>
    );
};

export default ProfileDropdown;
